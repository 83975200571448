import React from 'react';
import { useNavigate } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { HiOutlineVideoCamera } from 'react-icons/hi2';
import { FiSettings } from 'react-icons/fi';
import moment from 'moment';
import { WiTime4 } from 'react-icons/wi';
import './LiveGridStructure.scss';
import { ReactComponent as ThreeDotIcon } from '../../../assets/images/VerticalThreeDots.svg';
import { ReactComponent as PhotoOffIcon } from '../../../assets/images/photo_off.svg';
import { constants } from '../../../helpers';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  getCDNInfo,
  setIsReloadedStream,
} from '../../../store/reducers/StreamingReducer';
import { setDeviceInformation } from '../../../store/reducers/AccountReducer';
import { usePoliciesStore } from '../../../store/policiesStore';

const NoSnapshot = ({ device, deviceId, activeTime, timeZone }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cdnInfo = useSelector(getCDNInfo);
  const { getLoggedInUserPolicies } = usePoliciesStore();
  const userPolicies = getLoggedInUserPolicies();

  const OnClickCameraDetails = () => {
    dispatch(setIsReloadedStream(false));
    navigate(`/cameras/dashboard.html`, {
      state: {
        id: deviceId,
        cdnInfo: cdnInfo ? cdnInfo : {},
        origin: 'multiview-contextual-menu',
      },
    });
  };

  const handleDoubleClick = () => {
    OnClickCameraDetails();
  };

  const OnClickCameraSettings = () => {
    dispatch(setDeviceInformation(device));
    deviceId && navigate(`/devices/dashboard.html?deviceId=${deviceId}`);
  };

  return (
    <>
      <div
        className={`device-overlay name-option-container`}
        onDoubleClick={() => handleDoubleClick()}
      >
        <div className="device-title-container">
          <div className="device-name">{device?.deviceName}</div>
          <div className="device-location">
            {device?.locationName} • {device?.areaName}
          </div>
        </div>
        <div className="date-time-wrapper">
          <div className="off-date-time">
            <WiTime4 size={14} />
            {moment.tz(moment(activeTime), timeZone).format('hh:mm:ss A z')}
          </div>
        </div>
        <div className="menu-icon">
          <NavDropdown className="devices-dropdown" title={<ThreeDotIcon />}>
            <NavDropdown.Item
              className="devices-dropdown-options"
              onClick={() => OnClickCameraDetails()}
            >
              <HiOutlineVideoCamera size={16} />
              <span className="devices-dropdown-options-label">
                {constants.CAMERAS_VIDEO_CAMERA_DETAILS_LABEL}
              </span>
            </NavDropdown.Item>
            {userPolicies.view_device_settings && (
              <NavDropdown.Item
                className="devices-dropdown-options"
                onClick={() => OnClickCameraSettings()}
              >
                <FiSettings size={14} />
                <span className="devices-dropdown-options-label">
                  {constants.CAMERAS_VIDEO_SETTINGS_LABEL}
                </span>
              </NavDropdown.Item>
            )}
          </NavDropdown>
        </div>
      </div>
      <div className="not-active-camera-details">
        <PhotoOffIcon size={24} className="no-snapshot-icon" />
        <div className="not-active-camera-title">
          {constants.CAMERAS_VIDEO_NO_RECENT_SS_LABEL}
        </div>
      </div>
    </>
  );
};

export default NoSnapshot;
