import React, { useEffect, useRef, useState } from 'react';
import { constants, Utils } from '../../../../helpers';
import { checkUnslect } from '../../../../assets/images';
import { ImCheckboxChecked } from 'react-icons/im';
import { MdIndeterminateCheckBox } from 'react-icons/md';
import { PrimaryButton } from '../../../../components/common';
import publishDeviceSetting, {
  deleteDeviceSetting,
} from '../shared/PublishDeviceSettings';
import { EventType } from '../../../../helpers/enums';
const CategoryEventSelection = ({
  saveBtnEnable = false,
  title,
  subtitle = false,
  callBackMethod,
  eventListData,
  selectedRegion = false,
  islineDirectionClicked = false,
  setIslineDirectionClicked = false,
  deviceInfosProperty,
  minDurationData,
}) => {
  const [eventList, setEventList] = useState(eventListData);
  const [buttonDisable, setButtonDisble] = useState(false);
  const [buttonDisableOnMinDuration, setButtonDisableOnMinDuration] =
    useState(true);
  const [property, setProperty] = useState({});

  useEffect(() => {
    if (!islineDirectionClicked && selectedRegion) {
      setEventList(eventListData);
    } else {
      setIslineDirectionClicked && setIslineDirectionClicked(false);
    }
  }, [eventListData, selectedRegion]);

  useEffect(() => {
    setTimeout(() => {
      let isDisable = true;
      const properties = {};
      // Iterate through the main array to check selections
      eventList?.forEach((item) => {
        if (item.isSlected === '1') {
          properties[item.keyName] = 'true'; // Add main categories
          isDisable = false;
        } else if (
          item.typeName == EventType?.VEHICLE &&
          item?.vehicleTypes?.some((v) => v?.isSlected === '1')
        ) {
          properties[item.keyName] = 'true'; // Add main categories
        } else {
          properties[item.keyName] = 'false'; // Add main categories
        }
        if (item.typeName === EventType?.VEHICLE && item.vehicleTypes) {
          item.vehicleTypes.forEach((vehicle) => {
            if (vehicle.isSlected === '1') {
              properties[vehicle.keyName] = 'true'; // Add selected vehicle types
              properties[item.keyName] = 'true';
              isDisable = false;
            } else {
              properties[vehicle.keyName] = 'false'; // Add main categories
            }
          });
        }
      });
      setProperty(properties);
      if (!saveBtnEnable) {
        callBackMethod(properties);
      }
      setButtonDisble(isDisable);
    }, 200);
  }, [eventList]);

  useEffect(() => {
    const minDuration = deviceInfosProperty['obj-min-duration'];
    setButtonDisableOnMinDuration(
      minDuration !== undefined &&
        minDuration !== 'undefined' &&
        minDurationData === JSON.parse(minDuration),
    );
  }, [deviceInfosProperty, minDurationData]);

  const setVehicleEventTypeParent = (item) => {
    setEventList((prevList) =>
      prevList.map((event) => {
        if (event?.keyName === item?.keyName) {
          const newSelectedState = event?.isSlected === '0' ? '1' : '0'; // Toggle main category
          return {
            ...event,
            isSlected: newSelectedState,
            vehicleTypes: event?.vehicleTypes?.map((vehicle) => ({
              ...vehicle,
              isSlected: newSelectedState, // Set all vehicle types to the same state as main category
            })),
          };
        }
        return event;
      }),
    );
  };

  const setVehicleEventTypeChild = (item, vehicle) => {
    setEventList((prevList) =>
      prevList.map((event) => {
        if (event.keyName === item.keyName) {
          const updatedVehicleTypes =
            event?.vehicleTypes?.map((v) => {
              if (v?.keyName === vehicle?.keyName) {
                return {
                  ...v,
                  isSlected: v?.isSlected === '0' ? '1' : '0', // Toggle child selection
                };
              }
              return v; // Return unchanged vehicle type if not matched
            }) || []; // Ensure we handle the case where vehicleTypes is undefined

          // Check if all vehicleTypes are selected as '0'
          const allSelectedZero = updatedVehicleTypes.every(
            (v) => v.isSlected === '0',
          );

          // If all vehicle types have isSlected === '0', update the parent event's isSlected
          return {
            ...event,
            vehicleTypes: updatedVehicleTypes,
            isSlected: allSelectedZero ? '0' : event.isSlected, // Update parent if needed
          };
        }
        return event; // Return unchanged event if not matched
      }),
    );
  };

  const getMainCheckboxIcon = (item) => {
    if (
      item?.typeName !== EventType?.VEHICLE ||
      item?.vehicleTypes?.length === 0
    ) {
      if (item?.isSlected === '0') {
        return <img className="img-icon" alt="" src={checkUnslect} />;
      } else if (item?.isSlected === '1') {
        return (
          <ImCheckboxChecked
            size={20}
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--brand_primary',
            )}
          />
        );
      }
    } else {
      const allSelected = item?.vehicleTypes?.every(
        (v) => v?.isSlected === '1',
      );
      const anySelected = item?.vehicleTypes?.some((v) => v?.isSlected === '1');

      if (allSelected) {
        return (
          <ImCheckboxChecked
            size={20}
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--brand_primary',
            )}
          />
        );
      } else if (anySelected) {
        return 'anySelected';
      } else {
        return <img className="img-icon" alt="" src={checkUnslect} />;
      }
    }
  };

  const checkVehicleSubTypes = () => {
    return (
      eventList?.filter((item) => item.keyName === 'vehicle-detection')[0]
        ?.vehicleTypes?.length > 0
    );
  };

  return (
    <div className="component-container">
      {eventListData?.length > 0 && (
        <>
          <div className="object-type-title category">{title}</div>
          {subtitle && <div className="object-type-sub-title">{subtitle}</div>}
          <div className="event-category-Container">
            <div className="event-type-list">
              {eventList.map((item) => (
                <div key={item.keyName} className="parent-list-container">
                  <div
                    className={`left-event-block ${
                      item.keyName === 'licenseplate-detection' &&
                      checkVehicleSubTypes()
                        ? 'license-plate'
                        : ''
                    }`}
                  >
                    <div className="icon-title-container">
                      <div className="inner-row">
                        <div>{item?.icon}</div>
                        <div className="title-event">{item?.typeName}</div>
                      </div>
                      <div
                        className={`img-icon ${
                          getMainCheckboxIcon(item) === 'anySelected' &&
                          'minus-icon'
                        }`}
                        onClick={() => setVehicleEventTypeParent(item)} // Handle main checkbox click
                      >
                        {getMainCheckboxIcon(item) == 'anySelected' ? (
                          <MdIndeterminateCheckBox
                            size={26}
                            color={getComputedStyle(
                              document.documentElement,
                            ).getPropertyValue('--brand_primary')}
                          />
                        ) : (
                          getMainCheckboxIcon(item)
                        )}
                      </div>
                    </div>

                    {item.typeName === EventType?.VEHICLE &&
                      item?.vehicleTypes &&
                      item?.vehicleTypes?.length > 0 && (
                        <div className="vehicle-margin-layout">
                          {item?.vehicleTypes.map((vehicle) => (
                            <div className="vehicle-list-container">
                              <div className="inner-row">
                                <div>{vehicle?.icon}</div>
                                <div className="title-event">
                                  {vehicle?.typeName}
                                </div>
                              </div>
                              <div
                                onClick={(e) =>
                                  setVehicleEventTypeChild(item, vehicle)
                                }
                                className="img-icon"
                                style={{ cursor: 'pointer' }} // Optional: make the cursor pointer
                              >
                                {vehicle.isSlected === '1' ? (
                                  <ImCheckboxChecked
                                    size={20}
                                    color={getComputedStyle(
                                      document.documentElement,
                                    ).getPropertyValue('--brand_primary')}
                                  />
                                ) : (
                                  <img
                                    className="img-icon"
                                    alt=""
                                    src={checkUnslect}
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      {saveBtnEnable && (
        <PrimaryButton
          className="btn btn-primary mt-4"
          type="button"
          width="100%"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          disabled={
            eventListData?.length > 0
              ? buttonDisable
              : buttonDisableOnMinDuration
          }
          onClick={() => {
            callBackMethod(property);
          }}
        >
          {constants.ACCOUNT_SETTINGS_PROFILE_BUTTON_LABEL}
        </PrimaryButton>
      )}
    </div>
  );
};

export default CategoryEventSelection;
