import { useEffect, useRef, useState } from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import { TextField } from '../../../../components/forms';
import { Formik } from 'formik';
import { constants, Utils } from '../../../../helpers';
// TODO : use later once have the aPI
// import axios from 'axios';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { FaCircle } from 'react-icons/fa';
import moment from 'moment';
import DeviceLocationAreas from '../../DeviceLocationAreas';
import timezones from '../../../../data/support/timezone.json';
import './DeviceInfoBlock.scss';
import { useTranslation } from 'react-i18next';
import { findTimeZoneFromSelectedValue } from '../../../../helpers/commonUtils';
import * as Yup from 'yup';
import {
  ErrorInfo,
  LaunchDisable,
  LaunchEnable,
  ErrorInfoActive,
} from '../../../../assets/images';
import publishDeviceSetting, {
  publishAddDeviceSetting,
  publishNetworkChannelLocalSetting,
  deleteKeepLiveSession,
  publishDeviceAccessUiStatus,
} from '../shared/PublishDeviceSettings';
import {
  getAllMqttDataFromResponse,
  setAllMqttDataFromResponse,
} from '../../../../store/reducers/AccountReducer';
import { useSelector } from 'react-redux';
import {
  PrimaryButton,
  SiteModal,
  SiteSpinner,
} from '../../../../components/common';
import { RxCross2 } from 'react-icons/rx';
import { LocalUIHttp } from '../../../../helpers/enums';
import {
  mqttPublish,
  mqttSubscribe,
  mqttUnsubscribe,
} from '../../../../utils/connection/mqttConnection';
import { useDispatch } from 'react-redux';

const DeviceInfoBlock = ({
  orgId,
  orgName,
  deviceId,
  deviceInfos,
  saveAudioSettingsFlag,
  saveAudioSettingsCallback,
  moveDeviceDetailCallback,
  updateStore,
  accountId,
  uuid,
  devicePublishId,
  updateFirmware,
}) => {
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  const { t } = useTranslation();
  const formRef = useRef();
  const isTimerRunning = useRef(false);
  const [variant, setVariant] = useState('danger');
  const [userMsg, setUserMsg] = useState('');
  const [showMsg, setShowMsg] = useState(false);
  const [deviceName, setDeviceName] = useState(deviceInfos?.deviceName);
  const [slectedAreaLocation, setslectedAreaLocation] = useState({});
  const zoneTimeList = timezones?.data;
  const timezone = deviceInfos?.properties?.timezone;
  const [currentUserTimezone, setCurrentUserTimezone] = useState(timezone);
  const [currentUiStatus, setCurrentUiStatus] = useState(1);
  const [showSpiner, setShowSpiner] = useState(false);
  const [showUrl, setShowUrl] = useState(false);
  const [showError, setShowError] = useState(false);
  const [activeSessionModal, setActiveSessionModal] = useState(false);
  let timeoutId;
  const intervalTime = 78000;
  const [isTabActive, setIsTabActive] = useState(true); // Track tab visibility
  const [currentTime, setCurrentTime] = useState(
    moment
      .tz(
        moment(),
        timezone
          ? findTimeZoneFromSelectedValue(timezone)?.location
          : moment.tz.guess(),
      )
      .format('MMM DD, YYYY hh:mm:ss a'),
  );
  const dispatch = useDispatch();
  const deviceState = deviceInfos?.deviceStatus;
  const connectionState = deviceInfos?.connectionStatus;
  const capabilityList = deviceInfos?.capDetails?.capabilities;
  const curDeviceStatus = Utils.getDeviceStatus(deviceState, connectionState);
  const resourceList = Utils.getDeviceSettingResource(0);
  const [showLoader, setShowLoader] = useState(false);
  const capbilityProperty = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[0],
  );
  const capbilityLocalUi = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[2],
  );
  const publishPayload = {
    deviceId: deviceId,
    devicePublishId: devicePublishId,
    uuid: uuid,
    accountId: accountId,
    hubId: deviceInfos?.gatewayId,
  };

  const sendRequest = () => {
    const channelProperty = {
      sessionId: uuid,
      events: [
        {
          notifications: ['proxyConnection'],
        },
      ],
    };
    setTimeout(() => {
      publishNetworkChannelLocalSetting(
        channelProperty,
        LocalUIHttp?.KEEP_ALIVE_RESOURCE,
        publishPayload,
      );
    }, 7000);
  };

  // Function to handle the task, schedules the next task after 85 seconds
  const performTask = () => {
    if (isTabActive) {
      sendRequest();
    }

    // Schedule the next task to be performed after 85 seconds
    timeoutId = setTimeout(performTask, intervalTime); // Recurse every 85 seconds
  };

  // UseEffect to start the task on component mount
  useEffect(() => {
    // Function to check if the tab is active
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setIsTabActive(false); // Tab is inactive
      } else {
        setIsTabActive(true); // Tab is active
      }
    };

    // Listen for visibility change events
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Start the first task
    performTask();

    // Cleanup function to clear timeout and remove event listener
    return () => {
      clearTimeout(timeoutId); // Clear the timeout when the component unmounts
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [deviceInfos?.gatewayId]);

  useEffect(() => {
    let subscribeForEvents;
    if (deviceInfos?.gatewayId) {
      subscribeForEvents = {
        topic: `d/rt-events/${deviceInfos?.gatewayId}`,
        qos: 0,
      };
      mqttSubscribe(subscribeForEvents);
    }
    return () => {
      const channelPropertyKeepAlive = {
        sessionId: uuid,
        events: [
          {
            notifications: ['proxyConnection'],
          },
        ],
      };
      deleteKeepLiveSession(
        channelPropertyKeepAlive,
        LocalUIHttp?.KEEP_ALIVE_RESOURCE,
        publishPayload,
      );
      if (subscribeForEvents) {
        mqttUnsubscribe(subscribeForEvents);
      }
    };
  }, [deviceInfos?.gatewayId]);

  useEffect(() => {
    if (deviceInfos?.gatewayId) {
      if (isTabActive) {
        const channelProperty = {
          channelId: deviceInfos?.deviceId,
          session: uuid,
        };
        publishDeviceAccessUiStatus(
          channelProperty,
          LocalUIHttp?.DEVICE_ACCESS_UI_STATUS,
          publishPayload,
        );
        setTimeout(() => {
          sendAddRequestKeepAlive();
        }, 2000);
      }
    }
  }, [deviceInfos?.gatewayId, isTabActive]);

  const sendAddRequestKeepAlive = () => {
    const channelPropertyKeepAlive = {
      sessionId: uuid,
      events: [
        {
          notifications: ['proxyConnection'],
        },
      ],
    };
    publishAddDeviceSetting(
      channelPropertyKeepAlive,
      LocalUIHttp?.KEEP_ALIVE_RESOURCE,
      publishPayload,
    );
  };

  useEffect(() => {
    if (
      allMqttData?.msg?.resource === LocalUIHttp?.DEVICE_ACCESS_UI_STATUS &&
      allMqttData?.msg?.action?.toLowerCase() === LocalUIHttp?.ACTION_IS
    ) {
      const uiStatusObj = allMqttData?.msg?.properties;
      if (
        uiStatusObj?.status === LocalUIHttp?.STATUS_ACTIVE &&
        uiStatusObj?.channelId === deviceInfos?.deviceId
      ) {
        setShowUrl(uiStatusObj?.subdomain);
        setCurrentUiStatus(2);
        setShowSpiner(false);
      } else if (
        uiStatusObj?.status === LocalUIHttp?.STATUS_ACTIVE &&
        uiStatusObj?.channelId != deviceInfos?.deviceId
      ) {
        setCurrentUiStatus(3);
        setShowSpiner(false);
      } else {
        setCurrentUiStatus(1);
        setShowSpiner(false);
      }
    } else if (
      allMqttData?.msg?.resource === LocalUIHttp?.KEEP_ALIVE_RESOURCE &&
      allMqttData?.msg?.action?.toLowerCase() === LocalUIHttp?.ACTION_ERROR &&
      allMqttData?.from === deviceInfos?.deviceId
    ) {
      if (
        allMqttData?.msg?.properties?.desc != 'The subsciber is already exist'
      ) {
        sendAddRequestKeepAlive();
      }
    } else if (
      allMqttData?.msg?.resource === LocalUIHttp?.KEEP_ALIVE_RESOURCE &&
      allMqttData?.msg?.action?.toLowerCase() === LocalUIHttp?.ACTION_IS
    ) {
      const property = allMqttData?.msg?.properties;
      const dataKeepAlive = property?.[0]?.eventMeta?.events?.[0]?.eventProps;
      if (dataKeepAlive) {
        const uiStatusObj = dataKeepAlive;
        if (
          uiStatusObj?.status === LocalUIHttp?.STATUS_ACTIVE &&
          uiStatusObj?.channelId === deviceInfos?.deviceId
        ) {
          setShowUrl(uiStatusObj?.subdomain);
          setCurrentUiStatus(2);
        } else if (
          uiStatusObj?.status === LocalUIHttp?.STATUS_ACTIVE &&
          uiStatusObj?.channelId != deviceInfos?.deviceId
        ) {
          setCurrentUiStatus(3);
        } else {
          setCurrentUiStatus(1);
        }
      }
    } else if (
      allMqttData?.msg?.resource === LocalUIHttp?.DEVICE_ACCESS_UI_DISCONNECT &&
      allMqttData?.msg?.action?.toLowerCase() === LocalUIHttp?.ACTION_IS
    ) {
      if (
        allMqttData?.msg?.properties?.status ===
        LocalUIHttp?.STATUS_DISCONNECTED
      ) {
        setCurrentUiStatus(1);
        setShowSpiner(false);
      }
    } else if (
      allMqttData?.msg?.resource === LocalUIHttp?.DEVICE_ACCESS_UI_STATUS &&
      allMqttData?.msg?.action?.toLowerCase() === LocalUIHttp?.ACTION_ERROR
    ) {
      setShowSpiner(false);
      setActiveSessionModal(false);
    } else {
      const responseDeviceId = allMqttData?.msg?.resource
        ?.toString()
        ?.split('/')?.[1];
      const resource = allMqttData?.msg?.resource
        ?.toString()
        ?.split(`${deviceId}/`)?.[1];
      if (
        resource === LocalUIHttp?.DEVICE_ACCESS_UI &&
        deviceId === responseDeviceId &&
        allMqttData?.msg?.action?.toLowerCase() === LocalUIHttp?.ACTION_IS
      ) {
        setShowSpiner(false);
        setShowLoader(false);
        if (allMqttData?.msg?.properties?.url) {
          const domainUrl = allMqttData?.msg?.properties?.url;
          setShowUrl(domainUrl);
          let fullUrl =
            domainUrl?.indexOf('://') === -1
              ? 'http://' + domainUrl
              : domainUrl;
          window.open(fullUrl, '_blank');
          if (currentUiStatus === 1 || currentUiStatus === 3) {
            setCurrentUiStatus(2);
            setActiveSessionModal(false);
          }
        }
      } else if (
        resource === LocalUIHttp?.DEVICE_ACCESS_UI &&
        deviceId === responseDeviceId &&
        allMqttData?.msg?.action?.toLowerCase() === LocalUIHttp?.ACTION_ERROR
      ) {
        setStatusUi(allMqttData);
        setShowLoader(false);
      } else if (
        allMqttData?.msg?.resource ===
          LocalUIHttp?.DEVICE_ACCESS_UI_DISCONNECT &&
        allMqttData?.msg?.action?.toLowerCase() === LocalUIHttp?.ACTION_ERROR
      ) {
        setStatusUi(allMqttData);
      }
    }
  }, [allMqttData]);

  const setStatusUi = (allMqttData) => {
    setShowSpiner(false);
    const errorCode = allMqttData?.msg?.properties?.code;
    if (
      errorCode === '3001' ||
      errorCode === '3002' ||
      errorCode === '3003' ||
      errorCode === '3005'
    ) {
      setShowError(constants.HTTP_PROXY_MODAL_TUNNEL_ERROR);
    } else {
      setShowError(constants.HTTP_PROXIY_MODAL_UNKNOWN_ERROR);
    }
    setActiveSessionModal(true);
  };

  const connectDisconnectCall = () => {
    setShowError(false);
    if (currentUiStatus == 1) {
      mqttCallConnect(false);
    } else if (currentUiStatus === 3) {
      setActiveSessionModal(true);
    } else if (currentUiStatus === 2) {
      if (deviceInfos?.gatewayId) {
        setShowSpiner(true);
        const channelProperty = {
          channelId: deviceInfos?.deviceId,
          session: uuid,
        };
        publishNetworkChannelLocalSetting(
          channelProperty,
          LocalUIHttp?.DEVICE_ACCESS_UI_DISCONNECT,
          publishPayload,
        );
      }
    }
  };

  const mqttCallConnect = (isNormalConnect) => {
    setShowSpiner(true);
    const channelProperty = {
      forceDisconnect: isNormalConnect,
      session: uuid,
    };
    publishDeviceSetting(
      channelProperty,
      LocalUIHttp?.DEVICE_ACCESS_UI,
      publishPayload,
    );
  };

  useEffect(() => {
    if (currentUserTimezone) {
      let secTimer = setInterval(() => {
        setCurrentTime(
          moment
            .tz(
              moment(),
              findTimeZoneFromSelectedValue(currentUserTimezone)?.location,
            )
            .format('MMM DD, YYYY hh:mm:ss a'),
        );
      }, 1000);
      return () => clearInterval(secTimer);
    }
  }, [currentUserTimezone]);

  useEffect(() => {
    setCurrentUserTimezone(timezone);
  }, [deviceInfos]);

  useEffect(() => {
    if (formRef?.current && saveAudioSettingsFlag) {
      formRef?.current?.handleSubmit();
    }
  }, [saveAudioSettingsFlag]);

  useEffect(() => {
    moveDeviceLocation(slectedAreaLocation);
  }, [deviceName]);

  const moveDeviceLocation = async (slectedAreaLocation) => {
    try {
      const reqBody = {
        oldLocationId: deviceInfos?.locationId,
        oldAreaId: deviceInfos?.areaId,
        olddeviceName: deviceInfos?.deviceName,
        newLocationId:
          Object.keys(slectedAreaLocation).length === 0
            ? deviceInfos?.locationId
            : slectedAreaLocation?.locationId,
        newAreaId:
          Object.keys(slectedAreaLocation).length === 0
            ? deviceInfos?.areaId
            : slectedAreaLocation.areaId,
        deviceName: deviceName ? deviceName : deviceInfos?.deviceName,
        orgId: deviceInfos?.orgId,
        deviceId: deviceInfos?.deviceId,
      };
      moveDeviceDetailCallback(reqBody);
    } catch (error) {
      Utils.vmsLogger().error('ERROR: ', error);
    }
  };

  const validateAuthenticated = Yup.object({
    deviceName: Yup.string().max(40, constants.DEVICE_NAME_MAX_LENGTH_MESSAGE),
  });

  const onDeviceNameChange = (e, setFieldValue) => {
    if (e?.target?.value?.length <= 40) {
      setDeviceName(e.target.value);
      setFieldValue('deviceName', e.target.value);
    } else {
      setFieldValue('deviceName', e.target.value);
      setDeviceName(deviceInfos?.deviceName);
    }
  };

  const openInNewTab = () => {
    setShowError(false);
    if (showUrl) {
      let fullUrl =
        showUrl?.indexOf('://') === -1 ? 'http://' + showUrl : showUrl;
      window.open(fullUrl, '_blank');
    }
  };

  const hidePopUp = async () => {
    dispatch(setAllMqttDataFromResponse({}));
    setActiveSessionModal(false);
    setShowError(false);
  };

  return (
    <div
      className={`text-start audio-settings-block ${
        curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS
          ? ' editable-off-offline'
          : ''
      }`}
    >
      {showMsg && (
        <Alert variant={variant} onClose={() => setShowMsg(false)} dismissible>
          <div className="d-flex">
            <div>
              <HiOutlineExclamationCircle
                size={20}
                stroke={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--error_24')}
              />
            </div>
            <div className="text-alignment">
              {!userMsg ? (
                <p>{userMsg}</p>
              ) : (
                <>
                  <Alert.Heading>
                    {constants.UNABLE_TO_SAVE_DEVICE_INFO_HEADING}
                  </Alert.Heading>
                  <p>{constants.UNABLE_TO_SAVE_DEVICE_INFO_MESSAGE}</p>
                </>
              )}
            </div>
          </div>
        </Alert>
      )}
      {deviceInfos && (
        <Formik
          enableReinitialize
          innerRef={formRef}
          initialValues={{
            deviceName: deviceInfos?.deviceName,
            volume: 2,
            sensitivity: 2,
            deviceDegrees: '',
            noiseReduction: false,
            audioOutVolume: 2,
            areaData: deviceInfos?.areaId,
            timezone: findTimeZoneFromSelectedValue(
              currentUserTimezone ? currentUserTimezone : timezone,
            )?.location,
          }}
          validationSchema={validateAuthenticated}
          onSubmit={(values, { setSubmitting }) => {
            // TODO : Delete later
            Utils.vmsLogger().log(values);
            setSubmitting(true);
            setVariant('danger');
            setUserMsg('');
            setSubmitting(false);
            saveAudioSettingsCallback(false);
          }}
        >
          {({ values, handleSubmit, setFieldValue, handleChange }) => (
            <Form className="form device-info-form" onSubmit={handleSubmit}>
              <Row className="settings-block">
                <Col>
                  <div className="section-heading mb-2 mt-4">
                    <label>{constants.DEVICE_INFO_DEVICE_NAME_TITLE}</label>
                  </div>
                  <TextField
                    required={true}
                    removebottommargin="true"
                    removetopmargin="true"
                    label=""
                    placeholder={constants.DEVICE_INFO_MEETING_ROOM_TITLE}
                    name="deviceName"
                    value={values.deviceName}
                    type="text"
                    onChange={(e) => onDeviceNameChange(e, setFieldValue)}
                  />
                </Col>
              </Row>
              <div className="tab-title device-info-title">
                <label>{constants.DEVICES_TAB_DEVICE_INFO}</label>
              </div>
              <div className="device-info-section">
                <Row className="settings-block">
                  <Col>
                    <div className="settings-info-block-heading">
                      {constants.DEVICE_INFO_DEVICE_STATUS_TITLE}
                    </div>
                    <div className="settings-info-block-content mb-3">
                      {curDeviceStatus ===
                      constants.DEVICES_RETURN_ONLINE_STATUS ? (
                        <FaCircle size={10} className="status-icon-online" />
                      ) : curDeviceStatus ===
                        constants.DEVICES_RETURN_OFFLINE_STATUS ? (
                        <FaCircle size={10} className="status-icon-offline" />
                      ) : curDeviceStatus ===
                        constants.DEVICES_RETURN_DEACTIVATED_STATUS ? (
                        <FaCircle
                          size={10}
                          className="status-icon-deactivated"
                        />
                      ) : (
                        <FaCircle size={10} className="status-icon-entered" />
                      )}
                      {curDeviceStatus}
                    </div>
                    {curDeviceStatus ===
                      constants.DEVICES_RETURN_OFFLINE_STATUS && (
                      <Alert variant="danger">
                        <div className="d-flex">
                          <div>
                            <HiOutlineExclamationCircle
                              size={20}
                              stroke={getComputedStyle(
                                document.documentElement,
                              ).getPropertyValue('--error_24')}
                            />
                          </div>
                          <div className="text-alignment">
                            {userMsg !== '' ? (
                              <p>{userMsg}</p>
                            ) : (
                              <>
                                {constants.UNABLE_TO_SAVE_DEVICE_INFO_HEADING}
                                <p>
                                  {constants.UNABLE_TO_SAVE_DEVICE_INFO_MESSAGE}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </Alert>
                    )}
                  </Col>
                </Row>
                {Utils.getPropertyShowStatus(
                  curDeviceStatus,
                  'serial-number',
                  capbilityProperty,
                ) && (
                  <Row className="settings-block">
                    <Col>
                      <div className="settings-info-block-heading">
                        {constants.DEVICES_SERIAL_NO_TEXT}
                      </div>
                      <div className="settings-info-block-content">
                        {deviceInfos?.serialNo}
                      </div>
                    </Col>
                  </Row>
                )}
                {Utils.getPropertyShowStatus(
                  curDeviceStatus,
                  'mac-address',
                  capbilityProperty,
                ) && (
                  <Row className="settings-block">
                    <Col>
                      <div className="settings-info-block-heading">
                        {constants.DEVICE_INFO_DEVICES_MAC_ADDRESS_TITLE}
                      </div>
                      <div className="settings-info-block-content">
                        {deviceInfos?.macAddress}
                      </div>
                    </Col>
                  </Row>
                )}
                <Row className="settings-block">
                  <Col>
                    <div className="settings-info-block-heading">
                      {constants.DEVICE_INFO_FIRMWARE_TITLE}
                    </div>

                    <div className="settings-info-block-content">
                      {/* TODO : This static value here -- gonna changed later based on API data */}
                      {deviceInfos?.properties?.['firmware-version']
                        ? deviceInfos?.properties?.['firmware-version']
                        : '--'}
                    </div>
                  </Col>
                </Row>
                <Row className="settings-block">
                  <Col>
                    <div className="settings-info-block-heading">
                      {t('CHANNELS_INFO_FIRMWARE_TITLE')}
                    </div>
                    <div className="settings-info-block-content">
                      {/* TODO : This static value here -- gonna changed later based on API data */}
                      {deviceInfos?.properties?.['edge-app-version']
                        ? deviceInfos?.properties?.['edge-app-version']
                        : '--'}
                    </div>
                  </Col>
                </Row>
                {deviceInfos?.properties?.['avlbl-duclo-ver'] &&
                  deviceInfos?.properties?.['edge-app-version'] !=
                    deviceInfos?.properties?.['avlbl-duclo-ver'] && (
                    <Row className="settings-block">
                      <Col>
                        <div
                          className="alert alert-danger info-update"
                          role="alert"
                        >
                          <div className="alert-message">
                            <HiOutlineExclamationCircle
                              size={15}
                              stroke={getComputedStyle(
                                document.documentElement,
                              ).getPropertyValue('--error_24')}
                              className="alert-icon"
                            />
                          </div>
                          <p>
                            {' '}
                            {constants.UPDATE_AVAILABLE_TITLE}{' '}
                            <span
                              onClick={() => updateFirmware()}
                              style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                textDecorationColor: getComputedStyle(
                                  document.documentElement,
                                ).getPropertyValue('--error_24'),
                              }}
                            >
                              {constants.HERE_LABEL}
                            </span>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  )}
                {Utils.getPropertyShowStatus(
                  curDeviceStatus,
                  'manufacturer',
                  capbilityProperty,
                ) && (
                  <Row className="settings-block">
                    <Col>
                      <div className="settings-info-block-heading">
                        {constants.DEVICES_MANUFACTURER_TEXT}
                      </div>
                      <div className="settings-info-block-content">
                        {Utils.getManufacturer(deviceInfos?.manufacturer)}
                      </div>
                    </Col>
                  </Row>
                )}

                {Utils.getPropertyShowStatus(
                  curDeviceStatus,
                  'model',
                  capbilityProperty,
                ) && (
                  <Row className="settings-block">
                    <Col>
                      <div className="settings-info-block-heading">
                        {constants.DEVICES_MODEL_TEXT}
                      </div>
                      <div className="settings-info-block-content">
                        {deviceInfos?.modelNumber}
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
              <Row className="settings-block mt-4">
                <Col>
                  <div className="device-infodate-time-title device-location">
                    {constants.DEVICE_INFO_ASSIGN_LOCATIONS_TITLE}
                  </div>
                  <DeviceLocationAreas
                    defaultLocaionsId={deviceInfos?.locationId}
                    defaultLocaionsData={values.areaData}
                    updateLocationData={(selectedLocationArea) => {
                      setslectedAreaLocation(selectedLocationArea);
                      moveDeviceLocation(selectedLocationArea);
                      setFieldValue('areaData', selectedLocationArea.areaId);
                    }}
                  />
                </Col>
              </Row>

              {Utils.getPropertyShowStatus(
                curDeviceStatus,
                'isProxyConnSupport',
                capbilityLocalUi,
              ) && (
                <Row className="settings-block mt-3">
                  <Col>
                    <div className="local-camera-setting">
                      {constants.HTTP_PROXIY_LOCAL_CAMERA_SETTING}
                    </div>
                    <div className="http-proxiy-label-title">
                      {constants.HTTP_PROXIY_LOCAL_CAMERA_SETTING_MESSAGE}
                    </div>
                    {currentUiStatus != 1 && (
                      <div
                        className={`error-container-http ${
                          currentUiStatus == 2 && 'active-seesion-same-channel'
                        }`}
                      >
                        <img
                          className="img-error-info"
                          alt=""
                          src={
                            currentUiStatus == 2 ? ErrorInfoActive : ErrorInfo
                          }
                        ></img>
                        <div
                          className={`error-message-http ${
                            currentUiStatus == 2 &&
                            'active-seesion-same-channel'
                          } `}
                        >
                          {currentUiStatus == 2
                            ? constants.HTTP_PROXIY_ACTIVE_SESSION_SAME_CHANNEL_SAME_HUB
                            : constants.HTTP_PROXIY_ACTIVE_SESSION_OTHER_CHANNEL_SAME_HUB}
                        </div>
                      </div>
                    )}
                    <div className="remotly-connect-layout">
                      <div className="left-container-setting">
                        <div className="remotly-label">
                          {constants.HTTP_PROXIY_REMOTELY_CONNECT}
                        </div>
                        <img
                          onClick={() => openInNewTab()}
                          className={`img-launch ${
                            currentUiStatus !== 2 && 'disable-launch'
                          }`}
                          alt=""
                          src={
                            currentUiStatus == 3 || currentUiStatus == 1
                              ? LaunchDisable
                              : LaunchEnable
                          }
                        ></img>
                      </div>
                      <div className="right-container-setting">
                        {!showSpiner && (
                          <div
                            onClick={(e) => connectDisconnectCall(e)}
                            className={`${
                              curDeviceStatus ===
                              constants.DEVICES_RETURN_OFFLINE_STATUS
                                ? 'connect-disconnect-disabled-text'
                                : 'connect-disconnect-text'
                            }`}
                          >
                            {currentUiStatus == 3 || currentUiStatus == 1
                              ? constants.HTTP_PROXIY_CONNECT_LABEL
                              : constants.HTTP_PROXIY_DISCONNECT_LABEL}
                          </div>
                        )}
                        {showSpiner && (
                          <SiteSpinner height="20px" width="20px" />
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </Form>
          )}
        </Formik>
      )}
      <SiteModal
        modalTitle={constants.HTTP_PROXIY_LOCAL_CAMERA_SETTING}
        showModal={activeSessionModal}
        hideModal={() => {
          hidePopUp();
        }}
        classes="device-settings-restart-required"
      >
        <div className="modal-local-ui">
          <div className="image-icon-div">
            <RxCross2 size={30} className="img-cross" />
          </div>
          <div className="message-modal-connect">
            {!showError
              ? constants.HTTP_PROXIY_MODAL_ERROR_MESSAGE_REMOTE_SESSION
              : showError}
          </div>
          {!showError && (
            <div className="label-modal-text">
              {constants.HTTP_PROXIY_MODAL_SUB_TITLE}
            </div>
          )}
          <PrimaryButton
            className="btn btn-primary mt-3"
            type="button"
            width="100%"
            height="56px"
            fontSize="18px"
            lineHeight="24px"
            onClick={() => {
              hidePopUp();
            }}
          >
            {!showError
              ? constants.HTTP_PROXIY_MODAL_TRY_AGAIN_LATER
              : showError === constants.HTTP_PROXIY_MODAL_UNKNOWN_ERROR ||
                  showError === constants.HTTP_PROXY_MODAL_TUNNEL_ERROR
                ? constants.HTTP_PROXIY_MODAL_RETURN_TO_ONCLOUD
                : constants.HTTP_PROXIY_MODAL_TRY_AGAIN_LATER}
          </PrimaryButton>
          {!showError && (
            <PrimaryButton
              className="adddevice-btn"
              fontSize="18px"
              backgroundColor={
                showLoader
                  ? getComputedStyle(document.documentElement).getPropertyValue(
                      '--primary_40',
                    )
                  : getComputedStyle(document.documentElement).getPropertyValue(
                      '--brand_white',
                    )
              }
              height="56px"
              color={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--primary_40')}
              type="submit"
              loader={showLoader}
              onClick={() => {
                setShowLoader(true);
                mqttCallConnect(true);
              }}
            >
              {constants.HTTP_PROXIY_CONNECT_LABEL}
            </PrimaryButton>
          )}
        </div>
      </SiteModal>
    </div>
  );
};

export default DeviceInfoBlock;
