import { useEffect, useState } from 'react';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';

import { TextBlock } from '../../../components/common';
import { constants } from '../../../helpers';

const StatusFilters = ({ statusFilters, initialLoad, updateFilters }) => {
  const handleCheckboxChange = (key) => {
    updateFilters((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, show: !filter.show } : filter,
      ),
    );
  };

  return (
    <>
      {Array.isArray(statusFilters) &&
        statusFilters.map((status) => (
          <TextBlock
            key={`textblock-${status?.key}`}
            className="filters-content-item"
          >
            <div className="filters-content-item-details">
              {status.displayText}
            </div>
            <Checkbox
              key={`checkbox-${status?.key}`}
              className="filters-content-item-checkbox"
              name={status.key}
              defaultChecked={true}
              checked={status.show}
              sx={{
                '& .MuiSvgIcon-root': { fontSize: 26 },
                [`&, &.${checkboxClasses.checked}`]: {
                  color: getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--brand_primary'),
                },
              }}
              onChange={(e) => handleCheckboxChange(status.key)}
            />
          </TextBlock>
        ))}
    </>
  );
};

export default StatusFilters;
