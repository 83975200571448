import { useRef, useEffect, useState, useCallback } from 'react';
import './editcreatesheduled.scss';
import { RxCross1 } from 'react-icons/rx';
import { Utils, constants } from '../../../helpers';
import moment from 'moment';
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField, useMediaQuery } from '@mui/material';
import { PrimaryButton } from '../../../components/common';
import { useOrganizations } from '../../../store/OrganizationsStore';
import { useNotificationStore } from '../../../store/CreateNotificationStore';
import timezones from '../../../data/support/timezone.json';
import { useSelector } from 'react-redux';
import { getCustomerOrgData } from '../../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../../store/indexDB/observer';
import { findTimeZoneFromSelectedValue } from '../../../helpers/commonUtils';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';

const CreateEditSheduled = (props) => {
  const cDate = moment.tz(moment(), moment.tz.guess()).format('MMM DD, YYYY');
  const zoneTimeList = timezones?.data;
  const getStepsData = useNotificationStore((state) => state.getStepsData);
  const [currentUserTimezone, setCurrentUserTimezone] = useState({});
  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  // let custOrg = getCustomerOrgData();
  // const custOrgData = custOrg[0];
  const [custOrgData, setCustOrgData] = useState();
  const [updateDayStaus, setUpdateDayStaus] = useState(false);
  const [isCreate, setIsCreate] = useState(
    Object.keys(props?.selectedItem).length === 0,
  );
  const [selectedIds, setSelectedIds] = useState(
    isCreate ? ['2', '4', '6'] : props?.selectedItem?.days,
  );
  const isScreenSmall = useMediaQuery('(max-height: 640px)');
  const [daysList, setDaysList] = useState(
    Utils.getWeekDays(isCreate ? ['2', '4', '6'] : props?.selectedItem?.days),
  );

  const [startTime, setStartTime] = useState(
    new Date(
      cDate +
        ' ' +
        (!isCreate
          ? Utils.getHoursFromValue(props?.selectedItem?.startTime)
          : '00:00:00'),
    ).getTime(),
  );
  const [endTime, setEndTime] = useState(
    new Date(
      cDate +
        ' ' +
        (!isCreate
          ? Utils.getHoursFromValue(props?.selectedItem?.endTime)
          : '23:59:00'),
    ).getTime(),
  );

  const setStartEndTime = (epochValue, isTimeStart) => {
    if (isNaN(epochValue)) return;
    if (isTimeStart) {
      setStartTime(epochValue);
    } else {
      setEndTime(epochValue);
    }
  };

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setCustOrgData(orgs?.[0] || {});
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000,
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  useEffect(() => {
    if (updateDayStaus) {
      const dayIds = [];
      daysList &&
        daysList.forEach((days) => {
          if (days.isSelected) {
            dayIds.push(days.id);
          }
        });
      setSelectedIds(dayIds);
      setUpdateDayStaus(false);
    }
  }, [updateDayStaus]);

  const selectedDay = (dayData) => {
    const dayListfirm = [...daysList];
    dayListfirm.map((data, i) => {
      if (dayData.id == data.id) {
        if (data.isSelected && selectedIds?.length != 1) {
          daysList[i].isSelected = false;
        } else {
          daysList[i].isSelected = true;
        }
      }
    });
    setDaysList(dayListfirm);
    setUpdateDayStaus(true);
  };

  const renderTimePicker = (timeValue, isStart) => {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopTimePicker
          //ampm={false}
          readOnly={false}
          closeOnSelect={false}
          value={moment(timeValue)}
          onChange={(newValue) => {
            let epochValue = newValue?.unix() * 1000; // milliseconds
            setStartEndTime(epochValue, isStart);
          }}
          slotProps={{
            popper: {
              sx: {
                ...(isScreenSmall
                  ? {
                      '& .MuiPaper-root': {
                        position: 'fixed',
                        top: '-300px',
                        width: '480px',
                        height: '400.48px',
                      },
                    }
                  : {}),
              },
            },
          }}
          className="custom-non-error-style"
          renderInput={(params) => (
            <TextField
              sx={{
                svg: {
                  display: getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--greyscale_40'),
                },
                input: {
                  color: getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--brand_black'),
                  fontWeight: 500,
                },
              }}
              {...params}
              variant="outlined"
              margin="normal"
              fullWidth
            />
          )}
        />
      </LocalizationProvider>
    );
  };

  const addDeleteScheduled = (isDelete) => {
    const daysSelected = selectedIds?.join(',');
    const scheduledItem = {
      startTime: Utils.getStartEndMin(
        moment(startTime).format('DD MMM YYYY HH:mm'),
        true,
      ).toString(),
      endTime: Utils.getStartEndMin(
        moment(endTime).format('DD MMM YYYY HH:mm'),
        true,
      ).toString(),
      days: daysSelected?.split(','),
      isEnabled: true,
    };
    props.onSelectItemChange(scheduledItem, isCreate, isDelete);
  };

  useEffect(() => {
    const location = custOrgData?.locations?.find(
      (item) => item?.locationId === getStepsData()?.locationId,
    );
    if (location) {
      const userTimeZone = findTimeZoneFromSelectedValue(location?.timezone);
      setCurrentUserTimezone(userTimeZone);
    }
  }, [custOrgData?.orgId]);

  const showDays = (items) => {
    const daysMap = {
      1: constants.SUN,
      2: constants.MON,
      3: constants.TUE,
      4: constants.WED,
      5: constants.THU,
      6: constants.FRI,
      7: constants.SAT,
    };

    const selectedDays = items.filter((item) => item.isSelected);

    return selectedDays.map((item, index) => {
      const dayName = daysMap[item.id];
      if (selectedDays.length === 1) {
        return (
          <span key={item.id} className="day-text">
            {dayName}
          </span>
        );
      } else {
        const comma = index < selectedDays.length - 1 ? ', ' : ''; // Add comma if not the last selected day
        return (
          <span key={item.id} className="day-text">
            {dayName}
            {comma}
          </span>
        );
      }
    });
  };

  return (
    <div className="create-edit-container">
      <div className="create-edit-header">
        <div className="label-title ">
          {isCreate ? 'Create Schedule' : 'Edit Schedule '}
        </div>
        <RxCross1 size={24} onClick={() => props.hideContainer(true)} />
      </div>
      <div className="choose-day-conatiner">
        <div className="title-day">
          {constants.NOTIFICATION_CREATE_CHOOSE_DAY}
        </div>
        <div className="day-list-container">
          {daysList?.map((days) => {
            return (
              <div
                onClick={() => selectedDay(days)}
                key={days.id}
                className={`day-blocks ${days.isSelected ? 'selected' : ''}`}
              >
                <div className="label-value">{days.dayPrefix}</div>
              </div>
            );
          })}
        </div>
        <div className="m-t-8 days-style">{showDays(daysList)}</div>
        <div className="label-title">
          {constants.NOTIFICATION_CREATE_CHOOSE_TIME}
        </div>
        <div className="timezone">
          {currentUserTimezone ? currentUserTimezone?.label : '--'}
        </div>
        <div className="time-slot-container">
          <div className="time-container_x">
            <div className="timer-start-end">
              {renderTimePicker(startTime, true)}
            </div>
            <div className="seprator">-</div>
            <div className="timer-start-end">
              {renderTimePicker(endTime, false)}
            </div>
          </div>
          <div className="notification-sheduled-text m-t-8">
            {constants.NOTIFICATION_CREATE_SCHEDULED_MESSAGE}
          </div>
        </div>
        <PrimaryButton
          className="adddevice-btn"
          fontSize="14px"
          backgroundColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--primary_40')}
          height="44px"
          color="#FFFFFF"
          type="submit"
          onClick={() => addDeleteScheduled(false)}
        >
          {isCreate
            ? constants.NOTIFICATION_CREATE_BTN
            : constants.NOTIFICATION_CREATE_UPDATE_BTN}
        </PrimaryButton>

        {!isCreate && (
          <PrimaryButton
            className="adddevice-btn"
            fontSize="14px"
            backgroundColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--brand_white')}
            height="44px"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--error_48',
            )}
            type="submit"
            borderColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--error_48')}
            hoverBackgroundColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--error_48')}
            hoverColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--brand_white')}
            hoverBorderColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--brand_white')}
            onClick={() => addDeleteScheduled(true)}
          >
            {constants.NOTIFICATION_CREATE_DELETE_BTN}
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

export default CreateEditSheduled;
