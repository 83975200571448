import * as MP4Box from 'mp4box';
import { v4 as uuidv4 } from 'uuid';
import Store from '../../store/Store';
import {
  setElementUpdated,
  setPlaybackBufferMeta,
  setStreamLoader,
  setWSSConnection,
} from '../../store/reducers/StreamingReducer';
import { Utils } from '../../helpers';
import moment from 'moment';

const generateUUID = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

// ====================

function getOurId() {
  return Math.floor(Math.random() * (9000 - 10) + 10).toString();
}

// =================

// ====================
let videoElement = null;
// const nextVideoElements = [];
let videoContainer = null;
let currentPlayStartTime = '';
let audioMuteUnmute = false;
let mp4BoxFile = null;
const ctsArray = [];
let virtualTimeInterval = null;
let virtualCurrentTime = null;
let nextAvailTime;
let noVideoFromTimestamp;
let noVideo = null;
let timelineRef;
let token;
let websocket;
let orgID;
let deviceID;
let cID;
let playbackUUID;
let sendComplete = false;

// function ntpTimestampToUnixTimestamp(ntpTimestamp) {
//     /* global BigInt */
//   const bigIntNtpTimestamp = BigInt(ntpTimestamp);
//   const NTP_UNIX_EPOCH_DIFF = 2208988800n; // Seconds between 1900 and 1970
//   const TWO_TO_THE_32 = 2n ** 32n; // 2^32 to separate seconds and fractions

//   const seconds = bigIntNtpTimestamp >> 32n; // High 32 bits for seconds
//   const fractional = bigIntNtpTimestamp & (TWO_TO_THE_32 - 1n); // Low 32 bits for fractional part
//   const fractionalMilliseconds = Number((fractional * 1000n) / TWO_TO_THE_32);
//   const unixSeconds = Number(seconds - NTP_UNIX_EPOCH_DIFF);
//   const unixTimestamp = unixSeconds * 1000 + fractionalMilliseconds;

//   return unixTimestamp;
// }
// var time_dict = {};

// ==================

function startConnection(serverDetails) {
  // const peerId = getOurId();
  const playbackServer = serverDetails?.timeline_server;
  websocket = new WebSocket(
    `${playbackServer.protocol}://${playbackServer.host}:${playbackServer.port}`,
  );

  // time_dict[index] = Date.now();
  // console.log("=== session id:", sessionId, index, time_dict[index]);

  const checkVideoStartTime = () => {
    const { sourceBuffers } = videoElement;
    videoElement.currentTime = Math.max(
      ...Object.values(sourceBuffers).map((sb) => sb.startTime || 0),
    );
  };

  websocket.onopen = async () => {
    Store.dispatch(setWSSConnection(true));
    cID = uuidv4();
    playbackUUID = uuidv4();
    const registerObj = {
      appid: uuidv4(),
      token: token,
      cid: cID,
      tid: Date.now().toString(),
      to: deviceID,
      from: orgID,
      msg: {
        action: 'set',
        resource: 'media/handshake',
        properties: {
          uuid: playbackUUID,
          type: 'REGISTER',
        },
      },
    };
    if (websocket && websocket.readyState === 1) {
      const registerMsg = JSON.stringify(registerObj, null, 2);
      websocket.send(registerMsg);
      // console.log('Registering with server, setting button value to Connect');
    }
  };

  websocket.onerror = (err) => {
    console.log('websocket error', err);
    websocket.close();
  };

  websocket.onclose = (e) => {
    console.log('cloud playback web socket closed', e);
    Store.dispatch(setWSSConnection(false));
  };

  async function handleStream(event) {
    try {
      const data = event.data;
      const blob = await data.arrayBuffer();
      const buffer = new Uint8Array(blob);
      const type = 'video';

      function appendBufferToSourceBuffer(targetVideoElement) {
        const { sourceBuffers, segmentQueues } = targetVideoElement;
        const sourceBuffer = sourceBuffers[type];
        const segmentQueue = segmentQueues[type];

        if (!sourceBuffer.updating && segmentQueue.length > 0) {
          sourceBuffer.appendBuffer(segmentQueue.shift());
        }
      }

      if (checkInitSegment(buffer)) {
        // if (
        //   useMultipleInitMp4.checked &&
        //   Object.keys(videoElement.sourceBuffers).length > 0
        // ) {
        //   videoElement.mediaSource.endOfStream();
        //   videoElement = nextVideoElements.at(-1);
        //   nextVideoElements.push(createVideoElement());
        // }
        const { sourceBuffers, segmentQueues, mediaSource } = videoElement;
        if (!segmentQueues[type]) segmentQueues[type] = [];
        const segmentQueue = segmentQueues[type];

        mp4BoxFile = await loadMp4BoxFile(buffer);
        // window.mp4BoxFile = mp4BoxFile;
        // if (mp4BoxFile.prft) {
        //   const timestamp = ntpTimestampToUnixTimestamp(
        //     mp4BoxFile.prft.ntp_timestamp,
        //   );
        //   console.log('=== PRFT Date: ', dayjs(timestamp).toISOString());
        // }
        // console.log(mp4BoxFile.prfts);
        const mimeCodec = mp4BoxFile.getInfo().mime;
        console.log('=== mime codec:', mimeCodec);
        if (!MediaSource.isTypeSupported(mimeCodec)) {
          console.error('Mime codec not supported: codec=', mimeCodec);
          return;
        }
        if (mediaSource.sourceBuffers.length) {
          console.log(
            "=== mutliple resolution detected, but don't add new SourceBuffer",
          );
          segmentQueue.push(buffer);
          return;
        }
        sourceBuffers[type] = mediaSource.addSourceBuffer(mimeCodec);
        const sourceBuffer = sourceBuffers[type];
        sourceBuffer.addEventListener('updateend', (event) => {
          appendBufferToSourceBuffer(videoElement);
          const targetSourceBuffer = event.target;
          if (
            typeof targetSourceBuffer.startTime === 'undefined' &&
            targetSourceBuffer.buffered.length > 0
          ) {
            targetSourceBuffer.startTime = event.target.buffered.start(0);
            checkVideoStartTime();
          }
        });
        sourceBuffer.addEventListener('error', (error) => {
          console.log('=== source buffer error: ', error, videoElement?.error);
        });
        segmentQueue.push(buffer);
      } else {
        const { sourceBuffers, segmentQueues } = videoElement;
        const segmentQueue = segmentQueues[type];

        if (!sourceBuffers[type]) {
          console.log(
            '=== Source buffer not initialized, data received before the init segment is discarded.',
          );
          return;
        }
        segmentQueue.push(buffer);
        appendBufferToSourceBuffer(videoElement);
      }
    } catch (error) {
      console.log('=== media source error: ', error, videoElement?.error);
      //   mediaSource.endOfStream();
    }
  }

  websocket.onmessage = async (event) => {
    // console.log("=== received message: ", event.data);
    const data = event.data;
    if (data instanceof ArrayBuffer || data instanceof Blob) {
      // console.log("handling the stream...")
      await handleStream(event);
    } else {
      const responseMessage = JSON.parse(data);
      const type = responseMessage.msg.properties.type;
      console.log(JSON.stringify(responseMessage, null, 2));
      switch (type) {
        case 'REGISTERED':
          console.log('Registered with server');
          break;
        case 'READY':
          console.log('Ready to stream');
          break;
        case 'ERROR':
          console.error(event.data);
          break;
        case 'SEND_COMPLETE':
          sendComplete = true;
          break;
        case 'NO_VIDEO':
          noVideoFromTimestamp = responseMessage?.msg?.properties?.from;
          // check whether video is in playing state or not
          const isVideoPlaying = !!(
            videoElement?.currentTime > 0 &&
            !videoElement?.paused &&
            !videoElement?.ended &&
            videoElement?.readyState > 2
          );
          if (
            !responseMessage?.msg?.properties?.next_avail_time &&
            !isVideoPlaying
          ) {
            clearLocalTimerCloud();
            startClientSideTimer();
          }
          if (responseMessage?.msg?.properties?.next_avail_time) {
            clearLocalTimerCloud();
            nextAvailTime = responseMessage?.msg?.properties?.next_avail_time;
            startClientSideTimer();
          }
        default:
          break;
      }
    }
  };
}

function checkInitSegment(buffer) {
  // console.log("=== check init segment: ", buffer.byteLength);
  const ftypBox = String.fromCharCode.apply(null, buffer.subarray(4, 8));
  return ftypBox === 'ftyp';
}

function loadMp4BoxFile(u8Buffer) {
  return new Promise((resolve, reject) => {
    const mp4BoxFile = MP4Box.createFile(false);
    mp4BoxFile.onReady = (info) => {
      console.log('=== mp4 box info: ', info);
      resolve(mp4BoxFile);
    };
    mp4BoxFile.onError = reject;
    u8Buffer.buffer.fileStart = 0;
    mp4BoxFile.appendBuffer(u8Buffer.buffer);
    mp4BoxFile.flush();
  });
}

function createVideoElement() {
  videoElement = document.createElement('video');

  videoElement.controls = false;
  videoElement.muted = audioMuteUnmute;
  videoElement.style.width = '100%';
  videoElement.id = 'playback-video';
  const mediaSource = new MediaSource();
  videoElement.mediaSource = mediaSource;
  videoElement.src = URL.createObjectURL(mediaSource);
  videoElement.sourceBuffers = {};
  videoElement.segmentQueues = {};

  mediaSource.addEventListener('sourceopen', () => (mediaSource.duration = 0));

  // videoElement.addEventListener("ended", addNewVideoElement);
  videoElement.addEventListener('error', handleVideoEvents);
  videoElement.addEventListener('progress', handleVideoEvents);
  videoElement.addEventListener('canplay', handleVideoEvents);
  videoElement.addEventListener('waiting', handleVideoEvents);
  return videoElement;
}

const handleVideoEvents = (event) => {
  const isLoaderDisplay = Store.getState()?.streaming?.streamLoader;
  switch (event.type) {
    case 'canplay':
      noVideo.style.display = 'none';
      if (isLoaderDisplay) {
        Store.dispatch(setStreamLoader(false));
      }
      break;

    case 'waiting':
      const bufferEnd =
        videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);

      if (
        sendComplete &&
        parseInt(videoElement?.currentTime, 10) >= parseInt(bufferEnd, 10)
      ) {
        handleNoVideoCases(event);
      } else {
        if (!isLoaderDisplay) {
          Store.dispatch(setStreamLoader(true));
        }
      }
      break;

    case 'playing':
      noVideo.style.display = 'none';
      if (isLoaderDisplay) {
        Store.dispatch(setStreamLoader(false));
      }
      break;

    case 'progress':
      assignBufferedMeta();
      break;

    case 'error':
      handleVideoError(event);
      break;

    default:
      break;
  }
};

const handleNoVideoCases = (event) => {
  // handling this function when video is playing and gap occurred.
  try {
    const bufferEnd =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);
    noVideo.style.display = 'flex';
    virtualCurrentTime = parseInt(bufferEnd, 10);
    startClientSideTimer();
  } catch (err) {}
};

const startClientSideTimer = () => {
  const isLoaderDisplay = Store.getState()?.streaming?.streamLoader;
  noVideo.style.display = 'flex';
  if (isLoaderDisplay) {
    Store.dispatch(setStreamLoader(false));
  }
  const bufferEnd =
    videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);
  virtualCurrentTime = parseInt(bufferEnd, 10) || virtualCurrentTime;
  console.log('nextAvailTime is available', nextAvailTime, virtualCurrentTime);
  if (nextAvailTime && virtualCurrentTime <= nextAvailTime / 1000) {
    videoElement.pause();
    videoElement.removeEventListener('progress', handleVideoEvents);
    videoElement.removeEventListener('canplay', handleVideoEvents);
    URL.revokeObjectURL(videoElement.src);
    videoElement.remove();
    sendCloudPlayRequest(
      Utils.getDate(parseInt(nextAvailTime / 1000, 10) + 1),
      timelineRef,
      false,
    );
    Store.dispatch(setElementUpdated(true));

    virtualTimeInterval = setInterval(() => {
      virtualCurrentTime += 1000 / 1000;
      if (virtualCurrentTime <= nextAvailTime / 1000) {
        console.log('next avail time with timer');
        timelineRef?.current?.$el?.moveTo(
          moment(Utils.getDate(virtualCurrentTime)),
        );
      } else {
        console.log('reached to next avail time and play');
        clearLocalTimerCloud();
        noVideo.style.display = 'none';
        videoElement.play();
      }
    }, 1000);
  } else {
    console.log('virtual current time', virtualCurrentTime, nextAvailTime);
    if (
      !nextAvailTime ||
      parseInt(nextAvailTime / 1000, 10) < virtualCurrentTime
    ) {
      let nextPlayCheckTime = virtualCurrentTime + 60;
      if (videoElement) {
        videoElement.pause();
        sendCloudStopRequest();
        cleanupResources();
      }
      virtualTimeInterval = setInterval(() => {
        virtualCurrentTime += 1000 / 1000;
        if (
          !nextAvailTime ||
          parseInt(nextAvailTime / 1000, 10) <= virtualCurrentTime
        ) {
          console.log('start client side timer is runnning');
          timelineRef?.current?.$el?.moveTo(
            moment(Utils.getDate(virtualCurrentTime)),
          );
        } else {
          console.log('stop the local timer and send play reuest');
          clearLocalTimerCloud();
          noVideo.style.display = 'none';
          sendCloudPlayRequest(
            Utils.getDate(parseInt(nextAvailTime / 1000, 10) + 1),
            timelineRef,
            true,
          );
        }
        if (nextPlayCheckTime === virtualCurrentTime) {
          console.log('send play request for next available time');
          clearLocalTimerCloud();
          sendCloudPlayRequest(
            Utils.getDate(nextPlayCheckTime),
            timelineRef,
            true,
          );
        }
      }, 1000);
    }
  }
};

const handleVideoError = (event) => {
  console.log('video error', videoElement?.error);
};

const assignBufferedMeta = () => {
  if (
    videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered.length > 0
  ) {
    const bufferStart =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.start(0);
    const bufferEnd =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);
    if (bufferStart && bufferEnd) {
      const obj = {
        id: deviceID,
        client: {
          start: parseInt(bufferStart, 10) * 1000,
          end: parseInt(bufferEnd, 10) * 1000,
          className: 'new-meta-buffer-class',
        },
      };
      Store.dispatch(setPlaybackBufferMeta(obj));
    }
  }
};

export const connectCloudPlayback = (getPlatformDetails, deviceId, orgId) => {
  deviceID = deviceId;
  orgID = orgId;
  token = getPlatformDetails?.timeline_server?.token;
  startConnection(getPlatformDetails);
};

export const sendCloudPlayRequest = (
  timestamp,
  timeline,
  muteUnmuteAudio,
  autoPlay,
) => {
  sendComplete = false;
  noVideo = document.getElementById('noVideo');
  timelineRef = timeline;
  audioMuteUnmute = muteUnmuteAudio;
  const actualTime = Utils.getUnixDate(timestamp) * 1000;
  cID = uuidv4();
  playbackUUID = uuidv4();
  videoContainer = document.getElementById('remote-view-wrapper');
  videoElement = createVideoElement();
  videoElement.autoplay = autoPlay;
  videoContainer.appendChild(videoElement);
  Store.dispatch(setElementUpdated(true));

  const playObj = {
    cid: cID,
    tid: Date.now().toString(),
    to: deviceID,
    from: orgID,
    msg: {
      action: 'set',
      resource: 'media/handshake',
      properties: {
        uuid: playbackUUID,
        org_id: orgID,
        dev_id: deviceID,
        start_time: actualTime,
        duration: 60000,
        quality: 'SQ',
        type: 'PLAY',
      },
    },
  };
  const playMsg = JSON.stringify(playObj, null, 2);
  console.log('sendCloudPlayRequest ~ playMsg:', playMsg);
  if (websocket && websocket.readyState === 1) {
    setTimeout(() => {
      websocket.send(playMsg);
    }, 1500);
  }
  const elementCreated = 'elementCreated';
  return elementCreated;
};

export const sendCloudContinueRequest = () => {
  sendComplete = false;
  const contunueObj = {
    cid: cID,
    tid: Date.now().toString(),
    to: deviceID,
    from: orgID,
    msg: {
      action: 'set',
      resource: 'media/streaming',
      properties: {
        uuid: playbackUUID,
        org_id: orgID,
        dev_id: deviceID,
        duration: 60000,
        quality: 'SQ',
        type: 'CONTINUE',
      },
    },
  };
  const continueMsg = JSON.stringify(contunueObj, null, 2);

  if (websocket && websocket.readyState === 1) {
    websocket.send(continueMsg);
    console.log('Send Cloud Continue Request:', continueMsg);
  }
};

export const sendCloudStopRequest = () => {
  const stopObj = {
    cid: cID,
    tid: Date.now().toString(),
    to: deviceID,
    from: orgID,
    msg: {
      resource: 'media/streaming',
      properties: {
        uuid: playbackUUID,
        org_id: orgID,
        dev_id: deviceID,
        type: 'STOP',
      },
    },
  };
  const stopMsg = JSON.stringify(stopObj, null, 2);
  // console.log('sendCloudStopRequest ~ stopMsg:', stopMsg);
  if (websocket && websocket.readyState === 1) {
    websocket.send(stopMsg);
  }
};

export const sendCloudDisConnectRequest = () => {
  const disconnectObj = {
    cid: cID,
    tid: Date.now().toString(),
    to: deviceID,
    from: orgID,
    msg: {
      resource: 'media/streaming',
      properties: {
        uuid: playbackUUID,
        org_id: orgID,
        dev_id: deviceID,
        type: 'DISCONNECT',
      },
    },
  };

  const disconnectMsg = JSON.stringify(disconnectObj, null, 2);
  // console.log('sendCloudDisConnectRequest ~ DISCONNECT:', disconnectMsg);
  if (websocket && websocket.readyState === 1) {
    websocket.send(disconnectMsg);
  }
};

export const disconnectWithWebSocket = () => {
  if (websocket) {
    websocket.close();
  }
};

export const cleanupResources = () => {
  if (videoElement?.mediaSource?.readyState === 'open') {
    videoElement.mediaSource.endOfStream();
  }
  // videoElement?.removeEventListener('ended', addNewVideoElement);
  videoElement?.removeEventListener('progress', handleVideoEvents);
  videoElement?.removeEventListener('canplay', handleVideoEvents);
  videoElement?.removeEventListener('error', handleVideoEvents);
  videoElement?.remove();
  videoElement = null;
};

export const clearLocalTimerCloud = () => {
  if (virtualTimeInterval) {
    clearInterval(virtualTimeInterval);
  }
};
export const getCloudSendComplete = () => {
  return sendComplete;
};
