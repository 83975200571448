import { Utils } from '../../../helpers';
import { rateUnits, usageUnits } from '../../../helpers/commonUtils';

export const calculateMinRecordedBW = (
  totalSumProduct,
  reTransmit,
  overhead,
) => {
  const result = totalSumProduct * (1 + reTransmit) * (1 + overhead);
  return Utils.roundUpToTwoDecimals(result);
};

export const calculateExpectedLiveStreamBW = (
  liveStreamUsers,
  liveStream,
  networkQualityAverage,
  networkReliabilityAverage,
  networkCongestionAverage,
  reTransmit,
  overhead,
) => {
  const result =
    liveStreamUsers *
    liveStream *
    (1 + networkQualityAverage) *
    (1 + networkReliabilityAverage) *
    (1 + networkCongestionAverage) *
    (1 + reTransmit) *
    (1 + overhead);

  return Utils.roundUpToTwoDecimals(result);
};

export const calculateExpectedRecordedBW = (
  totalSumProduct,
  liveStreamUsers,
  liveStream,
  liveStreamHours,
  networkQualityAverage,
  networkReliabilityAverage,
  networkCongestionAverage,
  reTransmit,
  overhead,
) => {
  const result =
    (totalSumProduct + (liveStreamUsers * liveStream * liveStreamHours) / 24) *
    (1 + networkQualityAverage) *
    (1 + networkReliabilityAverage) *
    (1 + networkCongestionAverage) *
    (1 + reTransmit) *
    (1 + overhead);

  return Utils.roundUpToTwoDecimals(result);
};

export const calculateExpectedTotalBW = (
  expectedRecordedBW,
  expectedLiveStreamBW,
) => {
  const result = expectedRecordedBW + expectedLiveStreamBW;
  return result.toFixed(2);
};

export const calculateExpectedDailyBWUsage = (total, gbPer1Mbps) => {
  const result = total * gbPer1Mbps;
  return Utils.roundUpToTwoDecimals(result);
};

export const calculateExpectedWeeklyBWUsage = (value, daysPerWeek) => {
  const result = value * daysPerWeek;
  return Utils.roundUpToTwoDecimals(result);
};

export const calculateExpectedMonthlyBWUsage = (value, daysPerMonth) => {
  const result = value * daysPerMonth;
  return Utils.roundUpToTwoDecimals(result);
};

export const bandWidthConverter = (mb, type) => {
  let mbNumber = Number(mb);

  const units = type === 'rate' ? rateUnits : usageUnits;

  let index = 0;

  while (mbNumber >= 1024 && index < units?.length - 1) {
    mbNumber /= 1024;
    index++;
  }

  return `${mbNumber?.toFixed(2)} ${units[index]}`;
};
