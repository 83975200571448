import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useNotificationStore } from '../../../../store/CreateNotificationStore';
import { constants, Utils } from '../../../../helpers';
import { getCustomerOrgData } from '../../../../store/OrganizationsStoreIDB';
import useDebouncedCallback from '../../../../hooks/useDebouncedCallback';
import { observerInstance } from '../../../../store/indexDB/observer';
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField, useMediaQuery } from '@mui/material';
import { findTimeZoneFromSelectedValue } from '../../../../helpers/commonUtils';
import { PrimaryButton } from '../../../../components/common';
import { Alert } from 'react-bootstrap';
import { HiOutlineExclamationCircle } from 'react-icons/hi';

const BackupCalendar = ({
  calanderPurpose,
  handleDelete,
  selectedItem = {
    startTime: 0,
    endTime: 0,
    days: [],
    isEnabled: true,
  },
  onSelectItemChange,
  deviceTimeZone,
}) => {
  const cDate = moment.tz(moment(), moment.tz.guess()).format('MMM DD, YYYY');
  const [isTimeTouched, setIsTimeTouched] = useState(false);
  const getStepsData = useNotificationStore((state) => state.getStepsData);
  const [currentUserTimezone, setCurrentUserTimezone] = useState({});
  const [custOrgData, setCustOrgData] = useState();
  const [updateDayStaus, setUpdateDayStaus] = useState(false);
  const [isLessThan4Hours, setIsLessThan4Hours] = useState(false);
  const [isCreate, setIsCreate] = useState(
    selectedItem && Object.keys(selectedItem).length === 0,
  );
  const isScreenSmall = useMediaQuery('(max-height: 640px)');
  const [selectedIds, setSelectedIds] = useState(
    isCreate ? [] : selectedItem?.days,
  );
  const [daysList, setDaysList] = useState(
    Utils.getWeekDays(isCreate ? [] : selectedItem?.days, !isCreate),
  );

  const [startTime, setStartTime] = useState(
    new Date(
      cDate +
        ' ' +
        (!isCreate
          ? Utils.getHoursFromValue(selectedItem?.startTime)
          : '00:00:00'),
    ).getTime(),
  );
  const [endTime, setEndTime] = useState(
    new Date(
      cDate +
        ' ' +
        (!isCreate
          ? Utils.getHoursFromValue(selectedItem?.endTime)
          : '23:59:00'),
    ).getTime(),
  );
  const setStartEndTime = (epochValue, isTimeStart) => {
    let timeDifference = 0;

    if (isNaN(epochValue)) {
      return;
    }
    if (isTimeStart) {
      setStartTime(epochValue);
      timeDifference = (endTime - epochValue) / (1000 * 60 * 60);
    } else {
      setEndTime(epochValue);
      timeDifference = (epochValue - startTime) / (1000 * 60 * 60);
    }

    if (timeDifference < 0) {
      timeDifference = timeDifference + 24;
    }

    setIsLessThan4Hours(timeDifference < 4);
  };

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setCustOrgData(orgs?.[0] || {});
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000,
  );

  const selectedDay = (dayData) => {
    const dayListfirm = [...daysList];
    dayListfirm.map((data, i) => {
      if (dayData.id === data.id) {
        if (data.isSelected && selectedIds?.length !== 1) {
          daysList[i].isSelected = false;
        } else {
          daysList[i].isSelected = true;
        }
      }
    });
    setDaysList(dayListfirm);
    setUpdateDayStaus(true);
  };

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  useEffect(() => {
    if (updateDayStaus) {
      const dayIds = [];
      daysList &&
        daysList.forEach((days) => {
          if (days.isSelected) {
            dayIds.push(days.id);
          }
        });
      setSelectedIds(dayIds);
      setUpdateDayStaus(false);
    }
  }, [updateDayStaus]);

  const renderTimePicker = (timeValue, isStart, isError) => {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div>
          <span
            style={{
              fontSize: '12px',
              lineHeight: '16px',
              fontWeight: '500',
              color: '#444648',
              transform: 'translate(11px, 8px)',
              position: 'absolute',
            }}
          >
            {isStart ? 'Start' : 'End'}
          </span>
          <DesktopTimePicker
            //ampm={false}
            readOnly={false}
            closeOnSelect={false}
            value={moment(timeValue)}
            onChange={(newValue) => {
              setIsTimeTouched(true);
              let epochValue = newValue?.unix() * 1000; // milliseconds
              setStartEndTime(epochValue, isStart);
            }}
            timeSteps={{
              minutes: 1,
            }}
            sx={{
              paddingTop: '15px',
            }}
            slotProps={{
              popper: {
                sx: {
                  ...(isScreenSmall
                    ? {
                        '& .MuiPaper-root': {
                          position: 'fixed',
                          top: '-300px',
                          width: '480px',
                          height: '400.48px',
                        },
                      }
                    : {}),
                },
              },
            }}
            className={
              isError ? 'custom-error-style' : 'custom-non-error-style'
            }
            renderInput={(params) => (
              <TextField
                sx={{
                  svg: {
                    display: getComputedStyle(
                      document.documentElement,
                    ).getPropertyValue('--greyscale_40'),
                  },
                  input: {
                    color: getComputedStyle(
                      document.documentElement,
                    ).getPropertyValue('--brand_black'),
                    fontWeight: 500,
                    backgroundColor: 'red',
                  },
                }}
                {...params}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            )}
          />
        </div>
      </LocalizationProvider>
    );
  };

  const addDeleteScheduled = (isDelete) => {
    const daysSelected = selectedIds?.join(',');
    const scheduledItem = {
      startTime: Utils.getStartEndMin(
        moment(startTime).format('DD MMM YYYY HH:mm'),
        true,
      ).toString(),
      endTime: Utils.getStartEndMin(
        moment(endTime).format('DD MMM YYYY HH:mm'),
        true,
      ).toString(),
      days: daysSelected?.split(','),
      isEnabled: true,
    };
    onSelectItemChange(scheduledItem, isCreate);
  };

  useEffect(() => {
    const location = custOrgData?.locations?.find(
      (item) => item?.locationId === getStepsData()?.locationId,
    );

    if (location) {
      const userTimeZone = findTimeZoneFromSelectedValue(location?.timezone);
      setCurrentUserTimezone(userTimeZone);
    }
  }, [custOrgData?.orgId]);

  const showDays = (items) => {
    const daysMap = {
      1: constants.SUN,
      2: constants.MON,
      3: constants.TUE,
      4: constants.WED,
      5: constants.THU,
      6: constants.FRI,
      7: constants.SAT,
    };

    const selectedDays = items?.filter((item) => item?.isSelected);

    return selectedDays?.map((item, index) => {
      const dayName = daysMap[item?.id];
      if (selectedDays?.length === 1) {
        return (
          <span key={item?.id} className="day-text">
            {dayName}
          </span>
        );
      } else {
        const comma = index < selectedDays?.length - 1 ? ', ' : ''; // Add comma if not the last selected day
        return (
          <span key={item?.id} className="day-text">
            {dayName}
            {comma}
          </span>
        );
      }
    });
  };

  return (
    <div className="create-edit-container">
      <div className="choose-day-conatiner">
        {(calanderPurpose === constants.CLOUD_BACKUP_EDIT_UPLOAD_SCHEDULE ||
          calanderPurpose ===
            constants.CLOUD_BACKUP_CREATE_AN_UPLOAD_SCHEDULE) && (
          <div className="cloud-storage-tip mt-2 mb-2">
            {constants.CLOUD_BACKUP_CREATE_UPLOAD_SCHEDULE_ADDITIONAL_TEXT}
          </div>
        )}
        <div className="title-day">
          {constants.NOTIFICATION_CREATE_CHOOSE_DAY}
        </div>

        <div className="day-list-container">
          {daysList?.map((days) => {
            return (
              <div
                onClick={() => selectedDay(days)}
                key={days.id}
                className={`day-blocks ${days.isSelected ? 'selected' : ''}`}
              >
                <div className="label-value">{days.dayPrefix}</div>
              </div>
            );
          })}
        </div>
        <div className="m-t-8 days-style">{showDays(daysList)}</div>
        <div className="label-title">
          {constants.NOTIFICATION_CREATE_CHOOSE_TIME}
        </div>
        <div className="timezone">
          {constants.CLOUD_BACKUP_LOCAL_TIME_ZONE}&nbsp;
          {deviceTimeZone ? deviceTimeZone : '--'}
        </div>
        {isTimeTouched &&
          (startTime >= endTime ||
            (isLessThan4Hours &&
              (constants.CLOUD_BACKUP_CREATE_AN_UPLOAD_SCHEDULE ===
                calanderPurpose ||
                constants.CLOUD_BACKUP_EDIT_UPLOAD_SCHEDULE ===
                  calanderPurpose))) && (
            <Alert variant="danger" className="warning-alert">
              <HiOutlineExclamationCircle
                size={20}
                stroke={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--error_24')}
              />
              {isLessThan4Hours &&
              (constants.CLOUD_BACKUP_CREATE_AN_UPLOAD_SCHEDULE ===
                calanderPurpose ||
                constants.CLOUD_BACKUP_EDIT_UPLOAD_SCHEDULE === calanderPurpose)
                ? startTime === endTime
                  ? constants.CLOUD_BACKUP_CALENDAR_TIME_SELECTION_ERROR
                  : constants.CLOUD_BACKUP_CREATE_UPLOAD_SCHEDULE_ADDITIONAL_TEXT
                : constants.CLOUD_BACKUP_CALENDAR_TIME_SELECTION_ERROR}
            </Alert>
          )}
        <div className="time-slot-container">
          <div className="time-container_x">
            <div className="timer-start-end">
              {renderTimePicker(startTime, true)}
            </div>
            <div className="seprator">-</div>
            <div className="timer-start-end">
              {renderTimePicker(
                endTime,
                false,
                ((isLessThan4Hours &&
                  (constants.CLOUD_BACKUP_CREATE_AN_UPLOAD_SCHEDULE ===
                    calanderPurpose ||
                    constants.CLOUD_BACKUP_EDIT_UPLOAD_SCHEDULE ===
                      calanderPurpose)) ||
                  startTime === endTime) &&
                  isTimeTouched,
              )}
            </div>
          </div>
        </div>
        <div className="backup-calendar-button-container">
          <PrimaryButton
            className="adddevice-btn"
            fontSize="0.875rem"
            backgroundColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--primary_40')}
            height="44px"
            color="#FFFFFF"
            type="submit"
            onClick={() => addDeleteScheduled(false)}
            disabled={
              !selectedIds?.length ||
              startTime >= endTime ||
              Boolean(
                (calanderPurpose ===
                  constants.CLOUD_BACKUP_CREATE_AN_UPLOAD_SCHEDULE ||
                  calanderPurpose ===
                    constants.CLOUD_BACKUP_EDIT_UPLOAD_SCHEDULE) &&
                  isLessThan4Hours,
              )
            }
          >
            {constants.BACKUP_CALENDER_SAVE_BUTTON}
          </PrimaryButton>
          {(calanderPurpose ===
            constants.CLOUD_BACKUP_EDIT_CONTENT_BACKUP_WINDOW ||
            calanderPurpose ===
              constants.CLOUD_BACKUP_EDIT_UPLOAD_SCHEDULE) && (
            <PrimaryButton
              className="btn btn-primary-outline"
              type="button"
              width="100%"
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              hoverBorderColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--primary_40')}
              color={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--primary_40')}
              hoverColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--brand_white')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--primary_40')}
              backgroundColor="transparent"
              onClick={() => {
                handleDelete(selectedItem);
              }}
            >
              {constants.BACKUP_CALENDER_DELETE_BUTTON}
            </PrimaryButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default BackupCalendar;
