import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  viewList: [],
  selectedView: null,
  isOnDeviceWallPage: false,
  noPlaybackContentAvailable: [],
  isEditModeActive: false,
  isLeftMenuOpen: true,
};

export const views = createSlice({
  name: 'views',
  initialState,
  reducers: {
    setViewList: (state, action) => {
      state.viewList = action.payload;
    },
    setSelectedView: (state, action) => {
      state.selectedView = action.payload;
    },
    setIsOnDeviceWallPage: (state, action) => {
      state.isOnDeviceWallPage = action.payload;
    },
    setNoPlaybackContentAvailable: (state, action) => {
      state.noPlaybackContentAvailable = action.payload;
    },
    setIsEditModeActive: (state, action) => {
      state.isEditModeActive = action.payload;
    },
    setIsLeftMenuOpen: (state, action) => {
      state.isLeftMenuOpen = action.payload;
    },
    resetNoContentData: (state) => {
      state.noPlaybackContentAvailable = [];
      state.noLiveVideoContentAvailable = [];
      state.noSnapshotContentAvailable = [];
    },
    resetViewsData: (state) => {
      state.selectedView = null;
      state.isOnDeviceWallPage = false;
    },
    resetViewsList: (state) => {
      state.viewList = [];
      state.selectedView = null;
      state.isOnDeviceWallPage = false;
    },
  },
});

export const {
  setViewList,
  setSelectedView,
  setIsOnDeviceWallPage,
  setNoPlaybackContentAvailable,
  setIsEditModeActive,
  setIsLeftMenuOpen,
  resetNoContentData,
  resetViewsData,
  resetViewsList,
} = views.actions;
export const getViewList = (state) => state.views.viewList;
export const getSelectedView = (state) => {
  return state.views.selectedView;
};
export const getIsOnDeviceWallPage = (state) => state.views.isOnDeviceWallPage;
export const getNoPlaybackContentAvailable = (state) =>
  state.views.noPlaybackContentAvailable;
export const getIsEditModeActive = (state) => state.views.isEditModeActive;
export const getIsLeftMenuOpen = (state) => state.views.isLeftMenuOpen;
export default views.reducer;
