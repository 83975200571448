import React from 'react';

import DetailsContextProvider from '../addDevice/DetailsContextProvider';
import DataContextProvider from '../addDevice/DataContextProvider';
import DeviceList from './DeviceList';
import '../device.scss';
import DeviceListCloud from './DeviceListCloud';

const Device = () => {
  return (
    <DetailsContextProvider>
      <DataContextProvider>
        <DeviceListCloud />
      </DataContextProvider>
    </DetailsContextProvider>
  );
};

export default Device;
