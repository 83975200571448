import React, { useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useSelector } from 'react-redux';

import { constants, LocalStorageKeysEnum, Utils } from '../../../../helpers';
import {
  PrimaryButton,
  SiteModal,
  SiteSpinner,
} from '../../../../components/common';

import EditCamera from '../EditCamera';
import LiveGridItem from '../LiveGridItem';
import ImageGridItem from '../ImageGridItem';
import PlaybackGridItem from '../PlaybackGridItem';
import {
  getIsLeftMenuOpen,
  getViewList,
} from '../../../../store/reducers/ViewsReducer';

import 'react-grid-layout/css/styles.css';

const RGL = WidthProvider(Responsive);

const COLS = { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 };
const BREAKPOINTS = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
const RESOLUTION_TO_UNITS = {
  '2560x1920': {
    y: 1,
    x: 2,
  },
  '1920x1080': {
    y: 1,
    x: 2,
  },
  '1080x1920': {
    y: 2,
    x: 1,
  },
  '1080x1080': {
    y: 1,
    x: 1,
  },
};

const ResponsiveReactGridLayout = ({
  devicesData,
  entitleData,
  metadataByDeviceId,
  clearLayout,
  currentViewDeviceList,
  selectedViewDevices,
  setClearLayout,
  resetLayout,
  setCustomViewLayout,
  setResetLayout,
  setEditLayout,
  selectedView,
  isViewUpdateSaved,
  viewUpdateSavedCallback,
  viewId,
  activeTime,
  timeZone,
  gridItemComponent = 'LiveGridItem',
  // ImageGridItem props
  moveTimeline,
  liveSnapshot,
  time,
  cdnValue,
  snapshotImages,
  snapshotURL,
  selectedEventTime,
  // PlaybackGridItem props
  orgDetails,
  noContentDeviceIds,
  hasVideoContent,
}) => {
  const gridRef = useRef();
  const cameraTileContainerRef = useRef();
  const viewList = useSelector(getViewList);
  const isLeftMenuOpen = useSelector(getIsLeftMenuOpen);
  const numOfDevices = currentViewDeviceList?.length || 0;
  const [layouts, setLayouts] = useState({});
  const [mounted, setMounted] = useState(false);
  const [draggingItemId, setDraggingItemId] = useState(null);
  const [hoveredDeviceId, setHoveredDeviceId] = useState(null);
  const [isChangedSize, setIsChangedSize] = useState(false);
  const [currentViewId, setCurrentViewId] = useState(null);
  const [liveMetaData, setLiveMetaData] = useState([]);
  const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
  const [currentRowHeight, setCurrentRowHeight] = useState(40);
  const [currentGridHeight, setCurrentGridHeight] = useState('auto');
  const [currentGridWidth, setCurrentGridWidth] = useState('auto');
  const [loading, setLoading] = useState(true);
  const [toolbox, setToolbox] = useState({
    lg: [],
  });
  const [pendingLayouts, setPendingLayouts] = useState(
    Utils.getLocalStorageData(LocalStorageKeysEnum.cameraViewLayout) || null,
  );
  const [currentLayout, setCurrentLayout] = useState(null);
  // const [needLayoutUpdate, setNeedLayoutUpdate] = useState(false);
  // const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  // const [showSaveLayoutPrompt, setShowSaveLayoutPrompt] = useState(false);
  // const [showButtonLoader, setShowButtonLoader] = useState(false);

  // const itemCount = currentViewDeviceList?.length || 0;

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      setIsChangedSize(!isChangedSize);
      if (devicesData && devicesData.length) {
        setLoading(false);
      }
    }, 200);

    const handleResize = () => {
      setCurrentGridHeight(cameraTileContainerRef?.current?.clientHeight);
      setCurrentGridWidth(cameraTileContainerRef?.current?.clientWidth);
      setCurrentRowHeight(cameraTileContainerRef?.current?.clientHeight / 9);
    };

    setCurrentRowHeight(cameraTileContainerRef?.current?.clientHeight / 12);

    window.addEventListener('resize', handleResize);

    return () => {
      setCurrentLayout(null);
      clearTimeout(timeout);
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clearLayout === true) {
      setEditLayout(_.isObject(pendingLayouts) && pendingLayouts[viewId]);
      setCurrentLayout(
        _.isObject(pendingLayouts)
          ? pendingLayouts[viewId]
          : Array.isArray(selectedView?.customLayout) &&
              selectedView.customLayout.length > 0
            ? selectedView.customLayout
            : null,
      );
      setCurrentViewId(viewId);
      setClearLayout(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearLayout]);

  useEffect(() => {
    if (isViewUpdateSaved === true) {
      if (_.isObject(pendingLayouts) && _.isObject(pendingLayouts[viewId])) {
        delete pendingLayouts[viewId];
        Utils.setLocalStorageData(
          LocalStorageKeysEnum.cameraViewLayout,
          pendingLayouts,
        );
      } else {
        Utils.removeLocalStorageData(LocalStorageKeysEnum.cameraViewLayout);
      }

      // setHasUnsavedChanges(false);

      if (
        viewUpdateSavedCallback &&
        typeof viewUpdateSavedCallback === 'function'
      ) {
        viewUpdateSavedCallback(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewUpdateSaved]);

  useEffect(() => {
    if (resetLayout === true) {
      setCurrentLayout(
        selectedView?.customLayout?.length > 0
          ? structuredClone(selectedView?.customLayout)
          : [],
      );
      setCustomViewLayout(null);
      setResetLayout(false);
      if (_.isObject(pendingLayouts) && _.isObject(pendingLayouts[viewId])) {
        delete pendingLayouts[viewId];
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetLayout]);

  useEffect(() => {
    let currentCustomLayout = [];

    if (!viewId) {
      return;
    }

    setCurrentViewId(viewId);
    const layoutsFromLS = Utils.getLocalStorageData(
      LocalStorageKeysEnum.cameraViewLayout,
    );

    if (_.isObject(layoutsFromLS) && _.isObject(layoutsFromLS[viewId])) {
      currentCustomLayout = structuredClone(layoutsFromLS[viewId]);
      Utils.vmsLogger().log('useEffect  viewId: ', viewId);
      setEditLayout(true);
    } else if (
      Array.isArray(selectedView?.customLayout) &&
      selectedView.customLayout.length > 0
    ) {
      currentCustomLayout = structuredClone(selectedView.customLayout);
    }

    setPendingLayouts(structuredClone(layoutsFromLS));

    setCurrentLayout(structuredClone(currentCustomLayout));
    // setCustomViewLayout(currentCustomLayout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewId]);

  useEffect(() => {
    setLoading(!currentViewDeviceList?.length);
  }, [currentViewDeviceList?.length]);

  useEffect(() => {
    if (metadataByDeviceId) {
      setLiveMetaData(metadataByDeviceId);
    }
  }, [metadataByDeviceId]);

  useEffect(() => {
    const mainContainerElem = document.getElementsByClassName(
      'main-content-container',
    )?.[0];
    const containerElem = document.getElementsByClassName(
      'camera-tile-container',
    )?.[0];
    if (mainContainerElem && containerElem) {
      const marginTop =
        mainContainerElem.clientHeight - containerElem.clientHeight - 20;
      // containerElem.style.marginTop = marginTop / 2 + 'px';
    }
  }, [isChangedSize]);

  const handleLayoutChange = (layout, oldItem, newItem) => {
    const currentPendingLayouts = _.isObject(pendingLayouts)
      ? pendingLayouts
      : null;

    const currentSelectedDevices = selectedViewDevices || [];
    let viewLayouts, updatedSelectedDevices;

    if (_.isObject(currentPendingLayouts)) {
      if (_.isObject(currentPendingLayouts[viewId])) {
        currentPendingLayouts[viewId] = layout;

        viewLayouts = structuredClone(currentPendingLayouts);
      } else {
        viewLayouts = {
          ...pendingLayouts,
          [viewId]: layout,
        };
      }
    } else {
      viewLayouts = {
        [viewId]: layout,
      };
    }

    setCustomViewLayout(layout);
    setCurrentLayout(layout);
    // setHasUnsavedChanges(true);

    if (
      !Utils.setLocalStorageData(
        LocalStorageKeysEnum.cameraViewLayout,
        viewLayouts,
      )
    ) {
      Utils.vmsLogger().error(
        '*** ERROR: could not save new layout for view: ',
        viewId,
      );
    }

    setEditLayout(true);
  };

  const onBreakpointChange = (breakpoint) => {
    Utils.vmsLogger().log('breakpoint: ', breakpoint);

    setCurrentBreakpoint(breakpoint);
    setToolbox({
      ...toolbox,
      [breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || [],
    });
  };

  const onLayoutChange = (layout, layouts) => {
    let gridItems = document.getElementsByClassName('device-stack-item');
    Utils.vmsLogger().log('gridItems: ', gridItems);
    Utils.vmsLogger().log('layout: ', layout);
    Utils.vmsLogger().log('layouts: ', layouts);
  };

  const getDeviceInfo = (deviceId) => {
    if (devicesData?.length > 0) {
      const deviceData = devicesData.find(
        (device) => device.deviceId === deviceId,
      );
      return deviceData;
    }
    return null;
  };

  const getHubInfo = (deviceId) => {
    if (devicesData?.length > 0) {
      const deviceData = devicesData.filter(
        (device) => device.deviceId === deviceId,
      );
      return `${deviceData[0]?.gatewayId}`;
    }
  };

  const calculateDimensions = (rows, columns) => {
    const container = document.getElementsByClassName(
      'main-content-container',
    )?.[0];
    const containerWidth = container?.clientWidth;
    const containerHeight = container?.clientHeight;
    if (!containerWidth || !containerHeight) return;

    const aspectRatio = 16 / 9;
    const rowWidth = isLeftMenuOpen
      ? (containerWidth - 5) / columns
      : containerWidth / columns;
    const columnHeight = containerHeight / rows;

    let childWidth = rowWidth;
    let childHeight = childWidth / aspectRatio;

    if (childHeight > columnHeight) {
      childHeight = columnHeight;
      childWidth = childHeight * aspectRatio;
    }

    return { rowWidth: childWidth * columns, columnHeight: childHeight * rows };
  };

  const handleGridItemResize = useCallback(
    (l, oldLayoutItem, layoutItem, placeholder) => {
      const heightDiff = layoutItem?.h - oldLayoutItem?.h;
      const widthDiff = layoutItem?.w - oldLayoutItem?.w;
      const changeCoef = oldLayoutItem?.w / oldLayoutItem?.h;

      if (!heightDiff || !widthDiff) return;

      if (Math.abs(heightDiff) < Math.abs(widthDiff)) {
        layoutItem.h = layoutItem?.w / changeCoef;
        placeholder.h = layoutItem?.w / changeCoef;
      } else {
        layoutItem.w = layoutItem?.h * changeCoef;
        placeholder.w = layoutItem?.h * changeCoef;
      }
    },
    [],
  );

  const generateDOM = (GridItem) => {
    let DOMContent;
    let shouldLayoutBeUpdated = false;
    const viewLayouts =
      currentLayout?.length > 0
        ? [...currentLayout]
        : selectedView?.customLayout?.length > 0
          ? [...selectedView?.customLayout]
          : null;

    const isNoVideoContent = (deviceId) => {
      const findIndex = noContentDeviceIds?.findIndex(
        (device) => device === deviceId,
      );
      return findIndex > -1;
    };

    Utils.vmsLogger().log('*** generateDOM viewId: ', viewId);

    if (Array.isArray(pendingLayouts) && _.isObject(pendingLayouts[viewId])) {
      Utils.vmsLogger().log(
        '*** generateDOM pendingLayouts[viewId]: ',
        pendingLayouts[viewId],
      );
    } else {
      Utils.vmsLogger().log('*** generateDOM pendingLayouts[viewId]: ', null);
    }
    Utils.vmsLogger().log(
      '*** generateDOM selectedView?.customLayout: ',
      selectedView?.customLayout,
    );

    Utils.vmsLogger().log('*** generateDOM currentLayout: ', currentLayout);

    const getGridItem = (
      i,
      deviceId,
      deviceInfo,
      hubInfo,
      activeTime,
      timeZone,
      uniqueId,
      entitleData,
      metadataByDeviceId,
    ) => {
      const hasVideoContent = isNoVideoContent(deviceId);
      switch (gridItemComponent?.toUpperCase()) {
        case 'PLAYBACKGRIDITEM':
          return (
            <PlaybackGridItem
              key={deviceId}
              layout={deviceId}
              device={deviceInfo}
              deviceId={deviceId}
              activeTime={activeTime}
              timeZone={timeZone}
              orgDetails={orgDetails}
              isNoVideoContent={hasVideoContent}
              cdnValue={cdnValue}
              selectedEventTime={selectedEventTime}
            />
          );

        case 'IMAGEGRIDITEM':
          return (
            <ImageGridItem
              key={deviceId}
              layout={deviceId}
              device={getDeviceInfo(deviceId)}
              deviceId={deviceId}
              moveTimeline={moveTimeline}
              liveSnapshot={liveSnapshot}
              time={time}
              cdnValue={cdnValue}
              camera={i + 1}
              timeZone={timeZone}
              snapshotImages={snapshotImages}
              imageURL={snapshotURL?.[deviceId]}
              selectedEventTime={selectedEventTime}
            />
          );

        default:
          return (
            <LiveGridItem
              key={deviceId}
              layout={deviceId}
              device={deviceInfo}
              deviceId={deviceId}
              hubId={hubInfo}
              activeTime={activeTime}
              timeZone={timeZone}
              uniqueId={uniqueId}
              entitleData={entitleData}
              metadataByDeviceId={metadataByDeviceId}
            />
          );
      }
    };

    if (!Array.isArray(viewLayouts) || viewLayouts.length < 1) {
      Utils.vmsLogger().log(
        '*** generateDOM has NO viewLayouts: ',
        viewLayouts,
      );
      Utils.vmsLogger().log('*** selectedView: ', selectedView);

      DOMContent = currentViewDeviceList.map((deviceId, i) => {
        const deviceInfo = getDeviceInfo(deviceId);
        const hubInfo = getHubInfo(deviceId);
        const availableHandles = ['sw', 'nw', 'se', 'ne'];

        let minH = 1,
          minW = 1,
          isPortrait = false,
          isSquare = false,
          resolution,
          dimensions;

        resolution = deviceInfo.properties['mp-resolution'];

        if (resolution) {
          if (
            deviceInfo.properties['rotation-angle'] === '90' ||
            deviceInfo.properties['rotation-angle'] === '270'
          ) {
            dimensions = resolution?.split('x');
            if (dimensions) {
              minH = RESOLUTION_TO_UNITS[`${dimensions[1]}x${dimensions[0]}`].y;
              minW = RESOLUTION_TO_UNITS[`${dimensions[1]}x${dimensions[0]}`].x;
            } else {
              minH =
                RESOLUTION_TO_UNITS[deviceInfo.properties['mp-resolution']].y;
              minW =
                RESOLUTION_TO_UNITS[deviceInfo.properties['mp-resolution']].x;
            }
          } else {
            minH =
              RESOLUTION_TO_UNITS[deviceInfo.properties['mp-resolution']].y;
            minW =
              RESOLUTION_TO_UNITS[deviceInfo.properties['mp-resolution']].x;
          }

          if (minH === minW) {
            isSquare = true;
          } else if (minH > minW) {
            isPortrait = true;
          }
        }

        return (
          <div
            key={deviceId}
            className={`item-grid device-stack-item ${
              draggingItemId !== deviceId ? '' : 'dragged-item'
            } 
              ${hoveredDeviceId === deviceId ? 'add-hover' : ''}`}
            onMouseEnter={() => setHoveredDeviceId(deviceId)}
            onMouseLeave={() => setHoveredDeviceId(null)}
            data-grid={{
              x:
                numOfDevices === 1
                  ? 0
                  : isSquare
                    ? (i * Math.floor(9 / numOfDevices)) % 9
                    : (i * Math.floor(12 / numOfDevices)) % 12,
              y: 0,
              w: isSquare
                ? Math.floor(9 / numOfDevices)
                : Math.floor(12 / numOfDevices),
              h: isPortrait
                ? Math.floor(9 / numOfDevices) * minH
                : Math.floor(9 / numOfDevices),
              resizeHandles: availableHandles,
              minH: minH,
              minW: minW,
              isBounded: true,
            }}
            onResize={handleGridItemResize}
          >
            {/* const getGridItem = (deviceId, deviceInfo, hubInfo, activeTime, timeZone,uniqueId, entitleData, metadataByDeviceId) => { */}

            {deviceInfo ? (
              getGridItem(
                deviceId,
                deviceInfo,
                hubInfo,
                activeTime,
                timeZone,
                deviceId + viewId,
                entitleData,
                liveMetaData,
              )
            ) : (
              <EditCamera />
            )}
          </div>
        );
      });
    } else {
      setCustomViewLayout([...viewLayouts]);

      Utils.vmsLogger().log('*** generateDOM has viewLayouts: ', viewLayouts);
      Utils.vmsLogger().log('*** selectedView: ', selectedView);

      DOMContent = currentViewDeviceList.map((deviceId, i) => {
        const deviceInfo = getDeviceInfo(deviceId);
        const hubInfo = getHubInfo(deviceId);
        const itemLayout = viewLayouts.find((layout) => layout.i === deviceId);

        if (!_.isObject(itemLayout)) {
          shouldLayoutBeUpdated = true;
        }

        Utils.vmsLogger().log('*** generateDOM itemLayout: ', itemLayout);
        Utils.vmsLogger().log('*** generateDOM deviceId: ', deviceId);

        return (
          <div
            key={deviceId}
            className={`item-grid device-stack-item ${
              draggingItemId !== deviceId ? '' : 'dragged-item'
            } 
              ${hoveredDeviceId === deviceId ? 'add-hover' : ''}`}
            onMouseEnter={() => setHoveredDeviceId(deviceId)}
            onMouseLeave={() => setHoveredDeviceId(null)}
            data-grid={{
              x: itemLayout?.x ?? (numOfDevices === 1 ? 0 : (i * 3) % 12),
              y: itemLayout?.y ?? 0,
              w: itemLayout?.w ?? (numOfDevices === 1 ? 5 : 3),
              h: itemLayout?.h ?? (numOfDevices === 1 ? 5 : 3),
              resizeHandles: itemLayout?.resizeHandles,
              minH: itemLayout?.minH ?? 2,
              minW: itemLayout?.minW ?? 2,
              isBounded: true,
            }}
            onResize={handleGridItemResize}
          >
            {deviceInfo ? (
              getGridItem(
                i,
                deviceId,
                deviceInfo,
                hubInfo,
                activeTime,
                timeZone,
                deviceId + viewId,
                entitleData,
                liveMetaData,
              )
            ) : (
              <EditCamera />
            )}
          </div>
        );
      });
    }

    if (shouldLayoutBeUpdated === true) {
      let gridItems = document.getElementsByClassName('device-stack-item');
      Utils.vmsLogger().log('*** needLayoutUpdate gridItems: ', gridItems);
      // TODO: delete this later
      // Utils.vmsLogger().log(
      //   '*** needLayoutUpdate gridItems[0].dataset.grid.value: ',
      //   gridItems[0].dataset?.grid?.value
      // );
    }

    return DOMContent;
  };

  return (
    <div ref={cameraTileContainerRef} className={`camera-tile-container`}>
      {loading ? (
        <div className="loading-screen" style={{ position: 'absolute' }}>
          <SiteSpinner />
          <div className="simple-label">
            {constants.CAMERAS_VIDEO_CAMERA_LOADING_LABEL}
          </div>
        </div>
      ) : (
        <RGL
          ref={gridRef}
          className="devices-stack mb-4"
          layouts={layouts}
          useCSSTransforms={mounted}
          preventCollision={true}
          measureBeforeMount={false}
          compactType={null}
          onDragStop={handleLayoutChange}
          onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts)}
          onBreakpointChange={onBreakpointChange}
          // onResize={handleLayoutChange}
          onResizeStop={handleLayoutChange}
          breakpoints={BREAKPOINTS}
          cols={COLS}
          rowHeight={currentRowHeight}
          width={currentGridWidth}
          height={currentGridHeight}
          maxRows={9}
          autoSize={false}
          margin={[10, 10]}
          // rowHeight={64}
          // width={1200}
          // height={771}
          // maxRows={12}
          // isDraggable
        >
          {generateDOM(gridItemComponent)}
        </RGL>
      )}
    </div>
  );
};

export default ResponsiveReactGridLayout;
