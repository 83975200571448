import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { HiOutlineVideoCamera } from 'react-icons/hi2';
import { FiSettings } from 'react-icons/fi';
import OfflineCamera from './OfflineCamera';
import moment from 'moment';
import { WiTime4 } from 'react-icons/wi';
import {
  getCDNInfo,
  getWSSConnections,
  removeRemoteStreams,
  removeRemoteStreamsAudio,
  setIsRemoteStreamPlay,
} from '../../../store/reducers/StreamingReducer';
import { Utils, constants } from '../../../helpers';
import useEventsStore from '../../../store/EventsStore';
import nosnapshot from '../../../assets/images/nosnapshot.svg';
import NoSnapshot from './NoSnapshot';
import { ReactComponent as ThreeDotIcon } from '../../../assets/images/VerticalThreeDots.svg';
import { setDeviceInformation } from '../../../store/reducers/AccountReducer';
import { getSnapshotImage } from '../../../store/StreamingStoreIDB';
import { observerInstance } from '../../../store/indexDB/observer';
import { disconnectWithWebSocket } from '../../multilive/components/playback/wssConnection/wssConnection';
import { usePoliciesStore } from '../../../store/policiesStore';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';

const ImageGridItem = ({
  time,
  imageURL = null,
  cdnValue,
  deviceId,
  device,
  liveSnapshot,
  camera,
  timeZone,
  snapshotImages,
  selectedEventTime,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const imageRef = useRef();
  const [newSrc, setNewSrc] = useState('');
  const [selectedSnapSrc, setSelectedSnapSrc] = useState('');
  const [snapshot, setSnapshot] = useState(null);
  const [snapshotImage, setSnapshotImage] = useState({});

  const { setSelectedEventStore, setPauseVideo } = useEventsStore();
  const cdnInfo = useSelector(getCDNInfo);
  const wssConnections = useSelector(getWSSConnections);

  const loadSnapshots = useCallback(async () => {
    const snapshot = await getSnapshotImage();
    setSnapshotImage(snapshot);
  }, []);
  const { getLoggedInUserPolicies } = usePoliciesStore();
  const userPolicies = getLoggedInUserPolicies();
  const [currentDevice, setCurrentDevice] = useState(device);

  const debouncedLoadSnapshot = useDebouncedCallback(loadSnapshots, 100);

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'snapshotImage') {
        await debouncedLoadSnapshot();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadSnapshot();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadSnapshot]);

  useEffect(() => {
    if (
      device &&
      device?.displayDeviceStatus?.toLowerCase() !==
        currentDevice?.displayDeviceStatus?.toLowerCase()
    ) {
      setCurrentDevice(device);
    }
  }, [JSON.stringify(device)]);

  useEffect(() => {
    const newTime = Utils.getUnixDate(Utils.getDate(time / 1000));

    if (
      deviceId &&
      newTime &&
      !imageURL &&
      cdnValue?.protocol &&
      cdnValue?.host
    ) {
      const bucket = (cdnValue?.bucket).replace('${deviceId}', deviceId);
      const date = Utils.fetchDateInUnix(newTime);

      fetch(
        `${cdnValue?.protocol}://${cdnValue?.host}/${bucket}/${date}/${newTime}.jpg`,
        {
          credentials: 'include',
        },
      )
        .then((response) => response.blob())
        .then((blob) => {
          if (selectedEventTime && selectedEventTime / 1000 === newTime) {
            setSelectedSnapSrc(URL.createObjectURL(blob));
          } else {
            setNewSrc(URL.createObjectURL(blob));
          }
        })
        .catch(() => {
          Utils.vmsLogger().log('Error got', deviceId);
        });
    } else if (imageURL) {
      setNewSrc(imageURL);
    }
  }, [time, deviceId, selectedEventTime]);

  useEffect(() => {
    if (camera) {
      setSnapshot(snapshotImages?.[deviceId]);
    } else {
      setSnapshot(snapshotImage);
    }
  }, [snapshotImage, deviceId, camera, snapshotImages]);

  const getCapabiltiesForDevice = async (deviceData) => {
    if (deviceData?.capability) {
      try {
        const response = await fetch(deviceData?.capability?.url);
        if (response.status === 200) {
          const responseJson = await response.json();
          setCurrentDevice({ ...deviceData, capDetails: responseJson });
        } else {
          setCurrentDevice(deviceData);
        }
      } catch (error) {
        setCurrentDevice(deviceData);
      }
    } else {
      setCurrentDevice(deviceData);
    }
  };

  useEffect(() => {
    getCapabiltiesForDevice(device);
  }, []);

  const OnClickCameraDetails = async () => {
    if (wssConnections) {
      Object.keys(wssConnections).forEach((key) => {
        dispatch(removeRemoteStreams(key));
        dispatch(removeRemoteStreamsAudio(key));
        disconnectWithWebSocket(key);
      });
    }
    dispatch(setDeviceInformation(currentDevice));
    await setSelectedEventStore(Utils.getUnixDate(time) * 1000);
    await setPauseVideo(true);
    navigate(`/cameras/dashboard.html`, {
      state: {
        id: deviceId,
        cdnInfo: cdnInfo ? cdnInfo : {},
        origin: 'multiview-contextual-menu',
      },
    });
  };

  const handleDoubleClick = () => {
    OnClickCameraDetails();
  };

  const OnClickCameraSettings = () => {
    dispatch(setDeviceInformation(currentDevice));
    deviceId && navigate(`/devices/dashboard.html?deviceId=${deviceId}`);
  };

  return (
    <>
      {(currentDevice?.displayDeviceStatus ===
        constants.DEVICES_RETURN_OFFLINE_STATUS ||
        currentDevice?.displayDeviceStatus ===
          constants.DEVICES_RETURN_DEACTIVATED_STATUS) &&
      liveSnapshot &&
      !snapshot ? (
        <OfflineCamera
          deviceId={deviceId}
          device={currentDevice}
          activeTime={time}
          timeZone={timeZone}
          onRefresh={() => {}}
        />
      ) : (
        <>
          {liveSnapshot ? (
            snapshot ? (
              <>
                <div
                  className={`device-overlay hovered`}
                  onDoubleClick={() => handleDoubleClick()}
                >
                  <div className="device-title-container">
                    <div className="device-name">
                      {currentDevice?.deviceName}
                    </div>
                    <div className="device-location">
                      {currentDevice?.locationName} • {currentDevice?.areaName}
                    </div>
                  </div>
                  <div className="date-time-wrapper">
                    <div className="date-time">
                      <WiTime4 size={14} />
                      {moment.tz(moment(time), timeZone).format('hh:mm:ss A z')}
                    </div>
                  </div>
                  <div className="menu-icon">
                    <NavDropdown
                      className="devices-dropdown"
                      title={<ThreeDotIcon />}
                    >
                      <NavDropdown.Item
                        className="devices-dropdown-options"
                        onClick={() => OnClickCameraDetails()}
                      >
                        <HiOutlineVideoCamera size={16} />
                        <span className="devices-dropdown-options-label">
                          {constants.CAMERAS_VIDEO_CAMERA_DETAILS_LABEL}
                        </span>
                      </NavDropdown.Item>
                      {userPolicies.view_device_settings && (
                        <NavDropdown.Item
                          className="devices-dropdown-options"
                          onClick={() => OnClickCameraSettings()}
                        >
                          <FiSettings size={14} />
                          <span className="devices-dropdown-options-label">
                            {constants.CAMERAS_VIDEO_SETTINGS_LABEL}
                          </span>
                        </NavDropdown.Item>
                      )}
                    </NavDropdown>
                  </div>
                </div>
                <img
                  ref={imageRef}
                  id={`imgSnapshot_${deviceId}`}
                  src={snapshot}
                  alt=""
                  className="live-snapshot main-snapshot"
                  onLoad={() => {
                    dispatch(setIsRemoteStreamPlay(true));
                  }}
                  onError={() => {
                    setSnapshot(null);
                  }}
                />
              </>
            ) : (
              <NoSnapshot
                deviceId={deviceId}
                device={currentDevice}
                activeTime={time}
                timeZone={timeZone}
              />
            )
          ) : (
            <>
              {selectedSnapSrc || newSrc ? (
                <>
                  <div
                    className={`device-overlay hovered`}
                    onDoubleClick={() => handleDoubleClick()}
                  >
                    <div className="device-title-container">
                      <div className="device-name">
                        {currentDevice?.deviceName}
                      </div>
                      <div className="device-location">
                        {currentDevice?.locationName} •{' '}
                        {currentDevice?.areaName}
                      </div>
                    </div>
                    <div className="date-time-wrapper">
                      <div className="date-time">
                        <WiTime4 size={14} />
                        {moment
                          .tz(moment(time), timeZone)
                          .format('hh:mm:ss A z')}
                      </div>
                    </div>
                    <div className="menu-icon">
                      <NavDropdown
                        className="devices-dropdown"
                        title={<ThreeDotIcon />}
                      >
                        <NavDropdown.Item
                          className="devices-dropdown-options"
                          onClick={() => OnClickCameraDetails()}
                        >
                          <HiOutlineVideoCamera size={16} />
                          <span className="devices-dropdown-options-label">
                            {constants.CAMERAS_VIDEO_CAMERA_DETAILS_LABEL}
                          </span>
                        </NavDropdown.Item>
                        {userPolicies.view_device_settings && (
                          <NavDropdown.Item
                            className="devices-dropdown-options"
                            onClick={() => OnClickCameraSettings()}
                          >
                            <FiSettings size={14} />
                            <span className="devices-dropdown-options-label">
                              {constants.CAMERAS_VIDEO_SETTINGS_LABEL}
                            </span>
                          </NavDropdown.Item>
                        )}
                      </NavDropdown>
                    </div>
                  </div>
                  <img
                    ref={imageRef}
                    id="img-snapshot"
                    className={`main-snapshot`}
                    src={
                      selectedEventTime &&
                      selectedEventTime / 1000 ===
                        Utils.getUnixDate(Utils.getDate(time / 1000)) &&
                      selectedSnapSrc
                        ? selectedSnapSrc
                        : newSrc
                          ? newSrc
                          : nosnapshot
                    }
                    alt=""
                    onLoad={(event) => {
                      dispatch(setIsRemoteStreamPlay(true));
                    }}
                    onError={(event) => {
                      setNewSrc('');
                      setSelectedSnapSrc('');
                    }}
                  />
                </>
              ) : (
                <NoSnapshot
                  deviceId={deviceId}
                  device={currentDevice}
                  activeTime={time}
                  timeZone={timeZone}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ImageGridItem;
