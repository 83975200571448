import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { HiOutlinePencil } from 'react-icons/hi';
import { FiPlus } from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';
import './AnalyticsSettings.scss';
import { ToggleInput, InputSlider } from '../../../../components/forms';
import { PrimaryButton, SiteModal } from '../../../../components/common';
import { Utils, constants } from '../../../../helpers';
import publishDeviceSetting from '../shared/PublishDeviceSettings';
import EnclusionZone from './ExclusionZone';
import InclusionZone from './InclusionZone';
import OccupancyQueue from './OccupancyQueue';
import LineCrossings from './LineCrossings';
import { ReactComponent as Explosion } from '../../../../assets/images/Explosion.svg';
import { ReactComponent as Gunshot } from '../../../../assets/images/gunshot.svg';
import { ReactComponent as Scream } from '../../../../assets/images/Scream.svg';
import { ReactComponent as GlassBreak } from '../../../../assets/images/GlassBreak.svg';
import CategoryEventSelection from './CategoryEventSelection';
import { EventType } from '../../../../helpers/enums';
import EnclusionZoneObject from './EnclusionZoneObject';
import VirtualAreaIva from './VirtualAreaIva';

const AnalyticsSettings = ({
  accountId,
  deviceId,
  deviceInfos,
  devicePublishId,
  uuid,
  updateStore,
  hasManagePermission,
}) => {
  const [update, setUpdate] = useState(false);
  const timeOutRef = useRef(null);
  const [title, setTitle] = useState('');
  const [showDetectionmodal, setShowDetectionmodal] = useState(false);
  const [modelData, setModalData] = useState({});
  const [modalIndex, setModalIndex] = useState(0);
  const capabilityList = deviceInfos?.capDetails?.capabilities;
  const deviceState = deviceInfos?.deviceStatus;
  const connectionState = deviceInfos?.connectionStatus;
  const curDeviceStatus = Utils.getDeviceStatus(deviceState, connectionState);
  const canChangeSettings =
    curDeviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS &&
    hasManagePermission;
  const [refResolution, setRefResolution] = useState();
  const resourceList = Utils.getDeviceSettingResource(7);
  const capMotion = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[1],
  );
  const capShock = Utils.getCurrentTabProperty(capabilityList, resourceList[2]);
  const capTamper = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[3],
  );
  const capDefocus = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[4],
  );
  const capAudio = Utils.getCurrentTabProperty(capabilityList, resourceList[5]);
  const capVirtualLine = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[6],
  );
  const capInclusionArea = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[1],
  );
  const MAX_REGION_TO_BE_ADDED_COUNT =
    +capInclusionArea?.['md-include-area-index']?.max + 1 || 1;

  const capExclusionArea = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[1],
  );
  const MAX_REGION_TO_BE_ADDED_COUNT_EXCULDED =
    +capExclusionArea?.['md-exclude-area-index']?.max + 1 || 1;

  const capQueueManagement = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[8],
  );
  const capSoundClassification = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[10],
  );
  const capObjectDetection = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[0],
  );

  const capVirtualAreaIva = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[13],
  );
  const ivaExcluionArea = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[9],
  );
  const MAX_REGION_TO_BE_ADDED_COUNT_EXCULDED_OBJ =
    +capObjectDetection?.['obj-exclude-area-index']?.max + 1 || 1;

  const MAX_REGION_TO_BE_ADDED_COUNT_VIRTUAL_AREA_IVA =
    +capVirtualAreaIva?.['virtual-area-index']?.max + 1 || 1;

  const MAX_REGION_TO_BE_ADDED_COUNT_VIRTUAL_LINE =
    +capVirtualLine?.['virtual-line-index']?.max + 1 || 1;

  const [eventTypeListObj, setEventTypeListObj] = useState(
    Utils.getObjectTypes(capObjectDetection),
  );

  const capFogDetection = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[11],
  );
  const [vehicleDetection, setVehicleDetection] = useState(
    deviceInfos?.properties?.['vehicle-detection'] ===
      constants.PROPERTY_STATUS_TRUE,
  );
  const [licensePlateDetection, setLicensePlateDetection] = useState(
    deviceInfos?.properties?.['licenseplate-detection'] ===
      constants.PROPERTY_STATUS_TRUE,
  );
  const [personDetection, setPersonDetection] = useState(
    deviceInfos?.properties?.['person-detection'] ===
      constants.PROPERTY_STATUS_TRUE,
  );
  const [faceDetection, setFaceDetection] = useState(
    deviceInfos?.properties?.['face-detection'] ===
      constants.PROPERTY_STATUS_TRUE,
  );
  const [soundClassificationEnabled, setSoundClassificationEnabled] = useState(
    deviceInfos?.properties?.['sc-enabled'] === constants.PROPERTY_STATUS_TRUE,
  );
  const [fogDetectionEnabled, setFogDetectionEnabled] = useState(
    deviceInfos?.properties?.['fd-enable'] === constants.PROPERTY_STATUS_TRUE,
  );
  const [gunShotEnabled, setGunShotEnabled] = useState(
    deviceInfos?.properties?.['sc-gunshot'] === constants.PROPERTY_STATUS_TRUE,
  );
  const [glassBreakEnable, setGlassBreakEnable] = useState(
    deviceInfos?.properties?.['sc-glassbreak'] ===
      constants.PROPERTY_STATUS_TRUE,
  );
  const [scExplosionEnable, setScExplosionEnable] = useState(
    deviceInfos?.properties?.['sc-explosion'] ===
      constants.PROPERTY_STATUS_TRUE,
  );
  const [scScreamEnable, setScreamEnable] = useState(
    deviceInfos?.properties?.['sc-scream'] === constants.PROPERTY_STATUS_TRUE,
  );
  const [scLevel, setScLevel] = useState(deviceInfos?.properties?.['sc-level']);
  const [motionDetection, setMotionDetection] = useState(
    deviceInfos?.properties?.['md-enable'] === constants.PROPERTY_STATUS_TRUE,
  );
  const [objectDetection, setobjectDetection] = useState(
    deviceInfos?.properties?.['object-detection'] ===
      constants.PROPERTY_STATUS_TRUE,
  );
  const [ivaEnable, setIvaEnable] = useState(
    deviceInfos?.properties?.['iva-enable'] === constants.PROPERTY_STATUS_TRUE,
  );
  const [shockDetection, setShockDetection] = useState(
    deviceInfos?.properties?.['skd-enable'] === constants.PROPERTY_STATUS_TRUE,
  );
  const [temperingDetection, setTemperingDetection] = useState(
    deviceInfos?.properties?.['td-enable'] === constants.PROPERTY_STATUS_TRUE,
  );
  const [defocusDetection, setDefocusDetection] = useState(
    deviceInfos?.properties?.['dfd-enable'] === constants.PROPERTY_STATUS_TRUE,
  );
  const [audioDetction, setAudioDetction] = useState(
    deviceInfos?.properties?.['audio-detection'] ===
      constants.PROPERTY_STATUS_TRUE,
  );
  const [exclusionZonesData, setExclusionZonesData] = useState(
    deviceInfos?.properties?.['md-exclude-area']
      ? JSON.parse(deviceInfos?.properties?.['md-exclude-area'])
      : { 'md-exclude-areas': [] },
  );
  const [exclusionZonesDataObj, setExclusionZonesDataObj] = useState(
    deviceInfos?.properties?.['obj-exclude-area']
      ? JSON.parse(deviceInfos?.properties?.['obj-exclude-area'])
      : { 'obj-exclude-areas': [] },
  );
  const [virtualAreaIva, setvirtualAreaIva] = useState(
    deviceInfos?.properties?.['virtual-area']
      ? JSON.parse(deviceInfos?.properties?.['virtual-area'])
      : { 'virtual-areas': [] },
  );
  const [inclusionZonesData, setInclusionZonesData] = useState(
    deviceInfos?.properties?.['include-area']
      ? JSON.parse(deviceInfos?.properties?.['include-area'])
      : { 'include-areas': [] },
  );
  const [queuesData, setQueuesData] = useState(
    deviceInfos?.properties?.['queue']
      ? JSON.parse(deviceInfos?.properties?.['queue'])
      : { queues: [] },
  );
  const [lineCrossingsData, setLineCrossingsData] = useState(
    deviceInfos?.properties?.['virtual-line']
      ? JSON.parse(deviceInfos?.properties?.['virtual-line'])
      : { 'virtual-lines': [] },
  );
  const [showExclusionZone, setShowExclusionZone] = useState(false);
  const [showExclusionZoneObj, setShowExclusionZoneObj] = useState(false);
  const [showVirtualAreaIva, setShowVirtualAreaIva] = useState(false);
  const [showInclusionZone, setShowInclusionZone] = useState(false);
  const [showOccupancyQueue, setShowOccupancyQueue] = useState(false);
  const [showLineCrossing, setShowLineCrossing] = useState(false);
  const publishPayload = {
    deviceId: deviceId,
    devicePublishId: devicePublishId,
    uuid: uuid,
    accountId: accountId,
    hubId: deviceInfos.gatewayId,
  };
  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    Utils.vmsLogger().log('first', capabilityList);
    if (deviceId && deviceInfos) {
      if (deviceInfos) {
        const referenceResolution =
          deviceInfos?.properties?.['reference-resolution'];
        Utils.vmsLogger().log(
          'first',
          deviceInfos?.properties?.['reference-resolution'],
        );
        if (referenceResolution) {
          setRefResolution(referenceResolution.split('x'));
        }
      }
    }
  }, []);

  const getCheckPropertyStatus = (value, key) => {
    return {
      [key]: value
        ? constants.PROPERTY_STATUS_TRUE
        : constants.PROPERTY_STATUS_FALSE,
    };
  };

  useEffect(() => {
    if (modalIndex !== 0) {
      debounce(() => {
        setShowDetectionmodal(true);
      }, 100)();
    }
  }, [update, modalIndex]);

  useEffect(() => {
    setVehicleDetection(
      deviceInfos?.properties?.['vehicle-detection'] ===
        constants.PROPERTY_STATUS_TRUE,
    );
    setPersonDetection(
      deviceInfos?.properties?.['person-detection'] ===
        constants.PROPERTY_STATUS_TRUE,
    );
    setFaceDetection(
      deviceInfos?.properties?.['face-detection'] ===
        constants.PROPERTY_STATUS_TRUE,
    );
    setLicensePlateDetection(
      deviceInfos?.properties?.['licenseplate-detection'] ===
        constants.PROPERTY_STATUS_TRUE,
    );
    setSoundClassificationEnabled(
      deviceInfos?.properties?.['sc-enabled'] ===
        constants.PROPERTY_STATUS_TRUE,
    );

    setFogDetectionEnabled(
      deviceInfos?.properties?.['fd-enable'] === constants.PROPERTY_STATUS_TRUE,
    );

    setGunShotEnabled(
      deviceInfos?.properties?.['sc-gunshot'] ===
        constants.PROPERTY_STATUS_TRUE,
    );

    setGlassBreakEnable(
      deviceInfos?.properties?.['sc-glassbreak'] ===
        constants.PROPERTY_STATUS_TRUE,
    );

    setScExplosionEnable(
      deviceInfos?.properties?.['sc-explosion'] ===
        constants.PROPERTY_STATUS_TRUE,
    );

    setScreamEnable(
      deviceInfos?.properties?.['sc-scream'] === constants.PROPERTY_STATUS_TRUE,
    );

    setScLevel(deviceInfos?.properties?.['sc-level']);

    setMotionDetection(
      deviceInfos?.properties?.['md-enable'] === constants.PROPERTY_STATUS_TRUE,
    );
    setShockDetection(
      deviceInfos?.properties?.['skd-enable'] ===
        constants.PROPERTY_STATUS_TRUE,
    );
    setTemperingDetection(
      deviceInfos?.properties?.['td-enable'] === constants.PROPERTY_STATUS_TRUE,
    );
    setDefocusDetection(
      deviceInfos?.properties?.['dfd-enable'] ===
        constants.PROPERTY_STATUS_TRUE,
    );
    setIvaEnable(
      deviceInfos?.properties?.['iva-enable'] ===
        constants.PROPERTY_STATUS_TRUE,
    );
    setAudioDetction(
      deviceInfos?.properties?.['audio-detection'] ===
        constants.PROPERTY_STATUS_TRUE,
    );
    setExclusionZonesData(
      deviceInfos?.properties?.['md-exclude-area']
        ? JSON.parse(deviceInfos?.properties?.['md-exclude-area'])
        : { 'md-exclude-areas': [] },
    );
    setExclusionZonesDataObj(
      deviceInfos?.properties?.['obj-exclude-area']
        ? JSON.parse(deviceInfos?.properties?.['obj-exclude-area'])
        : { 'obj-exclude-areas': [] },
    );
    setvirtualAreaIva(
      deviceInfos?.properties?.['virtual-area']
        ? JSON.parse(deviceInfos?.properties?.['virtual-area'])
        : { 'virtual-areas': [] },
    );
    setInclusionZonesData(
      deviceInfos?.properties?.['include-area']
        ? JSON.parse(deviceInfos?.properties?.['include-area'])
        : { 'include-areas': [] },
    );
    setLineCrossingsData(
      deviceInfos?.properties?.['virtual-line']
        ? JSON.parse(deviceInfos?.properties?.['virtual-line'])
        : { 'virtual-lines': [] },
    );
    setQueuesData(
      deviceInfos?.properties?.['queue']
        ? JSON.parse(deviceInfos?.properties?.['queue'])
        : { queues: [] },
    );

    if (modelData == 'undefined' && modalIndex !== 0) {
      setModalData(
        Utils.getDetectionValue(modalIndex, deviceInfos?.properties, modelData),
      );
    }
  }, [deviceInfos]);

  const selectDetection = (e, name) => {
    e.preventDefault();
    switch (name) {
      case constants.DEVICES_TAB_ANALYTICS_SETTINGS_MOTION_DETECTION:
        setModalIndex(1);
        setModalData(
          Utils.getModalData(
            1,
            deviceInfos?.properties,
            capMotion,
            resourceList[1],
          ),
        );
        break;
      case constants.DEVICES_TAB_ANALYTICS_SETTINGS_SHOCK_DETECTION:
        setModalIndex(2);
        setModalData(
          Utils.getModalData(
            2,
            deviceInfos?.properties,
            capShock,
            resourceList[2],
          ),
        );
        break;
      case constants.DEVICES_TAB_ANALYTICS_SETTINGS_TAMPERING_DETECTION:
        setModalIndex(3);
        setModalData(
          Utils.getModalData(
            3,
            deviceInfos?.properties,
            capTamper,
            resourceList[3],
          ),
        );
        break;
      case constants.DEVICES_TAB_ANALYTICS_SETTINGS_DEFOCUS_DETECTION:
        setModalIndex(4);
        setModalData(
          Utils.getModalData(
            4,
            deviceInfos?.properties,
            capDefocus,
            resourceList[4],
          ),
        );
        break;
      case constants.DEVICES_TAB_ANALYTICS_SETTINGS_AUDIO_DETECTION:
        setModalIndex(5);
        setModalData(
          Utils.getModalData(
            5,
            deviceInfos?.properties,
            capAudio,
            resourceList[5],
          ),
        );
        break;
      case constants.DEVICES_TAB_ANALYTICS_SETTINGS_FOG_DETECTION:
        setModalIndex(7);
        setModalData(
          Utils.getModalData(
            7,
            deviceInfos?.properties,
            capFogDetection,
            resourceList[11],
          ),
        );
        break;
      case constants.DEVICES_TAB_ANALYTICS_SETTINGS_SOUND_CLASSIFICATION:
        setModalIndex(8);
        setModalData(
          Utils.getModalData(
            6,
            deviceInfos?.properties,
            capSoundClassification,
            resourceList[10],
          ),
        );
        break;
      case constants.DEVICES_TAB_ANALYTICS_SETTINGS_OBJECT_DETACTION:
        setModalIndex(9);
        setModalData(
          Utils.getModalData(
            8,
            deviceInfos?.properties,
            capObjectDetection,
            resourceList[0],
          ),
        );
        break;
      default:
      // do nothing
    }
    setTitle(name);
  };

  const onChangeHandler = (e, property, resource) => {
    const status = getCheckPropertyStatus(
      !!!JSON.parse(e?.target?.value),
      property,
    );
    updateStore(status);
    if (property === 'person-detection') {
      const personStatus = getCheckPropertyStatus(
        !!!JSON.parse(e?.target?.value),
        'face-detection',
      );
      setTimeout(() => {
        publishDeviceSetting(personStatus, resource, publishPayload);
      }, 1000);
    }
    if (property === 'vehicle-detection') {
      const vehicleStatus = getCheckPropertyStatus(
        !!!JSON.parse(e?.target?.value),
        'licenseplate-detection',
      );
      setTimeout(() => {
        publishDeviceSetting(vehicleStatus, resource, publishPayload);
      }, 1000);
    }
    publishDeviceSetting(status, resource, publishPayload);
    setUpdate(!!update);
  };

  const onCheckedHandler = (e, property, resource) => {
    const status = getCheckPropertyStatus(e?.target?.checked, property);
    updateStore(status);
    publishDeviceSetting(status, resource, publishPayload);
    setUpdate(!!update);
  };

  const handleInclusionZonePlusClick = () => {
    setShowInclusionZone(true);
  };

  const handleExclusionZonePlusClick = () => {
    setShowExclusionZone(true);
  };
  const handleExclusionZonePlusClickObj = () => {
    setShowExclusionZoneObj(true);
  };
  const handleVirtualAreaIva = () => {
    setShowVirtualAreaIva(true);
  };
  const handleOccupancyQueuePlusClick = () => {
    setShowOccupancyQueue(true);
  };

  const handleLineCrossingPlusClick = () => {
    setShowLineCrossing(true);
  };

  const eventTypeSelected = (publishData) => {
    const properties =
      eventList?.length > 0
        ? {
            ...publishData,
            [EventType.OBJ_DETECTION]: 'true',
            [modelData.mindurationKey]: modelData?.valueDuration?.toString(),
          }
        : {
            [EventType.OBJ_DETECTION]: 'true',
            [modelData.mindurationKey]: modelData?.valueDuration?.toString(),
          };
    updateStore(properties);
    publishDeviceSetting(properties, modelData.resource, publishPayload);
    setShowDetectionmodal(false);
    setModalIndex(0);
  };

  const isAudioTitleShow =
    Utils.getPropertyShowStatus(curDeviceStatus, 'audio-detection', capAudio) ||
    Utils.getPropertyShowStatus(
      curDeviceStatus,
      'sc-enabled',
      capSoundClassification,
    );
  useEffect(() => {
    if (!showDetectionmodal) {
      setModalData(0); // Clear stale data
    }
  }, [showDetectionmodal]);

  useEffect(() => {
    eventTypeListObj?.forEach((item) => {
      const apiValue = deviceInfos?.properties?.[item.keyName];
      if (apiValue !== undefined) {
        item.isSlected = apiValue === 'true' ? '1' : '0'; // Update main category
      }
      // If the item is the Vehicle category, update vehicle types
      if (item.typeName === EventType?.VEHICLE && item?.vehicleTypes) {
        item?.vehicleTypes?.forEach((vehicle) => {
          const vehicleApiValue = deviceInfos?.properties?.[vehicle.keyName];
          if (vehicleApiValue !== undefined) {
            vehicle.isSlected = vehicleApiValue === 'true' ? '1' : '0'; // Update vehicle type
          }
        });
      }
    });
    setEventTypeListObj(eventTypeListObj);

    const eventListData = eventTypeListObj;
    const keys = Object.keys(capObjectDetection ? capObjectDetection : {});
    const filteredKeys = keys?.filter((key) =>
      Utils.selectedKeysObjects.includes(key),
    );
    const hasVehicleDetection = Utils.vehicleKeysObjects.some((key) =>
      filteredKeys?.includes(key),
    );
    if (
      hasVehicleDetection &&
      !filteredKeys?.includes(constants.EVENT_TYPE_OBJ_DETECTION_VEHICLE_KEY)
    ) {
      filteredKeys?.push(constants.EVENT_TYPE_OBJ_DETECTION_VEHICLE_KEY);
    }
    const filteredEventList = eventListData?.filter((item) =>
      filteredKeys?.includes(item.keyName),
    );
    const finalEventList = filteredEventList?.map((item) => {
      if (
        item?.keyName === constants.EVENT_TYPE_OBJ_DETECTION_VEHICLE_KEY &&
        item?.vehicleTypes?.length
      ) {
        item.vehicleTypes = item.vehicleTypes.filter((vehicle) =>
          filteredKeys?.includes(vehicle.keyName),
        );
      }
      return item;
    });
    setEventList(finalEventList || []);
  }, [deviceInfos]);

  const ivaCapStatus =
    Utils.getPropertyShowStatus(
      curDeviceStatus,
      'virtual-line-enable',
      capVirtualLine,
    ) ||
    Utils.getPropertyShowStatus(
      curDeviceStatus,
      'virtual-area-enable',
      capVirtualAreaIva,
    ) ||
    Utils.getPropertyShowStatus(
      curDeviceStatus,
      'exclude-area-enable',
      ivaExcluionArea,
    );

  // Custom debounce using useRef since lodash debounce isn't compatible. Do not remove.
  const debounce = (func, delay) => {
    return (...args) => {
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current);
      }
      timeOutRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  return (
    <div
      className={`text-start device-settings-block ${
        curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS
          ? ' editable-off-offline'
          : ''
      }`}
    >
      <div className="tab-title-analytics">
        <label>{constants.ANALYTICS_SETTINGS}</label>
      </div>

      {Utils.getPropertyShowStatus(curDeviceStatus, 'md-enable', capMotion) && (
        <div className="settings-motion-detection-block">
          <div className="d-flex align-items-center">
            <Col>
              <ToggleInput
                label={
                  constants.DEVICES_TAB_ANALYTICS_SETTINGS_MOTION_DETECTION
                }
                name="motionDetection"
                curDeviceStatus={curDeviceStatus}
                changeHandler={(e) => {
                  setMotionDetection(!!!JSON.parse(e?.target?.value));
                  onChangeHandler(e, 'md-enable', resourceList[1]);
                }}
                value={motionDetection}
                isAddEdit={true}
                disabled={
                  deviceInfos?.properties?.recEventBased === 'true' ||
                  !canChangeSettings
                    ? true
                    : false
                }
              />
            </Col>
          </div>
          {Utils.getPropertyShowStatus(
            curDeviceStatus,
            'md-include-area-enable',
            capMotion,
          ) && (
            <div className="row-included-area">
              <div className="title-areas">
                {constants.INCLUDED_AREAS}
                {` ${inclusionZonesData?.['include-areas']?.length}/${MAX_REGION_TO_BE_ADDED_COUNT}`}{' '}
              </div>
              {inclusionZonesData?.['include-areas']?.length <= 0 ? (
                <FiPlus
                  onClick={(e) => {
                    if (canChangeSettings) {
                      handleInclusionZonePlusClick();
                    }
                  }}
                  className={`add-edit-icon plus-icon ${
                    (!canChangeSettings || !motionDetection) && 'off-status'
                  }`}
                />
              ) : (
                <HiOutlinePencil
                  onClick={() => {
                    if (canChangeSettings) {
                      handleInclusionZonePlusClick();
                    }
                  }}
                  className={`add-edit-icon ${
                    (!canChangeSettings || !motionDetection) && 'off-status'
                  }`}
                  size={24}
                />
              )}
            </div>
          )}
          {Utils.getPropertyShowStatus(
            curDeviceStatus,
            'md-exclude-area-enable',
            capMotion,
          ) && (
            <div className="row-included-area exclude-padding">
              <div className="title-areas">
                {constants.EXCLUDED_AREAS}
                {` ${exclusionZonesData?.['md-exclude-areas']?.length}/${MAX_REGION_TO_BE_ADDED_COUNT_EXCULDED}`}
              </div>
              {exclusionZonesData?.['md-exclude-areas']?.length <= 0 ? (
                <FiPlus
                  onClick={(e) => {
                    handleExclusionZonePlusClick();
                  }}
                  className={`add-edit-icon plus-icon ${
                    (!canChangeSettings || !motionDetection) && 'off-status'
                  }`}
                />
              ) : (
                <HiOutlinePencil
                  onClick={() => {
                    if (canChangeSettings) {
                      handleExclusionZonePlusClick();
                    }
                  }}
                  className={`add-edit-icon ${
                    (!canChangeSettings || !motionDetection) && 'off-status'
                  }`}
                  size={24}
                />
              )}
            </div>
          )}
        </div>
      )}

      {Utils.getPropertyShowStatus(curDeviceStatus, 'skd-enable', capShock) && (
        <div className="settings-block-analytics">
          <Col>
            <ToggleInput
              label={constants.DEVICES_TAB_ANALYTICS_SETTINGS_SHOCK_DETECTION}
              name="shockDetection"
              curDeviceStatus={curDeviceStatus}
              changeHandler={(e) => {
                setShockDetection(!!!JSON.parse(e?.target?.value));
                onChangeHandler(e, 'skd-enable', resourceList[2]);
              }}
              value={shockDetection}
              isAddEdit={true}
              disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
              disabled={!canChangeSettings}
              icon={
                <HiOutlinePencil
                  onClick={(e) =>
                    selectDetection(
                      e,
                      constants.DEVICES_TAB_ANALYTICS_SETTINGS_SHOCK_DETECTION,
                    )
                  }
                  className={`add-edit-icon ${
                    !shockDetection ? 'off-status' : ''
                  }`}
                />
              }
            />
          </Col>
        </div>
      )}

      {Utils.getPropertyShowStatus(curDeviceStatus, 'td-enable', capTamper) && (
        <div className="settings-block-analytics">
          <Col>
            <ToggleInput
              label={
                constants.DEVICES_TAB_ANALYTICS_SETTINGS_TAMPERING_DETECTION
              }
              name="temperingDetection"
              curDeviceStatus={curDeviceStatus}
              changeHandler={(e) => {
                setTemperingDetection(!!!JSON.parse(e?.target?.value));
                onChangeHandler(e, 'td-enable', resourceList[3]);
              }}
              isAddEdit={true}
              value={temperingDetection}
              disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
              disabled={!canChangeSettings}
              icon={
                <HiOutlinePencil
                  onClick={(e) =>
                    selectDetection(
                      e,
                      constants.DEVICES_TAB_ANALYTICS_SETTINGS_TAMPERING_DETECTION,
                    )
                  }
                  className={`add-edit-icon ${
                    !temperingDetection ? ' off-status' : ''
                  }`}
                />
              }
            />
          </Col>
        </div>
      )}

      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'dfd-enable',
        capDefocus,
      ) && (
        <div className="settings-block-analytics">
          <Col>
            <ToggleInput
              label={constants.DEVICES_TAB_ANALYTICS_SETTINGS_DEFOCUS_DETECTION}
              curDeviceStatus={curDeviceStatus}
              name="defocusDetection"
              changeHandler={(e) => {
                setDefocusDetection(!!!JSON.parse(e?.target?.value));
                onChangeHandler(e, 'dfd-enable', resourceList[4]);
              }}
              isAddEdit={true}
              value={defocusDetection}
              disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
              disabled={!canChangeSettings}
              icon={
                <HiOutlinePencil
                  onClick={(e) =>
                    selectDetection(
                      e,
                      constants.DEVICES_TAB_ANALYTICS_SETTINGS_DEFOCUS_DETECTION,
                    )
                  }
                  className={`add-edit-icon ${
                    !defocusDetection ? ' off-status' : ''
                  }`}
                />
              }
            />
          </Col>
        </div>
      )}

      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'fd-enable',
        capFogDetection,
      ) && (
        <div className="settings-block-analytics">
          <Col>
            <ToggleInput
              label={constants.DEVICES_TAB_ANALYTICS_SETTINGS_FOG_DETECTION}
              name="fogDetection"
              curDeviceStatus={curDeviceStatus}
              changeHandler={(e) => {
                setFogDetectionEnabled(!!!JSON.parse(e?.target?.value));
                onChangeHandler(e, 'fd-enable', resourceList[11]);
              }}
              value={fogDetectionEnabled}
              isAddEdit={true}
              disabled={
                deviceInfos?.properties?.recEventBased === 'true' ? true : false
              }
              icon={
                <HiOutlinePencil
                  onClick={(e) => {
                    selectDetection(
                      e,
                      constants.DEVICES_TAB_ANALYTICS_SETTINGS_FOG_DETECTION,
                    );
                  }}
                  className={`add-edit-icon ${
                    !fogDetectionEnabled ? ' off-status' : ''
                  }`}
                />
              }
            />
          </Col>
        </div>
      )}

      {isAudioTitleShow && (
        <div className="tab-title-analytics">
          <label>{constants.AUDIO_ANALYTICS_SETTING}</label>
        </div>
      )}

      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'audio-detection',
        capAudio,
      ) && (
        <div className="settings-block-analytics">
          <Col>
            <ToggleInput
              label={constants.DEVICES_TAB_ANALYTICS_SETTINGS_AUDIO_DETECTION}
              name="audioDetction"
              curDeviceStatus={curDeviceStatus}
              changeHandler={(e) => {
                setAudioDetction(!!!JSON.parse(e?.target?.value));
                onChangeHandler(e, 'audio-detection', resourceList[5]);
              }}
              isAddEdit={true}
              value={audioDetction}
              disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
              disabled={!canChangeSettings}
              icon={
                <HiOutlinePencil
                  onClick={(e) =>
                    selectDetection(
                      e,
                      constants.DEVICES_TAB_ANALYTICS_SETTINGS_AUDIO_DETECTION,
                    )
                  }
                  className={`add-edit-icon ${
                    !audioDetction ? ' off-status' : ''
                  }`}
                />
              }
            />
          </Col>
        </div>
      )}

      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'sc-enabled',
        capSoundClassification,
      ) && (
        <div className="settings-block-analytics">
          <Col>
            <ToggleInput
              label={
                constants.DEVICES_TAB_ANALYTICS_SETTINGS_SOUND_CLASSIFICATION
              }
              name="soundClassification"
              curDeviceStatus={curDeviceStatus}
              changeHandler={(e) => {
                setSoundClassificationEnabled(!!!JSON.parse(e?.target?.value));
                onChangeHandler(e, 'sc-enabled', resourceList[10]);
              }}
              value={soundClassificationEnabled}
              isAddEdit={true}
              disabled={
                deviceInfos?.properties?.recEventBased === 'true' ? true : false
              }
              icon={
                <HiOutlinePencil
                  onClick={(e) => {
                    if (!soundClassificationEnabled) return;
                    selectDetection(
                      e,
                      constants.DEVICES_TAB_ANALYTICS_SETTINGS_SOUND_CLASSIFICATION,
                    );
                  }}
                  className={`add-edit-icon ${
                    !motionDetection ? ' off-status' : ''
                  }`}
                />
              }
            />
          </Col>
        </div>
      )}

      {(ivaCapStatus ||
        Utils.getPropertyShowStatus(
          curDeviceStatus,
          'object-detection',
          capObjectDetection,
        )) && (
        <div className="tab-title-analytics">
          <label>{constants.ADVANCED_ANALYTICS}</label>
        </div>
      )}

      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'object-detection',
        capObjectDetection,
      ) && (
        <div className="settings-motion-detection-block">
          <div className="d-flex align-items-center">
            <Col>
              <ToggleInput
                label={
                  constants.DEVICES_TAB_ANALYTICS_SETTINGS_OBJECT_DETACTION
                }
                name="objectDetection"
                curDeviceStatus={curDeviceStatus}
                changeHandler={(e) => {
                  setobjectDetection(!!!JSON.parse(e?.target?.value));
                  onChangeHandler(e, 'object-detection', resourceList[0]);
                }}
                value={objectDetection}
                isAddEdit={true}
                disabled={false}
                icon={
                  <HiOutlinePencil
                    onClick={(e) => {
                      selectDetection(
                        e,
                        constants.DEVICES_TAB_ANALYTICS_SETTINGS_OBJECT_DETACTION,
                      );
                    }}
                    className={`add-edit-icon ${
                      !objectDetection ? ' off-status' : ''
                    }`}
                  />
                }
              />
            </Col>
          </div>
          {Utils.getPropertyShowStatus(
            curDeviceStatus,
            'obj-exclude-area-enable',
            capObjectDetection,
          ) && (
            <div className="row-included-area exclude-padding">
              <div className="title-areas">
                {constants.EXCLUDED_AREAS}
                {` ${exclusionZonesDataObj?.['obj-exclude-areas']?.length}/${MAX_REGION_TO_BE_ADDED_COUNT_EXCULDED_OBJ}`}
              </div>
              {exclusionZonesDataObj?.['obj-exclude-areas']?.length <= 0 ? (
                <FiPlus
                  onClick={(e) => {
                    handleExclusionZonePlusClickObj();
                  }}
                  className={`add-edit-icon plus-icon ${
                    (!canChangeSettings || !objectDetection) && 'off-status'
                  }`}
                />
              ) : (
                <HiOutlinePencil
                  onClick={() => {
                    if (canChangeSettings) {
                      handleExclusionZonePlusClickObj();
                    }
                  }}
                  className={`add-edit-icon ${
                    (!canChangeSettings || !objectDetection) && 'off-status'
                  }`}
                  size={24}
                />
              )}
            </div>
          )}
        </div>
      )}

      {
        <div className="settings-motion-detection-block">
          {ivaCapStatus && (
            <div className="d-flex align-items-center">
              <Col>
                <ToggleInput
                  label={constants.IVA_TITLE}
                  name="ivaTitle"
                  curDeviceStatus={curDeviceStatus}
                  changeHandler={(e) => {
                    setIvaEnable(!!!JSON.parse(e?.target?.value));
                    onChangeHandler(e, 'iva-enable', resourceList[14]);
                  }}
                  value={ivaEnable}
                  isAddEdit={true}
                  disabled={false}
                />
              </Col>
            </div>
          )}
          {Utils.getPropertyShowStatus(
            curDeviceStatus,
            'virtual-line-enable',
            capVirtualLine,
          ) && (
            <div className="row-included-area exclude-padding">
              <div className="title-areas">
                {constants.VIRTUAL_LINES}
                {` ${lineCrossingsData?.['virtual-lines']?.length}/${MAX_REGION_TO_BE_ADDED_COUNT_VIRTUAL_LINE}`}
              </div>
              {lineCrossingsData?.['virtual-lines']?.length <= 0 ? (
                <FiPlus
                  onClick={(e) => {
                    handleLineCrossingPlusClick();
                  }}
                  className={`add-edit-icon plus-icon ${
                    (!canChangeSettings || !ivaEnable) && 'off-status'
                  }`}
                />
              ) : (
                <HiOutlinePencil
                  onClick={() => {
                    if (canChangeSettings) {
                      handleLineCrossingPlusClick();
                    }
                  }}
                  className={`add-edit-icon ${
                    (!canChangeSettings || !ivaEnable) && 'off-status'
                  }`}
                  size={24}
                />
              )}
              {/* <FiPlus onClick={(e) => {handleLineCrossingPlusClick()}} className={`add-edit-icon plus-icon `} /> */}
            </div>
          )}

          {Utils.getPropertyShowStatus(
            curDeviceStatus,
            'virtual-area-enable',
            capVirtualAreaIva,
          ) && (
            <div className="row-included-area exclude-padding">
              <div className="title-areas">
                {constants.VIRTUAL_AREAS}
                {` ${virtualAreaIva?.['virtual-areas']?.length}/${MAX_REGION_TO_BE_ADDED_COUNT_VIRTUAL_AREA_IVA}`}
              </div>
              {virtualAreaIva?.['virtual-areas']?.length <= 0 ? (
                <FiPlus
                  onClick={(e) => {
                    handleVirtualAreaIva();
                  }}
                  className={`add-edit-icon plus-icon ${
                    (!canChangeSettings || !ivaEnable) && 'off-status'
                  }`}
                />
              ) : (
                <HiOutlinePencil
                  onClick={() => {
                    if (canChangeSettings) {
                      handleVirtualAreaIva();
                    }
                  }}
                  className={`add-edit-icon ${
                    (!canChangeSettings || !ivaEnable) && 'off-status'
                  }`}
                  size={24}
                />
              )}
            </div>
          )}
          {Utils.getPropertyShowStatus(
            curDeviceStatus,
            'exclude-area-enable',
            ivaExcluionArea,
          ) && (
            <div className="row-included-area exclude-padding">
              <div className="title-areas">
                {constants.IVA_EXCLUSION_AREA}
                {` ${exclusionZonesDataObj?.['obj-exclude-areas']?.length}/${MAX_REGION_TO_BE_ADDED_COUNT_EXCULDED_OBJ}`}
              </div>
              {exclusionZonesDataObj?.['obj-exclude-areas']?.length <= 0 ? (
                <FiPlus
                  onClick={(e) => {
                    handleExclusionZonePlusClickObj();
                  }}
                  className={`add-edit-icon plus-icon ${
                    (!canChangeSettings || !ivaEnable) && 'off-status'
                  }`}
                />
              ) : (
                <HiOutlinePencil
                  onClick={() => {
                    if (canChangeSettings) {
                      handleExclusionZonePlusClickObj();
                    }
                  }}
                  className={`add-edit-icon ${
                    (!canChangeSettings || !ivaEnable) && 'off-status'
                  }`}
                  size={24}
                />
              )}
            </div>
          )}
        </div>
      }
      <SiteModal
        modalTitle={title}
        showModal={showDetectionmodal}
        hideModal={() => {
          setShowDetectionmodal(false);
          setTimeout(() => {
            setModalIndex(0);
          }, 300);
        }}
        classes="device-settings-restart-required"
      >
        <div className="sitemodal-inner">
          {modalIndex !== 6 && modalIndex !== 8 && modalIndex !== 9 && (
            <Row className="settings-block">
              <Col>
                <InputSlider
                  label={constants.DEVICE_TAB_LEVEL_OF_DETECTION}
                  fieldName="levelDetection"
                  maxValue={parseInt(modelData?.maxLevelDetection)}
                  minValue={parseInt(modelData?.minLevelDetection)}
                  currentValue={parseInt(modelData?.valueLevelDetection)}
                  isErrorMessage={false}
                  changeHandler={debounce((value) => {
                    setModalData({
                      ...modelData,
                      valueLevelDetection: value.toString(),
                    });
                    const property = {
                      [modelData.levelKey]: value.toString(),
                    };
                    updateStore(property);

                    publishDeviceSetting(
                      property,
                      modelData.resource,
                      publishPayload,
                    );
                  }, 500)}
                  isDetection={true}
                />
              </Col>
            </Row>
          )}

          {modalIndex !== 5 && modalIndex !== 8 && modalIndex !== 9 && (
            <Row className="settings-block">
              <Col>
                <InputSlider
                  label={constants.DEVICES_TAB_AUDIO_SETTINGS_SENSITIVITY_LABEL}
                  fieldName="sensitivity"
                  maxValue={parseInt(modelData?.maxSensitivity)}
                  minValue={parseInt(modelData?.minSensitivity)}
                  currentValue={parseInt(modelData?.valueSensitivity)}
                  isErrorMessage={false}
                  changeHandler={debounce((value) => {
                    if (timeOutRef.current) {
                      clearTimeout(timeOutRef.current);
                    }
                    setModalData({
                      ...modelData,
                      valueSensitivity: value.toString(),
                    });
                    const property = {
                      [modelData.sensitivityKey]: value.toString(),
                    };
                    updateStore(property);

                    publishDeviceSetting(
                      property,
                      modelData.resource,
                      publishPayload,
                    );
                  }, 500)}
                  isDetection={true}
                />
              </Col>
            </Row>
          )}
          {modalIndex !== 2 &&
            modalIndex !== 5 &&
            modalIndex !== 6 &&
            modalIndex !== 8 && (
              <Row className="settings-block">
                <Col md={12}>
                  <InputSlider
                    suffixString="s"
                    label={
                      modalIndex == 9
                        ? constants.MINIMUM_DURATION_OBJ.replace(
                            '${range}',
                            `${parseInt(modelData?.minDuration)} - ${parseInt(modelData?.maxDuration)}`,
                          )
                        : constants.DEVICE_TAB_MINIMUM_DURATION
                    }
                    fieldName="mDuration"
                    maxValue={parseInt(modelData?.maxDuration)}
                    minValue={parseInt(modelData?.minDuration)}
                    currentValue={parseInt(modelData?.valueDuration)}
                    isErrorMessage={false}
                    changeHandler={debounce((value) => {
                      if (timeOutRef.current) {
                        clearTimeout(timeOutRef.current);
                      }

                      setModalData({
                        ...modelData,
                        valueDuration: value.toString(),
                      });
                      if (modalIndex == 9) {
                        return;
                      }
                      const property = {
                        [modelData.mindurationKey]: value.toString(),
                      };
                      updateStore(property);

                      publishDeviceSetting(
                        property,
                        modelData.resource,
                        publishPayload,
                      );
                    }, 500)}
                    isDetection={true}
                  />
                </Col>
              </Row>
            )}
          {modalIndex === 8 && (
            <Row>
              <Col>
                <Row className="settings-block">
                  <Col>
                    <InputSlider
                      label={
                        constants.DEVICES_TAB_ANALYTICS_SETTINGS_SOUND_OF_CLASSIFICATION
                      }
                      fieldName="sensitivity"
                      maxValue={parseInt(modelData?.maxLevel)}
                      minValue={parseInt(modelData?.minLevel)}
                      currentValue={parseInt(scLevel)}
                      isErrorMessage={false}
                      changeHandler={debounce((value) => {
                        setModalData({
                          ...modelData,
                          scLevelValue: value?.toString(),
                        });
                        setScLevel(value?.toString());
                        const property = {
                          [modelData?.levelKey]: value.toString(),
                        };
                        updateStore(property);

                        publishDeviceSetting(
                          property,
                          modelData.resource,
                          publishPayload,
                        );
                      }, 500)}
                      isDetection={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col className="sound-cls-types">
                        {constants.ANALYTICS_SETTINGS_AUDIO_CLASSIFICATION}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="range-field-wrap-types m-t-8 ">
                        <div className="m-l-r-20 w-90">
                          <div className="sound-container">
                            <label className="sound-label">
                              <span className="sound-label-icon">
                                <Explosion size={20} />
                              </span>
                              {
                                constants.DEVICES_TAB_ANALYTICS_SETTINGS_EXPLOSION
                              }
                            </label>
                            <input
                              type="checkbox"
                              className="sound-checkbox"
                              name="explosionDetection"
                              onChange={(e) => {
                                setScExplosionEnable(e?.target?.checked);
                                onCheckedHandler(
                                  e,
                                  'sc-explosion',
                                  resourceList[10],
                                );
                              }}
                              checked={scExplosionEnable}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="range-field-wrap-types m-t-8 ">
                        <div className="m-l-r-20 w-90">
                          <div className="sound-container">
                            <label className="sound-label">
                              <span className="sound-label-icon">
                                <GlassBreak size={20} />
                              </span>
                              {
                                constants.DEVICES_TAB_ANALYTICS_SETTINGS_GLASSBREAK
                              }
                            </label>
                            <input
                              type="checkbox"
                              className="sound-checkbox"
                              name="glassBreakDetection"
                              onChange={(e) => {
                                setGlassBreakEnable(e?.target?.checked);
                                onCheckedHandler(
                                  e,
                                  'sc-glassbreak',
                                  resourceList[10],
                                );
                              }}
                              checked={glassBreakEnable}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="range-field-wrap-types m-t-8 ">
                        <div className="m-l-r-20 w-90">
                          <div className="sound-container">
                            <label className="sound-label">
                              <span className="sound-label-icon">
                                <Gunshot size={20} />
                              </span>
                              {constants.DEVICES_TAB_ANALYTICS_SETTINGS_GUNSHOT}
                            </label>
                            <input
                              type="checkbox"
                              className="sound-checkbox"
                              name="gunShotDetection"
                              onChange={(e) => {
                                setGunShotEnabled(e?.target?.checked);
                                onCheckedHandler(
                                  e,
                                  'sc-gunshot',
                                  resourceList[10],
                                );
                              }}
                              checked={gunShotEnabled}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="range-field-wrap-types m-t-8 ">
                        <div className="m-l-r-20 w-90">
                          <div className="sound-container">
                            <label className="sound-label">
                              <span className="sound-label-icon">
                                <Scream size={20} />
                              </span>
                              {constants.DEVICES_TAB_ANALYTICS_SETTINGS_SCREAM}
                            </label>
                            <input
                              type="checkbox"
                              className="sound-checkbox"
                              name="screamDetection"
                              onChange={(e) => {
                                setScreamEnable(e?.target?.checked);
                                onCheckedHandler(
                                  e,
                                  'sc-scream',
                                  resourceList[10],
                                );
                              }}
                              checked={scScreamEnable}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {modalIndex === 9 && (
            <CategoryEventSelection
              selectedRegion={false}
              saveBtnEnable={true}
              title={EventType?.OBJ_TYPE}
              deviceInfosProperty={deviceInfos?.properties}
              callBackMethod={(publishData) => {
                eventTypeSelected(publishData);
              }}
              eventListData={eventList}
              minDurationData={parseInt(modelData?.valueDuration)}
            />
          )}
        </div>

        {modalIndex !== 9 && (
          <PrimaryButton
            className="btn btn-primary mt-4"
            type="button"
            width="100%"
            height="56px"
            fontSize="1.125rem"
            lineHeight="24px"
            onClick={() => {
              setShowDetectionmodal(false);
              setTimeout(() => {
                setModalIndex(0);
              }, 300);
            }}
          >
            {constants.DEVICES_TAB_ANALYTICS_BACK}
          </PrimaryButton>
        )}
      </SiteModal>

      <SiteModal
        modalTitle={constants.INCLUSION_ZONE_POPUP_TITLE}
        showModal={showInclusionZone}
        hideModal={() => {
          setShowInclusionZone(false);
        }}
        classes="analytics-settings-activity-zone"
      >
        <InclusionZone
          accountId={accountId}
          refResolution={refResolution}
          deviceId={deviceId}
          deviceInfos={deviceInfos}
          devicePublishId={devicePublishId}
          uuid={uuid}
          capInclusionArea={capInclusionArea}
          inclusionZonesData={inclusionZonesData}
          hideModelPopup={() => {
            setShowInclusionZone(false);
          }}
        />
      </SiteModal>

      <SiteModal
        modalTitle={constants.EXCLUSION_ZONE_POPUP_TITLE}
        showModal={showExclusionZone}
        hideModal={() => {
          setShowExclusionZone(false);
        }}
        classes="analytics-settings-activity-zone"
      >
        <EnclusionZone
          accountId={accountId}
          refResolution={refResolution}
          deviceId={deviceId}
          deviceInfos={deviceInfos}
          devicePublishId={devicePublishId}
          uuid={uuid}
          capExclusionArea={capExclusionArea}
          exclusionZonesData={exclusionZonesData}
          hideModelPopup={() => {
            setShowExclusionZone(false);
          }}
        />
      </SiteModal>

      <SiteModal
        modalTitle={constants.EXCLUSION_ZONE_POPUP_TITLE}
        showModal={showExclusionZoneObj}
        hideModal={() => {
          setShowExclusionZoneObj(false);
        }}
        classes="analytics-settings-activity-zone"
      >
        <EnclusionZoneObject
          accountId={accountId}
          refResolution={refResolution}
          deviceId={deviceId}
          deviceInfos={deviceInfos}
          devicePublishId={devicePublishId}
          uuid={uuid}
          capExclusionArea={capObjectDetection}
          exclusionZonesData={exclusionZonesDataObj}
          hideModelPopup={() => {
            setShowExclusionZoneObj(false);
          }}
        />
      </SiteModal>

      <SiteModal
        modalTitle={constants.VIRTUAL_AREA_MODAL_IVA}
        showModal={showVirtualAreaIva}
        hideModal={() => {
          setShowVirtualAreaIva(false);
        }}
        classes="analytics-settings-activity-zone"
      >
        <VirtualAreaIva
          accountId={accountId}
          refResolution={refResolution}
          deviceId={deviceId}
          deviceInfos={deviceInfos}
          devicePublishId={devicePublishId}
          uuid={uuid}
          capExclusionArea={capVirtualAreaIva}
          exclusionZonesData={virtualAreaIva}
          hideModelPopup={() => {
            setShowVirtualAreaIva(false);
          }}
          canChangeSettings={canChangeSettings}
        />
      </SiteModal>

      <SiteModal
        modalTitle={constants.OCCUPANCY_QUEUE_POPUP_TITLE}
        showModal={showOccupancyQueue}
        hideModal={() => {
          setShowOccupancyQueue(false);
        }}
        classes="analytics-settings-activity-zone"
      >
        <OccupancyQueue
          accountId={accountId}
          deviceId={deviceId}
          deviceInfos={deviceInfos}
          devicePublishId={devicePublishId}
          uuid={uuid}
          capQueueManagement={capQueueManagement}
          queuesData={queuesData}
          hideModelPopup={() => {
            setShowExclusionZoneObj(false);
          }}
        />
      </SiteModal>

      <SiteModal
        modalTitle={constants.VIRTUAL_LINES_POPUP_TITLE}
        showModal={showLineCrossing}
        shouldScrollToTop={showLineCrossing}
        hideModal={() => {
          setShowLineCrossing(false);
        }}
        classes="analytics-settings-activity-zone"
      >
        <LineCrossings
          refResolution={refResolution}
          accountId={accountId}
          deviceId={deviceId}
          deviceInfos={deviceInfos}
          devicePublishId={devicePublishId}
          uuid={uuidv4()}
          vehicleDetection={vehicleDetection}
          personDetection={personDetection}
          capVirtualLine={capVirtualLine}
          lineCrossingsData={lineCrossingsData}
          eventTypeListObj={eventTypeListObj}
          eventTypeSelected={eventTypeSelected}
          hideModelPopup={() => {
            setShowLineCrossing(false);
          }}
        />
      </SiteModal>
    </div>
  );
};

export default AnalyticsSettings;
