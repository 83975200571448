import { useRef, useEffect } from 'react';
import '../../utils/webRTC/ump-player-interleave';
import { useDispatch } from 'react-redux';
import { setStreamLoader } from '../../store/reducers/StreamingReducer';

const LocalVideoView = ({ remoteStream, bitmapObject }) => {
  const dispatch = useDispatch();
  const remoteVideoRef = useRef();
  const canvasVideoRef = useRef();
  const bitmapObjectHeight = bitmapObject ? bitmapObject?.StreamHeight : 450;
  const bitmapObjectWidth = bitmapObject ? bitmapObject?.StreamWidth : 764;
  const playerRef = useRef();

  return (
    <div className="remote-view-wrapper">
      <ump-player
        ref={playerRef}
        id="ump-player-test"
        hostname="192.168.125.100"
        datachannel="192.168.125.9:8080"
        username="admin"
        password=""
        channel={1}
        device="camera"
        https={false}
        audiosync={true}
        secure={false}
        profile="H.264"
        profileusage="Network"
        controls
        port={80}
      />
      {/* <video
        id="video"
        // width="764"
        // height="450"
        ref={remoteVideoRef}
        autoPlay={true}
        playsInline={true}
        muted={true}
      /> */}
      <canvas
        id="canvas"
        width={bitmapObjectWidth}
        height={bitmapObjectHeight}
        ref={canvasVideoRef}
        style={{ display: 'none' }}
      ></canvas>
    </div>
  );
};

export default LocalVideoView;
