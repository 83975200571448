import { useState, useEffect, Fragment } from 'react';
import { constants, Utils } from '../../../../helpers';
import { ToggleInput } from '../../../../components/forms';
import { Col } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { BiWebcam } from 'react-icons/bi';
import { IoIosArrowUp, IoIosSearch } from 'react-icons/io';
import { IoIosArrowDown } from 'react-icons/io';
import {
  PrimaryButton,
  SiteModal,
  TextBlock,
} from '../../../../components/common';
import { CiCircleRemove, CiLocationOn } from 'react-icons/ci';
import { CiCircleCheck } from 'react-icons/ci';
import RestoredDevicesTable from './RestoredDevicesTable';
import {
  publishFirmwareDeviceSetting,
  publishGetDeviceLocalStorageSetting,
} from '../shared/PublishDeviceSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { HiOutlinePencil } from 'react-icons/hi';
import { Alert, Checkbox, FormControlLabel } from '@mui/material';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import BackupCalendar from './BackupCalendar';
import axios from 'axios';
import { bandWidthConverter } from '../../../tools/bandwidth/BandwidthCalculationHelper';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const LocalStorageBackup = ({
  canChangeSettings,
  deviceInfos,
  localStoragePermissions,
  publishPayload,
  resourceList,
  publishFirmwareDeviceChannalSetting,
  updateStore,
  showRecordEvent,
  resourceListRecordEvent,
  switchToVideoTab,
  entitlements,
  isHubSettings,
}) => {
  const { t } = useTranslation();
  const [storageEnableModal, setStorageEnableModal] = useState(false);
  const [restorationModal, setRestorationModal] = useState(false);
  const [formatCardModal, setFormatCardModal] = useState(false);
  const [retentionDaysFromEntitlement, setRetentionDaysFromEntitlement] =
    useState(0);
  // Always use Utils.getDeviceStatus() to get device connection status.
  const deviceState = deviceInfos?.deviceStatus;
  const connectionStates = deviceInfos?.connectionStatus;
  const curDeviceStatus = Utils.getDeviceStatus(deviceState, connectionStates);
  const [connectionState, setConnectionState] = useState(curDeviceStatus);
  const [recondOnlyEvents, setRecondOnlyEvents] = useState(
    deviceInfos?.properties?.recEventBased === 'true' ||
      deviceInfos?.properties?.recEventBased === true,
  );
  const [localStorageDeviceEnable, setLocalStorageDeviceEnable] = useState(
    deviceInfos?.properties?.storageBackup === 'true' ||
      deviceInfos?.properties?.storageBackup === true,
  );
  const [isCloudStorageEnabled, setIsCloudStorageEnabled] = useState(false);
  const [showLocalStorageUI, setShowLocalStorageUI] = useState(false);
  const [sdCardStatus, setSdCardStatus] = useState(
    deviceInfos?.properties?.sdCardStatus,
  );
  const [freeSizeMB, setFreeSizeMB] = useState(
    deviceInfos?.properties?.freeSizeMB,
  );
  const [totalSizeMB, setTotalSizeMB] = useState(
    deviceInfos?.properties?.totalSizeMB,
  );
  const [sdcardDetectStatus, setSdcardDetectStatus] = useState(
    deviceInfos?.properties?.sdcardDetectStatus === 'true' ||
      deviceInfos?.properties?.sdcardDetectStatus === true,
  );
  const [isEdgeRecordSupport, setIsEdgeRecordSupport] = useState(false);
  const [backupContentType, setBackupContentType] = useState('event');
  const [isBackupButtonVisible, setIsBackupButtonVisible] = useState(true);
  const [isBackupConfirmOpen, setIsBackupConfirmOpen] = useState(false);
  const [isCalanderOpen, setIsCalanderOpen] = useState(false);
  const [calanderTitle, setCalanderTitle] = useState('');
  const [isApplyToOtherIsOpen, setIsApplyToOtherIsOpen] = useState(false);
  const [openAccordion, setOpenAccordion] = useState([]);
  const defaultCalanerValue = {
    startTime: 0,
    endTime: 0,
    days: [],
    isEnabled: true,
  };
  const [calanderValue, setCalanderValue] = useState(defaultCalanerValue);
  const [locationSearchParam, setLocationSearchParam] = useState('');
  const [cloudArchiveAvailability, setCloudArchiveAvailability] = useState({
    recentVideo: '-',
    oldestVideo: '-',
  });
  const [requiredStorageSizeInMB, setRequiredStorageSizeInMB] = useState(0);
  const deviceTimeZone = deviceInfos?.properties?.timezone;
  const [backupWindowList, setBackupWindowList] = useState([]);

  const [uploadScheduleList, setUploadScheduleList] = useState([]);
  const [cloudStorageBackupStatus, setCloudStorageBackupStatus] = useState('');
  const [backupStatusDateTime, setBackupStatusDateTime] = useState(0);
  const [locationsList, setLocationsList] = useState([
    // todo: Will be removed after API integration
    {
      floor: 'First Floor',
      children: [
        {
          label: 'Meeting Room 1',
          value: false,
          isPersonEventSupported: false,
        },
        { label: 'Meeting Room 2', value: true, isPersonEventSupported: true },
        { label: 'Meeting Room 3', value: true, isPersonEventSupported: true },
      ],
    },
    {
      floor: 'Second Floor',
      children: [
        { label: 'Meeting Room 1', value: false, isPersonEventSupported: true },
        { label: 'Meeting Room 2', value: false, isPersonEventSupported: true },
        { label: 'Meeting Room 3', value: false, isPersonEventSupported: true },
      ],
    },
  ]);
  const [filteredLocationsList, setFilteredLocationsList] = useState([]);

  const minutesToTime = (mins) => {
    let hours = Math.floor(mins / 60);
    const minutes = `${mins % 60}`.padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${period}`;
  };

  const getTimeZoneAbbreviation = (timeZone, date = new Date()) => {
    try {
      const timeZonePart = new Intl.DateTimeFormat('en-IN', {
        timeZone,
        timeZoneName: 'short',
      })
        .formatToParts(date)
        .find((part) => part.type === 'timeZoneName');
      return timeZonePart?.value ?? null;
    } catch (error) {
      console.error('Invalid time zone:', error);
      return null;
    }
  };

  const formatDateTime = (
    epochTime,
    timezone,
    monthLength,
    enableDateSuffix,
  ) => {
    if (!epochTime && !timezone) return;

    const date = new Date(epochTime); // Use epoch directly as milliseconds

    const options = {
      timeZone: timezone,
      year: 'numeric',
      month: monthLength ?? 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZoneName: 'short',
    };

    const formatter = new Intl.DateTimeFormat('en-US', options);
    const formattedDate = formatter.formatToParts(date);

    const parts = Object.fromEntries(
      formattedDate.map(({ type, value }) => [type, value]),
    );

    const day = parts.day;
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const mod10 = day % 10;
    const mod100 = day % 100;

    let dateSuffix =
      mod10 >= 1 && mod10 <= 3 && !(mod100 >= 11 && mod100 <= 13)
        ? suffixes[mod10]
        : suffixes[0];

    return `${parts.day}${enableDateSuffix ? dateSuffix : ''} ${parts.month} ${parts.year}, ${parts.hour}:${parts.minute}${parts.dayPeriod.toUpperCase()} ${getTimeZoneAbbreviation(deviceTimeZone)}`;
  };

  const getStorageStatusMqttCall = () => {
    publishGetDeviceLocalStorageSetting(
      `ch/${deviceInfos?.deviceId}/${resourceList[8]}`,
      publishPayload,
    );
  };

  useEffect(() => {
    setRecondOnlyEvents(
      deviceInfos?.properties?.recEventBased === 'true' ||
        deviceInfos?.properties?.recEventBased === true,
    );

    const deviceState = deviceInfos?.deviceStatus;
    const connectionStates = deviceInfos?.connectionStatus;
    const curDeviceStatus = Utils.getDeviceStatus(
      deviceState,
      connectionStates,
    );
    setConnectionState(curDeviceStatus); // set device connection state

    setLocalStorageDeviceEnable(
      deviceInfos?.properties?.storageBackup === 'true' ||
        deviceInfos?.properties?.storageBackup === true,
    );
    if (
      deviceInfos?.connectionStatus ===
        constants.DEVICES_ONLINE_CONNECTION_STATUS &&
      (deviceInfos?.properties?.storageBackup === 'true' ||
        deviceInfos?.properties?.storageBackup === true)
    ) {
      setShowLocalStorageUI(true);
    } else {
      setShowLocalStorageUI(false);
    }
    setSdCardStatus(deviceInfos?.properties?.sdCardStatus);
    setFreeSizeMB(deviceInfos?.properties?.freeSizeMB);
    setTotalSizeMB(deviceInfos?.properties?.totalSizeMB);
    setSdcardDetectStatus(
      deviceInfos?.properties?.sdcardDetectStatus === 'true' ||
        deviceInfos?.properties?.sdcardDetectStatus === true,
    );

    setIsEdgeRecordSupport(
      localStoragePermissions?.['edge-rec-support']?.includes('true'),
    );

    setIsBackupButtonVisible(
      deviceInfos?.properties?.['backup-status'] === 'partial' ||
        deviceInfos?.properties?.['backup-status'] === 'failed' ||
        deviceInfos?.properties?.['backup-status'] === 'idle',
    );

    setBackupStatusDateTime(
      deviceInfos?.properties?.['backup-status-time'] === 0 ||
        deviceInfos?.properties?.['backup-status-time'] === '0'
        ? '-'
        : deviceInfos?.properties?.['backup-status-time'],
    );

    setBackupContentType(deviceInfos?.properties?.['backup-type']);

    deviceInfos?.properties?.['content-backup-sch'] &&
      setBackupWindowList(
        JSON.parse(deviceInfos?.properties?.['content-backup-sch']),
      );

    deviceInfos?.properties?.['upload-content-sch'] &&
      setUploadScheduleList(
        JSON.parse(deviceInfos?.properties?.['upload-content-sch']),
      );

    setCloudStorageBackupStatus(deviceInfos?.properties?.['backup-status']);
    setRequiredStorageSizeInMB(Number(deviceInfos?.properties?.requiredSizeMB));
  }, [deviceInfos]);

  const enableCloudBackupFlag =
    deviceInfos?.properties?.['enable-cloud-backup'];

  useEffect(() => {
    setIsCloudStorageEnabled(
      deviceInfos?.properties?.['enable-cloud-backup'] === 'true' ||
        deviceInfos?.properties?.['enable-cloud-backup'] === true,
    );
  }, [enableCloudBackupFlag]);

  const getCloudArchiveAvailbility = () => {
    axios
      .get(
        `/timeline/orgs/${deviceInfos?.orgId}/device/${deviceInfos?.deviceId}/metadata/range`,
        Utils?.requestHeader(),
      )
      .then((result) => {
        const startTime = result?.data?.data?.startTime || 0;
        const endTime = result?.data?.data?.endTime || 0;

        const timeZone =
          deviceTimeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;

        setCloudArchiveAvailability({
          recentVideo: endTime ? formatDateTime(endTime, timeZone) : '-',
          oldestVideo: startTime ? formatDateTime(startTime, timeZone) : '-',
        });
      })
      .catch((error) => {
        setCloudArchiveAvailability({
          recentVideo: '-',
          oldestVideo: '-',
        });
      });
  };

  useEffect(() => {
    getCloudArchiveAvailbility();
    getStorageStatusMqttCall();
  }, []);

  useEffect(() => {
    let retentionDays =
      entitlements?.find(
        (entitlement) =>
          entitlement?.type === 'EDGE_STORAGE_CVR_VIEW' &&
          new Date().getTime() < entitlement?.expiry,
      )?.data?.durationInDays ?? 0;

    setRetentionDaysFromEntitlement(retentionDays);
  }, [entitlements]);

  const handleRemoveAllButtonClick = () => {
    setLocationsList((state) => {
      let prevState = [...state];
      let newState = prevState.map((item) => ({
        ...item,
        children: item?.children?.map((child) => ({ ...child, value: false })),
      }));
      return newState;
    });
  };

  const filterLocation = () => {
    if (locationSearchParam) {
      let filteredLocations = locationsList
        ?.map((floor) => ({
          ...floor,
          children: floor?.children?.filter((room) =>
            room?.label
              ?.toLowerCase()
              .includes(locationSearchParam?.toLowerCase()),
          ),
        }))
        ?.filter((floor) => floor?.children?.length > 0);

      setFilteredLocationsList(filteredLocations);
    } else {
      setFilteredLocationsList(locationsList);
    }
  };

  useEffect(() => {
    filterLocation();
  }, [locationSearchParam, locationsList]);

  const handleBackupContentTypeClick = (contentType) => {
    if (contentType !== backupContentType) {
      const status = {
        avoidReload: true,
        'backup-type': contentType,
      };
      updateStore(status);
      publishFirmwareDeviceChannalSetting(
        status,
        resourceList[6],
        publishPayload,
      );
      setBackupContentType(contentType);
    }
  };

  const getCheckPropertyStatus = (value, key) => {
    return {
      [key]: value
        ? constants.PROPERTY_STATUS_TRUE
        : constants.PROPERTY_STATUS_FALSE,
    };
  };

  const BackupContentList = ({ list, listType }) => {
    return (
      <div className="schedule-list-container">
        {list?.map((item) => (
          <div
            className="list-item-container"
            key={`${item?.['cbs-index']} cbs`}
          >
            <div className="list-item-data-container">
              <div className="list-item-day-container">
                {Utils.makeTheDaySelectionShort(item?.['cbs-day'])}
              </div>
              <div className="list-item-time-container">
                {minutesToTime(item?.['cbs-st'])} -&nbsp;
                {minutesToTime(item?.['cbs-et'])}{' '}
                {getTimeZoneAbbreviation(deviceTimeZone)}
              </div>
            </div>
            <div style={{ cursor: 'pointer' }}>
              <HiOutlinePencil
                fontSize={20}
                cursor={'pointer'}
                onClick={() => {
                  if (
                    connectionState?.toLowerCase() !==
                    constants.DEVICES_ONLINE_CONNECTION_STATUS.toLowerCase()
                  ) {
                    return;
                  }
                  setCalanderValue({
                    index: item?.['cbs-index'],
                    startTime: item?.['cbs-st'],
                    endTime: item?.['cbs-et'],
                    days: item?.['cbs-day'].split(','),
                    isEnabled: true,
                  });
                  setCalanderTitle(listType);
                  setIsCalanderOpen(true);
                }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const UploadScheduleList = ({ list, listType }) => {
    return (
      <div className="schedule-list-container">
        {list?.map((item) => (
          <div
            className="list-item-container"
            key={`${item?.['cbs-index']} ucs`}
          >
            <div className="list-item-data-container">
              <div className="list-item-day-container">
                {Utils.makeTheDaySelectionShort(item?.['ucs-day'])}
              </div>
              <div className="list-item-time-container">
                {minutesToTime(item?.['ucs-st'])} -&nbsp;
                {minutesToTime(item?.['ucs-et'])}{' '}
                {getTimeZoneAbbreviation(deviceTimeZone)}
              </div>
            </div>
            <div style={{ cursor: 'pointer' }}>
              <HiOutlinePencil
                fontSize={20}
                cursor={'pointer'}
                onClick={() => {
                  if (
                    connectionState?.toLowerCase() !==
                    constants.DEVICES_ONLINE_CONNECTION_STATUS.toLowerCase()
                  ) {
                    return;
                  }
                  setCalanderValue({
                    index: item?.['ucs-index'],
                    startTime: item?.['ucs-st'],
                    endTime: item?.['ucs-et'],
                    days: item?.['ucs-day'].split(','),
                    isEnabled: true,
                  });
                  setCalanderTitle(listType);
                  setIsCalanderOpen(true);
                }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const LocationListAccordion = () => {
    const handleAccordionOpen = (accordionKey) => {
      if (openAccordion?.includes(accordionKey)) {
        setOpenAccordion(openAccordion.filter((item) => item !== accordionKey));
      } else {
        setOpenAccordion([...openAccordion, accordionKey]);
      }
    };

    const handleRoomSelection = (floorName, roomLabel, checked) => {
      setLocationsList((prevLocations) => {
        return prevLocations.map((floor) => {
          if (floor.floor !== floorName) return floor;

          return {
            ...floor,
            children: floor.children.map((room) => {
              if (room.label === roomLabel) {
                return { ...room, value: checked };
              }
              return room;
            }),
          };
        });
      });
    };

    const handleFloorSelection = (floorIndex, checked) => {
      setLocationsList((prevLocations) => {
        const newLocations = [...prevLocations];
        newLocations[floorIndex] = {
          ...newLocations[floorIndex],
          children: newLocations[floorIndex].children.map((room) =>
            room.isPersonEventSupported
              ? {
                  ...room,
                  value: checked,
                }
              : room,
          ),
        };
        return newLocations;
      });
    };

    const isFloorPartiallySelected = (floor) => {
      const selectedRooms = floor.children.filter((room) => room.value).length;
      return selectedRooms > 0 && selectedRooms < floor.children.length;
    };

    const isFloorFullySelected = (floor) => {
      return floor.children.every((room) => room.value);
    };

    return (
      <div className="location-accordio-main-container">
        {filteredLocationsList.map((item, index) => (
          <div className="location-accordion">
            <div
              className="location-accordion-header"
              onClick={(event) => {
                event.preventDefault();
                handleAccordionOpen(index);
              }}
            >
              <div className="accordion-lable-arrow-container">
                {openAccordion.includes(index) ? (
                  <IoIosArrowUp size={20} className="accordion-arrow" />
                ) : (
                  <IoIosArrowDown size={20} className="accordion-arrow" />
                )}

                <div> {item?.floor}</div>
              </div>
              <FormControlLabel
                className="accordion-main-checkbox"
                control={
                  <Checkbox
                    checked={isFloorFullySelected(
                      locationsList.filter(
                        (location) => location?.floor === item?.floor,
                      )[0],
                    )}
                    indeterminate={isFloorPartiallySelected(
                      locationsList.filter(
                        (location) => location?.floor === item?.floor,
                      )[0],
                    )}
                    onChange={(event) => {
                      handleFloorSelection(index, event?.target?.checked);
                    }}
                    sx={{
                      '&.Mui-checked': {
                        color: '#DE671B',
                      },
                      '&.MuiCheckbox-indeterminate': {
                        color: '#DE671B',
                      },
                    }}
                  />
                }
                onClick={(event) => {
                  event?.stopPropagation();
                  // todo: handle floor selection
                }}
              />
            </div>
            {openAccordion.includes(index) && (
              <div className="location-accordion-body">
                {item?.children?.map((place, placeIndex) => (
                  <div className="location-option-container">
                    <div className="label-and-checkbox-holder-container">
                      <div
                        className={`location-option-label-container ${!place?.isPersonEventSupported ? 'location-option-label-container-disabled' : ''}`}
                      >
                        <BiWebcam size={20} />
                        <div className="location-checkbox-label">
                          {place?.label}
                        </div>
                      </div>
                      <Checkbox
                        disabled={!place?.isPersonEventSupported}
                        type="checkbox"
                        className="checkbox-style"
                        checked={place?.value}
                        sx={{
                          '&.Mui-checked': {
                            color: '#DE671B',
                          },
                          ...(!place?.isPersonEventSupported && {
                            svg: {
                              backgroundColor: '#656667',
                              height: '19px',
                              width: '19px',
                              borderRadius: '4px',
                              border: '1.5px solid #444648',
                            },
                            path: {
                              display: 'none',
                            },
                          }),
                        }}
                        onChange={(event) => {
                          handleRoomSelection(
                            item?.floor,
                            place?.label,
                            event?.target?.checked,
                          );
                        }}
                      />
                    </div>
                    {!place?.isPersonEventSupported && (
                      <div className="person-event-unsupported-text">
                        <HiOutlineExclamationCircle size={16} />
                        <div>
                          {
                            constants.CLOUD_BACKUP_CAMERA_DOES_NOT_SUPPORT_PERSON_EVENTS
                          }
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const cloudStrageBackupStatus = () => {
    switch (cloudStorageBackupStatus) {
      case 'in-progress':
        return (
          <div className="cloud-info-label">
            <div className="backup-status-dot green-dot"></div>
            {constants.CLOUD_BACKUP_IN_PROGRESS_BACKUP_TEXT}
          </div>
        );

      case 'completed':
        return (
          <div className="cloud-info-label">
            <div className="backup-status-dot green-dot"></div>
            {`${constants.CLOUD_BACKUP_COMPLETED_TEXT} ${formatDateTime(backupStatusDateTime * 1000, deviceTimeZone, 'long', true)}`}
          </div>
        );

      case 'failed':
        return (
          <div className="cloud-info-label">
            <div className="backup-status-dot red-dot"></div>
            {`${constants.CLOUD_BACKUP_FAILED_BACKUP_TEXT} ${formatDateTime(backupStatusDateTime * 1000, deviceTimeZone, 'long', true)}`}
          </div>
        );

      case 'idle':
        return (
          <div className="cloud-info-label">
            <div className="backup-status-dot yellow-dot"></div>
            {constants.CLOUD_BACKUP_IDLE_BACKUP_TEXT}
          </div>
        );

      case 'partial':
        return (
          <div className="cloud-info-label">
            <div className="backup-status-dot yellow-dot"></div>
            {`${constants.CLOUD_BACKUP_PARTIAL_BACKUP_TEXT} ${formatDateTime(backupStatusDateTime * 1000, deviceTimeZone, 'long', true)}`}
          </div>
        );

      default:
        return (
          <div className="cloud-info-label">
            <div className="backup-status-dot gray-dot"></div>
            {constants.CLOUD_BACKUP_UNKNOWN_STATUS_TEXT}
          </div>
        );
    }
  };

  const handleCalanderValueChange = (date, isCreate) => {
    const { days, endTime, startTime } = date;

    let cbsStatus = {
      'cbs-day': days.join(','),
      'cbs-st': Number(startTime),
      'cbs-et': Number(endTime),
    };

    let ucsStatus = {
      'ucs-day': days.join(','),
      'ucs-st': Number(startTime),
      'ucs-et': Number(endTime),
    };

    switch (calanderTitle) {
      case constants?.CLOUD_BACKUP_CREATE_A_CONTENT_BACKUP_WINDOW:
        // backup/cbs
        publishFirmwareDeviceChannalSetting(
          cbsStatus,
          resourceList[10],
          publishPayload,
          false,
          'add',
        );
        break;

      case constants?.CLOUD_BACKUP_EDIT_CONTENT_BACKUP_WINDOW:
        // backup/cbs
        publishFirmwareDeviceChannalSetting(
          {
            'cbs-index': calanderValue?.index,
            ...cbsStatus,
          },
          resourceList[10],
          publishPayload,
          false,
          'set',
        );
        break;

      case constants?.CLOUD_BACKUP_CREATE_AN_UPLOAD_SCHEDULE:
        // upload/ucs
        publishFirmwareDeviceChannalSetting(
          ucsStatus,
          resourceList[11],
          publishPayload,
          false,
          'add',
        );
        break;

      case constants?.CLOUD_BACKUP_EDIT_UPLOAD_SCHEDULE:
        // upload/ucs
        publishFirmwareDeviceChannalSetting(
          { ...ucsStatus, 'ucs-index': calanderValue?.index },
          resourceList[11],
          publishPayload,
          false,
          'set',
        );
        break;

      default:
        break;
    }
    setIsCalanderOpen(false);
    setCalanderValue(defaultCalanerValue);
  };

  const handleCalenderDelete = () => {
    switch (calanderTitle) {
      case constants?.CLOUD_BACKUP_EDIT_CONTENT_BACKUP_WINDOW:
        const cbsStatus = {
          'cbs-index': calanderValue?.index,
        };
        publishFirmwareDeviceChannalSetting(
          cbsStatus,
          resourceList[10],
          publishPayload,
          false,
          'delete',
        );
        break;

      case constants?.CLOUD_BACKUP_EDIT_UPLOAD_SCHEDULE:
        const ucsStatus = {
          'ucs-index': calanderValue?.index,
        };
        publishFirmwareDeviceChannalSetting(
          ucsStatus,
          resourceList[11],
          publishPayload,
          false,
          'delete',
        );
        break;

      default:
        break;
    }

    setIsCalanderOpen(false);
    setCalanderValue(defaultCalanerValue);
  };

  const localStorageSDCardStatus = (status) => {
    let lowerCaseStatus = status?.toLowerCase();

    switch (lowerCaseStatus) {
      case constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_RECORDING.toLowerCase():
      case constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_FORMATTING.toLowerCase():
      case constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_RECORDING_SOON.toLowerCase():
        return 'green-dot';

      case constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_WRITE_PROTECTED.toLowerCase():
      case constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_SDCARD_FULL.toLowerCase():
      case constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_PROFILE_NOT_CREATED.toLowerCase():
      case constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_MAX_STREAM_LIMIT.toLowerCase():
        return 'yellow-dot';

      case constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_ERROR.toLowerCase():
      case constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_NOT_RECORDING.toLowerCase():
        return 'red-dot';

      default:
        return 'gray-dot';
    }
  };

  return (
    <Fragment>
      <Formik
        initialValues={{
          recondOnlyEvents:
            deviceInfos?.properties?.recEventBased === 'true' ? true : false,
        }}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <div className="text-start device-network-settings-block">
              {localStoragePermissions?.['enable-backup']?.length && (
                <Fragment>
                  <div className="section-heading mb-3 mt-4">
                    <label>
                      {isEdgeRecordSupport
                        ? constants?.CHANNELS_INFO_CLOUD_ENABLED_LOCAL_STORAGE_TITLE
                        : constants.CHANNELS_INFO_LOCAL_STORAGE}
                    </label>
                  </div>
                  {sdcardDetectStatus && (
                    <div className="local-storage-message">
                      {isEdgeRecordSupport
                        ? constants?.CHANNELS_INFO_CLOUD_ENABLED_LOCAL_STORAGE_MESSAGE
                        : constants.CHANNELS_INFO_LOCAL_STORAGE_MESSAGE}
                    </div>
                  )}
                  <div className="device-info-section">
                    <div
                      className={`firmware-container-storage fm-container device-firmware-container`}
                    >
                      <div className="firmware-update storage-enable-button">
                        {(!isEdgeRecordSupport ||
                          (isEdgeRecordSupport && sdcardDetectStatus)) && (
                          <Col>
                            <ToggleInput
                              curDeviceStatus={connectionState}
                              label={
                                isEdgeRecordSupport
                                  ? t(
                                      'CLOUD_BACKUP_LOCAL_STORAGE_EDGE_RECORDING_TITLE',
                                    )
                                  : t('CHANNELS_INFO_LOCAL_STORAGE_TITLE')
                              }
                              classes={'p-0 m-t-0'}
                              name="fw-updt-dev-type"
                              value={localStorageDeviceEnable}
                              labelColor={true}
                              changeHandler={(e) => {
                                if (!localStorageDeviceEnable) {
                                  setStorageEnableModal(true);
                                } else {
                                  const status = {
                                    storageBackup: false,
                                    avoidReload: true,
                                  };
                                  updateStore(status);
                                  if (showRecordEvent) {
                                    publishFirmwareDeviceChannalSetting(
                                      status,
                                      resourceList[6],
                                      publishPayload,
                                    );
                                  } else {
                                    publishFirmwareDeviceSetting(
                                      status,
                                      resourceList[6],
                                      publishPayload,
                                    );
                                  }
                                }
                              }}
                              disabledClass={
                                (sdCardStatus &&
                                  sdCardStatus ===
                                    constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_FORMATTING) ||
                                deviceInfos?.connectionStatus !==
                                  constants.DEVICES_ONLINE_CONNECTION_STATUS ||
                                !canChangeSettings
                                  ? 'disabled-check-box'
                                  : ''
                              }
                              disabled={
                                (sdCardStatus &&
                                  sdCardStatus ===
                                    constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_FORMATTING) ||
                                deviceInfos?.connectionStatus !==
                                  constants.DEVICES_ONLINE_CONNECTION_STATUS ||
                                !canChangeSettings
                              }
                            />
                          </Col>
                        )}
                        {isEdgeRecordSupport && !sdcardDetectStatus && (
                          <div className="sd-card-unavailable-slot-container">
                            <div>
                              {constants.CHANNELS_INFO_LOCAL_STORAGE_SLOT_TITLE}
                            </div>
                            <div className="sd-card-unavailable">
                              <ErrorOutlineIcon
                                sx={{ fontSize: 20, color: '#d32f2f' }}
                              />
                              {
                                constants.CHANNELS_INFO_LOCAL_STORAGE_SD_CARD_UNAVAILABLE
                              }
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
              {((!isEdgeRecordSupport &&
                showLocalStorageUI &&
                (!isHubSettings ||
                  connectionState?.toLowerCase() === 'online')) ||
                (isEdgeRecordSupport && sdcardDetectStatus)) && (
                <div className="device-info-section mt-3">
                  <div className="storage-header-container">
                    <div className="storage-header">
                      {constants.CHANNELS_INFO_LOCAL_STORAGE_SLOT_TITLE}
                    </div>
                    {Boolean(localStoragePermissions?.['format-drive']) && (
                      <div className="storage-subheader">
                        {sdcardDetectStatus ? (
                          <CiCircleCheck
                            size={15}
                            className="sd-card-status-icons"
                            color={getComputedStyle(
                              document.documentElement,
                            ).getPropertyValue('--success_48')}
                          />
                        ) : (
                          <CiCircleRemove
                            size={15}
                            className="sd-card-status-icons"
                            color={getComputedStyle(
                              document.documentElement,
                            ).getPropertyValue('--error_64')}
                          />
                        )}
                        {sdcardDetectStatus
                          ? constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_INSERTED
                          : constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_NOT_INSERTED}
                      </div>
                    )}
                  </div>
                  {isEdgeRecordSupport &&
                    Boolean(retentionDaysFromEntitlement) &&
                    (deviceInfos?.properties?.sdcardDetectStatus === 'true' ||
                      deviceInfos?.properties?.sdcardDetectStatus === true) &&
                    requiredStorageSizeInMB > Number(totalSizeMB) && (
                      <div className="local-storage-error-container">
                        <Alert
                          severity="error"
                          sx={{ borderRadius: '12px', mt: '12px' }}
                        >
                          {constants.CHANNELS_INFO_LOCAL_STORAGE_CAPACITY_ERROR_MESSAGE.replace(
                            /{retentionDaysFromEntitlement}/g,
                            retentionDaysFromEntitlement,
                          ).replace(
                            '{requiredStorage}',
                            bandWidthConverter(requiredStorageSizeInMB),
                          )}
                        </Alert>
                      </div>
                    )}
                  <div className="storage-info-container">
                    <div className="w-100">
                      <div className="storage-text">
                        {constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS}
                      </div>
                      <div className="storage-header-container p-0">
                        <div className="storage-subheader">
                          <Fragment>
                            <div
                              className={`backup-status-dot ${localStorageSDCardStatus(sdCardStatus)}`}
                            ></div>
                            &nbsp;
                            {sdCardStatus
                              ? sdCardStatus
                              : constants.CHANNELS_INFO_LOCAL_STORAGE_NO_STATUS}
                          </Fragment>
                        </div>
                        {Boolean(localStoragePermissions?.['format-drive']) ? (
                          <div
                            className={
                              sdcardDetectStatus &&
                              sdCardStatus !==
                                constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_FORMATTING &&
                              deviceInfos?.connectionStatus ===
                                constants.DEVICES_ONLINE_CONNECTION_STATUS
                                ? 'format-card-eabled'
                                : 'format-card-disabled'
                            }
                            onClick={() => {
                              if (
                                !sdcardDetectStatus ||
                                sdCardStatus ===
                                  constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_FORMATTING ||
                                !canChangeSettings
                              ) {
                                return;
                              }
                              setFormatCardModal(true);
                            }}
                          >
                            {isEdgeRecordSupport
                              ? constants.CHANNELS_INFO_LOCAL_STORAGE_FORMAT_TITLE_FOR_EDGE
                              : constants?.CHANNELS_INFO_LOCAL_STORAGE_FORMAT_TITLE}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {!isEdgeRecordSupport && (
                      <Fragment>
                        {Boolean(localStoragePermissions?.['format-drive']) && (
                          <>
                            <div className="w-100">
                              <div className="storage-text">
                                {constants?.CLOUD_BACKUP_AVAIABLE_STORAGE}
                              </div>
                              <div className="d-flex">
                                <span>{bandWidthConverter(freeSizeMB)}</span>
                                &nbsp;{` / `}&nbsp;
                                <span className="storage-subheader">
                                  {bandWidthConverter(freeSizeMB)}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                        <PrimaryButton
                          className="btn btn-primary-outline"
                          type="button"
                          width="100%"
                          height="56px"
                          fontSize="1.125rem"
                          lineHeight="24px"
                          backgroundColor="transparent"
                          hoverBackgroundColor={getComputedStyle(
                            document.documentElement,
                          ).getPropertyValue('--primary_40')}
                          borderColor={getComputedStyle(
                            document.documentElement,
                          ).getPropertyValue('--primary_40')}
                          hoverBorderColor={getComputedStyle(
                            document.documentElement,
                          ).getPropertyValue('--primary_40')}
                          borderWidth="1px"
                          color={getComputedStyle(
                            document.documentElement,
                          ).getPropertyValue('--primary_40')}
                          hoverColor={getComputedStyle(
                            document.documentElement,
                          ).getPropertyValue('--brand_white')}
                          onClick={() => setRestorationModal(true)}
                        >
                          {constants.CHANNELS_INFO_LOCAL_STORAGE_RESTORATION}
                        </PrimaryButton>
                      </Fragment>
                    )}
                  </div>
                </div>
              )}
              {showRecordEvent && (
                <div className="cloud-storage-recording-profile mt-4">
                  <div className="cloud-storage-text-holder">
                    <div className="section-heading">
                      {isEdgeRecordSupport
                        ? constants?.CHANNEL_CLOUD_STORAGE_RECORDING_PROFILE_FOR_EDGE
                        : constants.CHANNEL_CLOUD_STORAGE_RECORDING_PROFILE}
                    </div>
                    <div
                      className="cloude-storage-text"
                      onClick={() => {
                        switchToVideoTab();
                      }}
                    >
                      {
                        constants.CHANNEL_CLOUD_STORAGE_RECORDING_PROFILE_SETTINGS
                      }
                    </div>
                  </div>
                  <div className="device-info-section m-t-16">
                    <div>
                      <div
                        className={`space-btn-text ${canChangeSettings ? 'codec-selection' : 'codec-selection-offline'}`}
                      >
                        <div className="inside-title">
                          <div>
                            {constants.CHANNEL_CLOUD_STORAGE_RECORDING247}
                          </div>
                          {localStoragePermissions?.['enable-cloud-backup'] && (
                            <div className="cloud-option-secondary-label">
                              {constants.CHANNELS_INFO_FULL_VIDEO_RECORDING}
                            </div>
                          )}
                        </div>
                        <Field
                          type="radio"
                          name="recondOnlyEvents247"
                          checked={!recondOnlyEvents}
                          onClick={() => {
                            setRecondOnlyEvents(false);

                            const status = getCheckPropertyStatus(
                              false,
                              'recEventBased',
                            );
                            const property = {
                              recEventBased: 'false',
                            };
                            updateStore(property);
                            publishFirmwareDeviceChannalSetting(
                              status,
                              resourceListRecordEvent[0],
                              publishPayload,
                            );
                          }}
                          disabled={!canChangeSettings}
                        />
                      </div>
                      {deviceInfos?.properties?.recEventBased !== undefined &&
                        !localStoragePermissions?.['enable-cloud-backup']
                          ?.length && (
                          <div
                            className={`space-btn-text ${canChangeSettings ? 'codec-selection' : 'codec-selection-offline'}`}
                          >
                            <div className="inside-title">
                              {
                                constants.CHANNEL_CLOUD_STORAGE_RECORD_ONLY_EVENT
                              }
                            </div>
                            <Field
                              type="radio"
                              name="recondOnlyEvents"
                              checked={recondOnlyEvents === true}
                              onClick={() => {
                                setRecondOnlyEvents(true);
                                const status = getCheckPropertyStatus(
                                  true,
                                  'recEventBased',
                                );
                                const property = {
                                  recEventBased: 'true',
                                };
                                updateStore(property);
                                publishFirmwareDeviceChannalSetting(
                                  status,
                                  resourceListRecordEvent[0],
                                  publishPayload,
                                );
                              }}
                              disabled={!canChangeSettings}
                            />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              )}
              {/* Enable Cloud Storage section starts */}
              {localStorageDeviceEnable &&
                sdcardDetectStatus &&
                localStoragePermissions?.['enable-cloud-backup']?.length && (
                  <div className="mt-4 cloud-storage">
                    <div>
                      <div className="section-heading mb-2">
                        {constants.CLOUD_BACKUP_MAIN_TITLE}
                      </div>
                      <div className="local-storage-message">
                        {constants.CLOUD_BACKUP_SHORT_INFO}
                      </div>
                    </div>
                    <div className="cloud-storage-section">
                      <div className="firmware-update storage-enable-button">
                        <Col>
                          <ToggleInput
                            curDeviceStatus={connectionState}
                            label={t(
                              'CHANNELS_INFO_CLOUD_BACKUP_STORAGE_TITLE',
                            )}
                            classes={'p-0 m-t-0'}
                            name="cloud-storage-enable"
                            value={isCloudStorageEnabled}
                            labelColor={true}
                            changeHandler={() => {
                              !isCloudStorageEnabled &&
                                getCloudArchiveAvailbility();
                              const status = {
                                'enable-cloud-backup': !isCloudStorageEnabled,
                              };
                              publishFirmwareDeviceChannalSetting(
                                status,
                                resourceList[6],
                                publishPayload,
                              );
                              setIsCloudStorageEnabled(!isCloudStorageEnabled);
                            }}
                            disabled={
                              connectionState?.toLowerCase() !== 'online' ||
                              !sdcardDetectStatus
                            }
                            disabledClass={
                              connectionState?.toLowerCase() !== 'online' ||
                              !sdcardDetectStatus
                                ? 'disabled-check-box'
                                : ''
                            }
                          />
                        </Col>
                      </div>
                      {isCloudStorageEnabled && (
                        <Fragment>
                          <div className="cloud-info-container">
                            <div className="cloud-storage-info-label">
                              {constants.CLOUD_BACKUP_BACKUP_STATUS}
                            </div>
                            {cloudStrageBackupStatus()}
                          </div>
                          {isBackupButtonVisible && (
                            <PrimaryButton
                              className="btn btn-primary"
                              type="submit"
                              height="2.75rem"
                              fontSize="0.875rem"
                              width="8.75rem"
                              onClick={() => {
                                setIsBackupConfirmOpen(true);
                              }}
                            >
                              {constants.CLOUD_BACKUP_BACKUP_NOW}
                            </PrimaryButton>
                          )}
                          <div className="cloud-info-container">
                            <div className="cloud-storage-info-label">
                              {constants.CLOUD_BACKUP_CLOUD_ARCHIVE_ABILITY}
                            </div>
                            <div className="cloud-info">
                              <div className="cloud-info-label">
                                {constants.CLOUD_BACKUP_MOST_RECENT_VIDEO}
                              </div>
                              <div className="cloud-info-label">
                                {cloudArchiveAvailability?.recentVideo?.toString()}
                              </div>
                            </div>
                            <div className="cloud-info">
                              <div className="cloud-info-label">
                                {constants.CLOUD_BACKUP_OLDEST_VIDEO}
                              </div>
                              <div className="cloud-info-label">
                                {cloudArchiveAvailability?.oldestVideo?.toString()}
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      )}
                    </div>
                    {isCloudStorageEnabled && (
                      <Fragment>
                        {(localStoragePermissions?.['content-backup-sch']
                          ?.length ||
                          localStoragePermissions?.['backup-type']?.length) && (
                          <div className="cloud-storage-section">
                            <div className="cloud-info-label">
                              {constants.CLOUD_BACKUP_BACKUP_CONTENT}
                            </div>
                            <div className="cloud-backup-window-container">
                              {localStoragePermissions?.['backup-type']
                                ?.length && (
                                <Fragment>
                                  <div className="cloud-storage-info-label">
                                    {constants.CLOUD_BACKUP_TYPE_TEXT}
                                  </div>
                                  <div className="cloud-option-holder-container">
                                    {localStoragePermissions?.[
                                      'backup-type'
                                    ]?.includes('continuous') && (
                                      <div className="cloud-option-container">
                                        <div className="cloud-options-label-container">
                                          <div className="cloud-option-main-label">
                                            {
                                              constants.CLOUD_BACKUP_BACKUP_247_RECORDING
                                            }
                                          </div>
                                          <div className="cloud-option-secondary-label">
                                            {
                                              constants.CLOUD_BACKUP_BACKUP_FULL_VIDEO_RECORING
                                            }
                                          </div>
                                        </div>
                                        <div
                                          className={`space-btn-text ${canChangeSettings ? 'codec-selection' : 'codec-selection-offline'}`}
                                        >
                                          <Field
                                            type="radio"
                                            checked={
                                              backupContentType === 'continuous'
                                            }
                                            onClick={() => {
                                              handleBackupContentTypeClick(
                                                'continuous',
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {localStoragePermissions?.[
                                      'backup-type'
                                    ]?.includes('event') && (
                                      <div className="cloud-option-container">
                                        <div className="cloud-options-label-container">
                                          <div className="cloud-option-main-label">
                                            {
                                              constants.CLOUD_BACKUP_BACKUP_MOTION_BASED_RECORDING
                                            }
                                          </div>
                                          <div className="cloud-option-secondary-label">
                                            {
                                              constants.CLOUD_BACKUP_ONLT_MOTION_BASED_RECORDING
                                            }
                                          </div>
                                        </div>
                                        <div
                                          className={`space-btn-text ${canChangeSettings ? 'codec-selection' : 'codec-selection-offline'}`}
                                        >
                                          <Field
                                            type="radio"
                                            checked={
                                              backupContentType === 'event'
                                            }
                                            onClick={() => {
                                              handleBackupContentTypeClick(
                                                'event',
                                              );
                                            }}
                                            name="recondOnlyEvents"
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </Fragment>
                              )}
                              {localStoragePermissions?.[
                                'content-backup-sch'
                              ] && (
                                <Fragment>
                                  <div>
                                    <div className="cloud-storage-info-label">
                                      {constants.CLOUD_BACKUP_WINDOW}
                                    </div>
                                    <div className="cloud-storage-tip">
                                      {
                                        constants.CLOUD_BACKUP_SETUP_THREE_WINDOW
                                      }
                                    </div>
                                  </div>
                                  <BackupContentList
                                    list={backupWindowList}
                                    listType={
                                      constants.CLOUD_BACKUP_EDIT_CONTENT_BACKUP_WINDOW
                                    }
                                  />

                                  {
                                    // todo : uncomment below line and remove false once backend is ready
                                    // backupWindowList?.length > 0
                                    false && (
                                      <PrimaryButton
                                        className="btn btn-primary"
                                        type="button"
                                        width="262px"
                                        height="44px"
                                        fontSize="0.875rem"
                                        lineHeight="1.25rem"
                                        backgroundColor="transparent"
                                        hoverBackgroundColor={getComputedStyle(
                                          document.documentElement,
                                        ).getPropertyValue('--primary_96')}
                                        borderColor={getComputedStyle(
                                          document.documentElement,
                                        ).getPropertyValue('--primary_40')}
                                        hoverBorderColor={getComputedStyle(
                                          document.documentElement,
                                        ).getPropertyValue('--primary_40')}
                                        borderWidth="1.5px"
                                        hoverBorderWidth="1.5px"
                                        color={getComputedStyle(
                                          document.documentElement,
                                        ).getPropertyValue('--primary_40')}
                                        hoverColor={getComputedStyle(
                                          document.documentElement,
                                        ).getPropertyValue('--primary_40')}
                                        onClick={() => {
                                          setIsApplyToOtherIsOpen(true);
                                        }}
                                      >
                                        {
                                          constants.CLOUD_BACKUP_APPLY_TO_OTHER_DEVICES
                                        }
                                      </PrimaryButton>
                                    )
                                  }
                                  {backupWindowList?.length < 3 && (
                                    <div
                                      className="content-backup-window"
                                      onClick={() => {
                                        if (
                                          connectionState?.toLowerCase() !==
                                          constants.DEVICES_ONLINE_CONNECTION_STATUS.toLowerCase()
                                        ) {
                                          return;
                                        }
                                        setCalanderTitle(
                                          constants.CLOUD_BACKUP_CREATE_A_CONTENT_BACKUP_WINDOW,
                                        );
                                        setCalanderValue(defaultCalanerValue);
                                        setIsCalanderOpen(true);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faPlus}
                                        fontSize={16}
                                        color={
                                          connectionState?.toLowerCase() !==
                                          constants.DEVICES_ONLINE_CONNECTION_STATUS.toLowerCase()
                                            ? 'gray'
                                            : '#DE671B'
                                        }
                                      />
                                      <div className="content-backup-window-text">
                                        {
                                          constants.CLOUD_BACKUP_CREATE_A_CONTENT_BACKUP_WINDOW
                                        }
                                      </div>
                                    </div>
                                  )}
                                </Fragment>
                              )}
                            </div>
                          </div>
                        )}
                        {Boolean(
                          localStoragePermissions?.['upload-content-sch'],
                        ) && (
                          <div className="cloud-storage-section">
                            <div>
                              <div className="cloud-info-label">
                                {constants.CLOUD_BACKUP_UPLOAD_SCHEDULE}
                              </div>
                              <div className="cloud-storage-tip">
                                {constants.CLOUD_BACKUP_SETUP_UPLOAD_SCHEDULE}
                              </div>
                            </div>
                            <UploadScheduleList
                              list={uploadScheduleList}
                              listType={
                                constants.CLOUD_BACKUP_EDIT_UPLOAD_SCHEDULE
                              }
                            />
                            {
                              // todo : uncomment below line and remove false once backend is ready
                              // uploadScheduleList?.length > 0
                              false && (
                                <PrimaryButton
                                  className="btn btn-primary"
                                  type="button"
                                  width="262px"
                                  height="44px"
                                  fontSize="0.875rem"
                                  lineHeight="1.25rem"
                                  backgroundColor="transparent"
                                  hoverBackgroundColor={getComputedStyle(
                                    document.documentElement,
                                  ).getPropertyValue('--primary_96')}
                                  borderColor={getComputedStyle(
                                    document.documentElement,
                                  ).getPropertyValue('--primary_40')}
                                  hoverBorderColor={getComputedStyle(
                                    document.documentElement,
                                  ).getPropertyValue('--primary_40')}
                                  borderWidth="1.5px"
                                  hoverBorderWidth="1.5px"
                                  color={getComputedStyle(
                                    document.documentElement,
                                  ).getPropertyValue('--primary_40')}
                                  hoverColor={getComputedStyle(
                                    document.documentElement,
                                  ).getPropertyValue('--primary_40')}
                                  onClick={() => {
                                    setIsApplyToOtherIsOpen(true);
                                  }}
                                >
                                  {
                                    constants.CLOUD_BACKUP_APPLY_TO_OTHER_DEVICES
                                  }
                                </PrimaryButton>
                              )
                            }
                            {uploadScheduleList?.length < 3 && (
                              <div
                                className="content-backup-window mt-0"
                                onClick={() => {
                                  if (
                                    connectionState?.toLowerCase() !==
                                    constants.DEVICES_ONLINE_CONNECTION_STATUS.toLowerCase()
                                  ) {
                                    return;
                                  }
                                  setCalanderTitle(
                                    constants.CLOUD_BACKUP_CREATE_AN_UPLOAD_SCHEDULE,
                                  );
                                  setCalanderValue({
                                    ...defaultCalanerValue,
                                    endTime: 240,
                                  });
                                  setIsCalanderOpen(true);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faPlus}
                                  fontSize={16}
                                  color={
                                    connectionState?.toLowerCase() !==
                                    constants.DEVICES_ONLINE_CONNECTION_STATUS.toLowerCase()
                                      ? 'gray'
                                      : '#DE671B'
                                  }
                                />
                                <div className="content-backup-window-text">
                                  {
                                    constants.CLOUD_BACKUP_CREATE_AN_UPLOAD_SCHEDULE
                                  }
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </Fragment>
                    )}
                  </div>
                )}
              {/* Enable Cloud Storage section ends */}
              {/* Enable local storage - modal  */}
              <SiteModal
                modalTitle={
                  'Are you sure you want to enable SD card recording for this device?'
                }
                showModal={storageEnableModal}
                hideModal={() => {
                  setStorageEnableModal(false);
                }}
                classes="localstorage-setup"
              >
                <TextBlock
                  className="mb-3"
                  fontWeight="400"
                  fontSize="1rem"
                  lineHeight="24px"
                  color={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--greyscale_56')}
                >
                  {constants.CHANNELS_INFO_LOCAL_STORAGE_ENABLE_TITLE}
                </TextBlock>
                <PrimaryButton
                  className="btn btn-primary-outline mb-3"
                  type="button"
                  width="100%"
                  borderWidth="1.5px"
                  height="56px"
                  fontSize="1.125rem"
                  lineHeight="24px"
                  onClick={(e) => {
                    setStorageEnableModal(false);
                    const status = {
                      storageBackup: true,
                      avoidReload: true,
                    };
                    updateStore(status);
                    if (showRecordEvent) {
                      publishFirmwareDeviceChannalSetting(
                        status,
                        resourceList[6],
                        publishPayload,
                      );
                    } else {
                      publishFirmwareDeviceSetting(
                        status,
                        resourceList[6],
                        publishPayload,
                      );
                    }
                    setTimeout(() => {
                      getStorageStatusMqttCall();
                    }, 3000);
                  }}
                >
                  {constants.CHANNELS_INFO_LOCAL_STORAGE_ENABLE_CONFIRM}
                </PrimaryButton>
                <PrimaryButton
                  className="btn btn-primary"
                  type="button"
                  width="100%"
                  height="56px"
                  fontSize="1.125rem"
                  lineHeight="24px"
                  backgroundColor="transparent"
                  hoverBackgroundColor={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--primary_96')}
                  borderColor={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--primary_40')}
                  hoverBorderColor={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--primary_40')}
                  borderWidth="1.5px"
                  hoverBorderWidth="1.5px"
                  color={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--primary_40')}
                  hoverColor={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--primary_40')}
                  onClick={() => {
                    setStorageEnableModal(false);
                  }}
                >
                  {constants.CHANNELS_INFO_LOCAL_STORAGE_ENABLE_CANCEL}
                </PrimaryButton>
              </SiteModal>
              {/* Restoration - modal  */}
              <SiteModal
                showModal={restorationModal}
                hideModal={() => {
                  setRestorationModal(false);
                }}
                classes="restortion-maodal"
                size="xl"
                modalTitle={constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY}
              >
                <RestoredDevicesTable
                  showRecordEvent={showRecordEvent}
                  deviceInfos={deviceInfos}
                />
              </SiteModal>
              {/* Format SD card local storage - modal  */}
              <SiteModal
                modalTitle={
                  constants.CHANNELS_INFO_LOCAL_STORAGE_FORMAT_MESSAGE
                }
                showModal={formatCardModal}
                hideModal={() => {
                  setFormatCardModal(false);
                }}
                classes="localstorage-setup"
              >
                <TextBlock
                  className="mb-3"
                  fontWeight="400"
                  fontSize="1rem"
                  lineHeight="24px"
                  color={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--greyscale_56')}
                >
                  {constants.CHANNELS_INFO_LOCAL_STORAGE_FORMAT_SUBMESSAGE}
                </TextBlock>
                <PrimaryButton
                  className="btn btn-primary"
                  type="button"
                  width="100%"
                  height="56px"
                  fontSize="1.125rem"
                  lineHeight="24px"
                  backgroundColor="transparent"
                  hoverBackgroundColor={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--primary_96')}
                  borderColor={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--primary_40')}
                  hoverBorderColor={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--primary_40')}
                  borderWidth="1.5px"
                  hoverBorderWidth="1.5px"
                  color={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--primary_40')}
                  hoverColor={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--primary_40')}
                  onClick={() => {
                    setFormatCardModal(false);
                  }}
                >
                  {constants.CHANNELS_INFO_LOCAL_STORAGE_FORMAT_CANCEL}
                </PrimaryButton>
                <PrimaryButton
                  className="btn btn-primary-outline mt-3"
                  type="button"
                  width="100%"
                  borderWidth="1.5px"
                  height="56px"
                  fontSize="1.125rem"
                  lineHeight="24px"
                  backgroundColor={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--error_64')}
                  hoverBackgroundColor={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--error_64')}
                  color={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--brand_white')}
                  hoverColor={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--brand_white')}
                  borderColor={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--error_64')}
                  hoverBorderColor={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--error_64')}
                  onClick={(e) => {
                    setFormatCardModal(false);
                    if (
                      connectionState?.toLowerCase() !==
                      constants.DEVICES_ONLINE_CONNECTION_STATUS.toLowerCase()
                    ) {
                      return;
                    }
                    const status = {};
                    if (showRecordEvent) {
                      publishFirmwareDeviceChannalSetting(
                        status,
                        resourceList[7],
                        publishPayload,
                      );
                    } else {
                      publishFirmwareDeviceSetting(
                        status,
                        resourceList[7],
                        publishPayload,
                      );
                    }
                  }}
                >
                  {constants.CHANNELS_INFO_LOCAL_STORAGE_FORMAT_CONFIRM}
                </PrimaryButton>
              </SiteModal>
              <SiteModal
                modalTitle="Backup Now"
                showModal={isBackupConfirmOpen}
                hideModal={() => {
                  setIsBackupConfirmOpen(false);
                }}
              >
                <TextBlock
                  className="mb-3 mt-3"
                  fontWeight="400"
                  fontSize="1rem"
                  lineHeight="24px"
                  color={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--greyscale_56')}
                >
                  {
                    constants.CLOUD_BACKUP_CONFIRMATION_POPUP_ONCE_STARTED_BACKUP_WILL_START
                  }
                </TextBlock>
                <div className="backup-now-button-container">
                  <PrimaryButton
                    className="btn btn-primary"
                    type="submit"
                    height="44px"
                    fontSize="0.875rem"
                    width="auto"
                    onClick={() => {
                      publishFirmwareDeviceChannalSetting(
                        {},
                        resourceList[9],
                        publishPayload,
                      );
                      setIsBackupConfirmOpen(false);
                    }}
                  >
                    {constants.CLOUD_BACKUP_CONFIRMATION_POPUP_CONTINUE_BUTTON}
                  </PrimaryButton>
                  <PrimaryButton
                    className="btn btn-primary-outline"
                    type="button"
                    width="auto"
                    borderWidth="1.5px"
                    hoverBorderWidth="1.5px"
                    hoverBorderColor={getComputedStyle(
                      document.documentElement,
                    ).getPropertyValue('--primary_40')}
                    color={getComputedStyle(
                      document.documentElement,
                    ).getPropertyValue('--primary_40')}
                    hoverColor={getComputedStyle(
                      document.documentElement,
                    ).getPropertyValue('--brand_white')}
                    hoverBackgroundColor={getComputedStyle(
                      document.documentElement,
                    ).getPropertyValue('--primary_40')}
                    backgroundColor="transparent"
                    onClick={() => {
                      setIsBackupConfirmOpen(false);
                    }}
                  >
                    {constants.CLOUD_BACKUP_CONFIRMATION_POPUP_CANCEL_BUTTON}
                  </PrimaryButton>
                </div>
              </SiteModal>
              <SiteModal
                showModal={isCalanderOpen}
                modalTitle={calanderTitle}
                hideModal={() => {
                  setCalanderValue(defaultCalanerValue);
                  setIsCalanderOpen(false);
                }}
              >
                <BackupCalendar
                  onSelectItemChange={handleCalanderValueChange}
                  selectedItem={calanderValue}
                  calanderPurpose={calanderTitle}
                  handleDelete={handleCalenderDelete}
                  deviceTimeZone={
                    deviceTimeZone ||
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                  }
                />
              </SiteModal>
              <SiteModal
                showModal={isApplyToOtherIsOpen}
                modalTitle={constants.CLOUD_BACKUP_APPLY_TO_OTHER_DEVICES}
                hideModal={() => {
                  setIsApplyToOtherIsOpen(false);
                }}
              >
                <div className="schedule-other-device-main-container">
                  <div className="schedule-other-device-sub-container">
                    <div className="section-heading">
                      {constants.CLOUD_BACKUP_SCHEDULE}
                    </div>
                    <div className="schedule-data-container">
                      {/* todo */}
                      <div>Mon, Wed, Fri, 10:00 PM – 04:00 AM PST</div>
                      <div>Tue, Thu, 10:00 PM – 08:00 AM PST</div>
                      <div>Sat, Sun, 10:00 PM – 08:00 AM PST</div>
                      {/* todo */}
                    </div>
                  </div>
                  <div className="schedule-other-device-sub-container">
                    <div className="section-heading">
                      {constants.CLOUD_BACKUP_DEVICES}
                    </div>
                    <div>
                      {
                        constants.CLOUD_BACKUP_SELECTE_ONE_OR_MORE_DEVICES_TO_APPLY_THE_ABOVE_SCHEDULE
                      }
                    </div>
                  </div>
                  <div className="schedule-other-device-sub-container">
                    <div className="section-heading">
                      {constants.CLOUD_BACKUP_LOCATION_TEXT}
                    </div>
                    <div>
                      <CiLocationOn size={16} className="input-icon" />
                      <input
                        type="text"
                        className="location-input-box"
                        value={'San Diago 1'}
                      />
                    </div>
                  </div>
                  <div className="schedule-other-device-sub-container">
                    <div className="section-heading">
                      {constants.CLOUD_BACKUP_ADD_DEVICES}
                    </div>
                    <div>
                      <div className="location-selection-main-container">
                        <div className="search-location-container">
                          <IoIosSearch size={16} className="input-icon" />
                          <input
                            type="text"
                            className="search-location-input"
                            placeholder={
                              constants.CLOUD_BACKUP_LOCATION_SEARCH_INPUT_PLACE_HOLDER
                            }
                            onChange={(event) => {
                              setLocationSearchParam(event?.target?.value);
                            }}
                          />
                          <PrimaryButton
                            className="btn btn-primary-outline"
                            type="button"
                            width="166px"
                            borderWidth="1.5px"
                            hoverBorderWidth="1.5px"
                            hoverBorderColor={getComputedStyle(
                              document.documentElement,
                            ).getPropertyValue('--primary_40')}
                            color={getComputedStyle(
                              document.documentElement,
                            ).getPropertyValue('--primary_40')}
                            hoverColor={getComputedStyle(
                              document.documentElement,
                            ).getPropertyValue('--brand_white')}
                            hoverBackgroundColor={getComputedStyle(
                              document.documentElement,
                            ).getPropertyValue('--primary_40')}
                            backgroundColor="transparent"
                            onClick={handleRemoveAllButtonClick}
                          >
                            {constants.CLOUD_BACKUP_REMOVE_ALL_BUTTON}
                          </PrimaryButton>
                        </div>
                        <LocationListAccordion />
                      </div>
                      <div className="selected-devices-count-container">
                        <div className="selected-devices-count">
                          {/* todo */}
                          Selected Devices (37)
                        </div>
                        <IoIosArrowDown size={20} className="accordion-arrow" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="backup-now-button-container">
                  <PrimaryButton
                    className="btn btn-primary"
                    type="submit"
                    height="44px"
                    fontSize="0.875rem"
                    width="auto"
                  >
                    {constants.CLOUD_BACKUP_APPLY_BUTTON}
                  </PrimaryButton>
                  <PrimaryButton
                    className="btn btn-primary-outline"
                    type="button"
                    width="auto"
                    borderWidth="1.5px"
                    hoverBorderWidth="1.5px"
                    hoverBorderColor={getComputedStyle(
                      document.documentElement,
                    ).getPropertyValue('--primary_40')}
                    color={getComputedStyle(
                      document.documentElement,
                    ).getPropertyValue('--primary_40')}
                    hoverColor={getComputedStyle(
                      document.documentElement,
                    ).getPropertyValue('--brand_white')}
                    hoverBackgroundColor={getComputedStyle(
                      document.documentElement,
                    ).getPropertyValue('--primary_40')}
                    backgroundColor="transparent"
                    onClick={() => {
                      setIsApplyToOtherIsOpen(false);
                    }}
                  >
                    {constants.CLOUD_BACKUP_CONFIRMATION_POPUP_CANCEL_BUTTON}
                  </PrimaryButton>
                </div>
              </SiteModal>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default LocalStorageBackup;
