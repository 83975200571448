import { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { HiOutlineChevronDown, HiOutlineLocationMarker } from 'react-icons/hi';
import { isObject } from 'lodash';
import { useCustomerOrgLocations } from '../../../store/CustomerOrgLocationsStore';
import { AppDefaults, constants } from '../../../helpers';
import '../listDevice/DiscoveredDevices.scss';

const LocationAreaDeviceList = ({
  onToggle,
  locations,
  selectedLocationId,
  sendSelectedLocationId,
}) => {
  const [updatedLocations, setUpdatedLocations] = useState([]);
  const defalutLocName = locations?.find(
    (item) => item?.locationId === selectedLocationId,
  )?.locationName;
  const [selectedLocation, setSelectedLocation] = useState(defalutLocName);
  const { getCustomerOrgLocationsData } = useCustomerOrgLocations();
  const custOrgLocations = getCustomerOrgLocationsData();

  useEffect(() => {
    if (isObject(custOrgLocations) && custOrgLocations?.locationName) {
      setSelectedLocation(custOrgLocations?.locationName);
    }
    filterAreasByLocation(
      custOrgLocations?.locationId
        ? custOrgLocations?.locationId
        : AppDefaults.ALL_LOCATIONS_ID,
    );
  }, [locations]);

  const areAllLocationIdsSame = (areas) => {
    if (!areas || areas.length === 0) return false;
    const firstLocationId = areas[0].locationId;
    return areas.every((area) => area.locationId === firstLocationId);
  };

  const filterAreasByLocation = (locationId, selectedArea, singleArea) => {
    if (!locationId) return;
    if (locationId !== AppDefaults.ALL_LOCATIONS_ID) {
      if (selectedArea?.length > 0) {
        if (selectedArea?.length > 1 && areAllLocationIdsSame(selectedArea)) {
          setUpdatedLocations([]);
          const selectedLocationData = getLocationData(
            selectedArea[0]?.locationId,
          );
          setSelectedLocation(selectedLocationData?.locationName);
          const data = [];
          data.push(selectedArea[0]?.locationId);
          sendSelectedLocationId(data);
        } else {
          setSelectedLocation(defalutLocName);
          const updatedLocationsData = locations?.filter((item) =>
            selectedArea
              ?.map((item) => item.locationId)
              ?.includes(item.locationId),
          );
          if (updatedLocationsData?.length > 0) {
            setUpdatedLocations(updatedLocationsData);
          }
          sendSelectedLocationId(selectedArea.map((item) => item.locationId));
        }
      } else {
        setUpdatedLocations([]);
        const selectedLocationData = getLocationData(locationId);
        setSelectedLocation(selectedLocationData?.locationName);

        if (selectedArea === 0) {
          const selectedAreaId = selectedLocationData?.areas
            ?.filter((item) => item.areaId === singleArea[0]?.areaId)
            ?.map((item) => item.areaId);
        } else {
          const data = [];
          data.push(locationId);
          sendSelectedLocationId(data);
        }
      }
    } else {
      setUpdatedLocations([]);
      sendSelectedLocationId([]);
      setSelectedLocation(defalutLocName);
    }
  };

  const onSelectLocationHandler = (evtKey) => {
    if (!evtKey) return;
    filterAreasByLocation(evtKey);
  };

  const getLocationData = (locationId) => {
    if (!locationId) return;

    if (locationId === AppDefaults.ALL_LOCATIONS_ID) {
      return defalutLocName;
    }
    return locations.find((location) => location.locationId === locationId);
  };

  const locationDropdownContent =
    updatedLocations &&
    locations &&
    (updatedLocations?.length > 0
      ? updatedLocations
      : locations?.length > 0) ? (
      updatedLocations?.length > 0 ? (
        updatedLocations
      ) : (
        locations
          ?.slice()
          .sort((a, b) => a.locationName.localeCompare(b.locationName))
          .map((location) => (
            <Dropdown.Item
              key={`location-${location.locationId}`}
              className={
                `area-selector-menu-item` +
                (selectedLocation === location.locationName ? ' active' : '')
              }
              eventKey={location.locationId}
            >
              {location.locationName}
            </Dropdown.Item>
          ))
      )
    ) : (
      <Dropdown.Item
        key={`location-${0}`}
        className={`area-selector-menu-item`}
        eventKey={undefined}
      >
        {constants.LOCATION_DROPDOWN_NO_LOCATION_DATA_TEXT}
      </Dropdown.Item>
    );

  return (
    <div className="selection-wrapper-location-area">
      <Dropdown
        onToggle={onToggle}
        className="area-selector mr-3"
        onSelect={onSelectLocationHandler}
      >
        <Dropdown.Toggle
          variant="outline-secondary"
          id="dropdown-location"
          className="area-selector-toggle"
        >
          <div className="area-selected-wrapper">
            <HiOutlineLocationMarker size={20} />
            <span className="area-selected-name">{selectedLocation}</span>
          </div>
          <HiOutlineChevronDown size={20} />
        </Dropdown.Toggle>
        <Dropdown.Menu className="area-selector-menu">
          {locationDropdownContent}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default LocationAreaDeviceList;
