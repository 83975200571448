import { Utils } from '../../helpers';
import * as mqttClient from '../connection/mqttConnection';
import { v4 as uuidv4 } from 'uuid';
import * as MP4Box from 'mp4box';
import Store from '../../store/Store';
import {
  setElementUpdated,
  setMultiLiveStreamLoader,
  setPlaybackBufferMeta,
} from '../../store/reducers/StreamingReducer';

const turnConfig = {
  iceServers: [
    {
      urls: 'stun:link.duclo.net:33000',
    },
    {
      urls: 'turn:link.duclo.net:33000',
      username: 'test',
      credential: 'test123',
    },
  ],
};

// ====================
// let videoElement = null;
const nextVideoElements = [];
let videoContainer = null;
let dataChannels = {};
// let allConnectionData = null;
let peerConnections = {};
let candidateArrayByDeviceId = {};
let currentPlayStartTime = '';
let audioMuteUnmute = false;
let mp4BoxFile = null;
const ctsArray = [];
let virtualTimeInterval = null;
let virtualCurrentTime = null;
let virtualTimeGap = 200;
let noVideo = null;
let test_data_channel = 'dc-test';
let video_data_channel = 'dc-video';
let audio_data_channel = 'dc-audio';

const generatePayload = (type, offerCandidate, data, subTopic) => {
  if (type === 'offer') {
    return {
      tid: new Date().getTime().toString(),
      to: data.gatewayId,
      from: data.accountId,
      msg: {
        // resource: `ch/${deviceId}/streaming/live`,
        resource: `ch/${data.deviceId}/playback`,
        // resource: `ch/${deviceId}/camera/streaming`,
        properties: {
          id: `${data.streamId}`,
          type: 'offer',
          sdp: offerCandidate,
        },
      },
      publish: subTopic,
    };
  }
  if (type === 'candidate') {
    return {
      tid: new Date().getTime().toString(),
      to: data.gatewayId,
      from: data.accountId,
      msg: {
        resource: `ch/${data.deviceId}/playback`,
        // resource: `ch/${deviceId}/camera/streaming`,
        properties: {
          id: `${data.streamId}`,
          type: 'candidate',
          ...JSON.parse(JSON.stringify(offerCandidate)),
        },
      },
      publish: subTopic,
    };
  }
};

// function ntpTimestampToUnixTimestamp(ntpTimestamp) {
//   /* global BigInt */
//   const bigIntNtpTimestamp = BigInt(ntpTimestamp);
//   const NTP_UNIX_EPOCH_DIFF = 2208988800n; // Seconds between 1900 and 1970
//   const TWO_TO_THE_32 = 2n ** 32n; // 2^32 to separate seconds and fractions

//   const seconds = bigIntNtpTimestamp >> 32n; // High 32 bits for seconds
//   const fractional = bigIntNtpTimestamp & (TWO_TO_THE_32 - 1n); // Low 32 bits for fractional part
//   const fractionalMilliseconds = Number((fractional * 1000n) / TWO_TO_THE_32);
//   const unixSeconds = Number(seconds - NTP_UNIX_EPOCH_DIFF);
//   const unixTimestamp = unixSeconds * 1000 + fractionalMilliseconds;

//   return unixTimestamp;
// }

const startConnection = async (deviceId) => {
  let peerConnection;
  if (peerConnections?.[deviceId]) {
    console.log('already exist peer connection');
    peerConnection = peerConnections[deviceId];
  } else {
    peerConnection = new RTCPeerConnection(turnConfig);
    peerConnections[deviceId] = peerConnection;
  }

  peerConnection.createDataChannel('test_datachannel', {
    protocol: 'protocol',
  });

  const allConnectionData = mqttClient?.getMqttClient(false, deviceId);
  console.log('playback start connection', allConnectionData);

  if (allConnectionData) {
    const clientMQTT = allConnectionData?.mqttclient;
    let pubTopic = `a/notify/${allConnectionData?.gatewayId}`;
    const subTopic = `d/notify/${allConnectionData?.accountId}/${allConnectionData?.sid}`;
    const candidateArray = [];

    const checkVideoStartTime = () => {
      const videoElement = document.getElementById(`playback-video${deviceId}`);
      const { sourceBuffers } = videoElement;
      videoElement.currentTime = Math.max(
        ...Object.values(sourceBuffers).map((sb) => sb.startTime || 0),
      );
    };

    clientMQTT.subscribe(subTopic);

    // send offer
    const offer = await peerConnection.createOffer();
    await peerConnection.setLocalDescription(offer);
    pubTopic = `a/notify/${allConnectionData?.gatewayId}`;
    mqttClient.sendWebRTCOffer(
      pubTopic,
      generatePayload('offer', offer.sdp, allConnectionData, subTopic),
    );

    // local candidate
    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        pubTopic = `a/notify/${allConnectionData?.gatewayId}`;
        mqttClient.sendWebRTCCandidate(
          pubTopic,
          generatePayload(
            'candidate',
            event.candidate,
            allConnectionData,
            subTopic,
          ),
        );
      }
    };

    // peer connection state
    peerConnection.onconnectionstatechange = () => {
      console.log('playback peer connection ', peerConnection.connectionState);
    };

    peerConnection.ondatachannel = (event) => {
      let dataChannel;
      if (dataChannels?.[deviceId]) {
        dataChannel = dataChannels?.[deviceId];
      } else {
        dataChannel = event.channel;
        dataChannels[deviceId] = event.channel;
      }
      const type = dataChannel.label;

      function appendBufferToSourceBuffer(targetVideoElement) {
        const { sourceBuffers, segmentQueues } = targetVideoElement;
        const sourceBuffer = sourceBuffers[type];
        const segmentQueue = segmentQueues[type];

        if (!sourceBuffer.updating && segmentQueue.length > 0) {
          sourceBuffer.appendBuffer(segmentQueue.shift());
        }
      }

      dataChannel.onopen = () => {
        console.log('=== data channel opened');
        sendPlayCommand(currentPlayStartTime, 'PLAY', deviceId);
      };

      dataChannel.onclose = () => {
        console.log('=== data channel closed');
        cleanupResources(deviceId);
      };

      dataChannel.onerror = (error) => {
        console.log('==== data channel error: ', error);
      };

      dataChannel.onmessage = async (event) => {
        const videoElement = document.getElementById(
          `playback-video${deviceId}`,
        );
        try {
          if (typeof event.data !== 'string') {
            const buffer = new Uint8Array(event.data);
            // console.log(
            //   '=== data channel received message size: ',
            //   buffer.byteLength,
            // );
            if (checkInitSegment(buffer)) {
              // TODO: Code is under observation will remove or update later
              // if (Object.keys(videoElement?.sourceBuffers)?.length > 0) {
              //   // console.log('next video elemt', nextVideoElements);
              //   videoElement.mediaSource.endOfStream();
              //   videoElement = nextVideoElements.at(-1);
              //   nextVideoElements.push(createVideoElement());
              // }
              const { sourceBuffers, segmentQueues, mediaSource } =
                videoElement;
              if (!segmentQueues[type]) segmentQueues[type] = [];
              const segmentQueue = segmentQueues[type];

              mp4BoxFile = await loadMp4BoxFile(buffer);
              window.mp4BoxFile = mp4BoxFile;
              const mimeCodec = mp4BoxFile.getInfo().mime;
              console.log('=== mime codec:', mimeCodec);
              if (!MediaSource.isTypeSupported(mimeCodec)) {
                console.error('Mime codec not supported: codec=', mimeCodec);
                return;
              }
              if (mediaSource.sourceBuffers.length) {
                console.log(
                  "=== mutliple resolution detected, but don't add new SourceBuffer",
                );
                segmentQueue.push(buffer);
                return;
              }
              sourceBuffers[type] = mediaSource.addSourceBuffer(mimeCodec);
              const sourceBuffer = sourceBuffers[type];
              sourceBuffer.addEventListener('updateend', (event) => {
                // console.log('=== source buffer update end');
                appendBufferToSourceBuffer(videoElement);
                const targetSourceBuffer = event.target;
                if (
                  typeof targetSourceBuffer.startTime === 'undefined' &&
                  targetSourceBuffer.buffered.length > 0
                ) {
                  targetSourceBuffer.startTime = event.target.buffered.start(0);
                  checkVideoStartTime();
                }
              });
              sourceBuffer.addEventListener('error', (error) => {
                console.log(
                  '=== source buffer error: ',
                  error,
                  videoElement?.error,
                );
              });
              segmentQueue.push(buffer);
            } else {
              const { sourceBuffers, segmentQueues } = videoElement;
              const segmentQueue = segmentQueues[type];

              if (!sourceBuffers[type]) {
                console.log(
                  '=== Source buffer not initialized, data received before the init segment is discarded.',
                );
                return;
              }
              segmentQueue.push(buffer);
              const mp4Buffer = buffer.buffer;
              mp4Buffer.fileStart = mp4BoxFile.lastFileStart;
              mp4BoxFile.lastFileStart = mp4BoxFile.appendBuffer(mp4Buffer);
              appendBufferToSourceBuffer(videoElement);
            }
          } else {
            const receivedData = JSON.parse(event.data);
            const type = receivedData?.msg?.properties?.type;
            if (type === 'NO_VIDEO') {
              // virtualTimeGap =
              //   receivedData?.msg?.properties?.next_avail_time -
              //   receivedData?.msg?.properties?.from;
            }
            // console.log(
            //   'Received data:',
            //   JSON.stringify(receivedData, null, 2),
            // );
          }
        } catch (error) {
          console.error('=== media source error: ', error, videoElement?.error);
          // dataChannel.close();
          // mediaSource.endOfStream();
        }
      };
    };
  }
};

const checkInitSegment = (buffer) => {
  const ftypBox = String.fromCharCode.apply(null, buffer.subarray(4, 8));
  return ftypBox === 'ftyp';
};

const loadMp4BoxFile = (u8Buffer) => {
  return new Promise((resolve, reject) => {
    const mp4BoxFile = MP4Box.createFile(false);
    mp4BoxFile.onReady = (info) => {
      console.log('=== mp4 box info: ', info);
      const videoTrack = info.tracks.find((track) => track.type === 'video');
      mp4BoxFile.setExtractionOptions(videoTrack.id, null, { nbSamples: 1 });
      mp4BoxFile.onSamples = (_id, _user, samples) => {
        const cts = samples[0].cts / samples[0].timescale;
        ctsArray.push(cts);
      };
      resolve(mp4BoxFile);
    };
    mp4BoxFile.onError = reject;
    u8Buffer.buffer.fileStart = 0;
    mp4BoxFile.lastFileStart = mp4BoxFile.appendBuffer(u8Buffer.buffer);
    mp4BoxFile.start();
  });
};

const addNewVideoElement = (event, deviceId) => {
  try {
    const previouseVideoElement = event.target;
    previouseVideoElement.pause();
    previouseVideoElement.removeEventListener('ended', (event) => {
      addNewVideoElement(event, deviceId);
    });
    previouseVideoElement.removeEventListener('progress', (event) => {
      handleVideoEvents(event, deviceId);
    });
    previouseVideoElement.removeEventListener('canplay', (event) => {
      handleVideoEvents(event, deviceId);
    });
    URL.revokeObjectURL(previouseVideoElement.src);
    previouseVideoElement.remove();
    const nextVideoElement = nextVideoElements.shift();
    videoContainer.appendChild(nextVideoElement);
    nextVideoElement.play();
    Store.dispatch(setElementUpdated(true));
  } catch (err) {
    console.log('Error while adding new element', err);
  }
};

const createVideoElement = (deviceId) => {
  const videoElement = document.createElement('video');

  videoElement.controls = false;
  videoElement.muted = true; //audioMuteUnmute;
  videoElement.style.width = '100%';
  videoElement.id = `playback-video${deviceId}`;

  const mediaSource = new MediaSource();
  videoElement.mediaSource = mediaSource;
  videoElement.src = URL.createObjectURL(mediaSource);
  videoElement.sourceBuffers = {};
  videoElement.segmentQueues = {};
  mediaSource.addEventListener('sourceopen', () => {
    mediaSource.duration = 0;
  });
  videoElement.addEventListener('ended', (event) => {
    addNewVideoElement(event, deviceId);
  });
  videoElement.addEventListener('error', (event) => {
    handleVideoEvents(event, deviceId);
  });
  videoElement.addEventListener('progress', (event) => {
    handleVideoEvents(event, deviceId);
  });
  videoElement.addEventListener('canplay', (event) => {
    handleVideoEvents(event, deviceId);
  });
  videoElement.addEventListener('waiting', (event) => {
    handleVideoEvents(event, deviceId);
  });
  return videoElement;
};

const handleVideoEvents = (event, deviceId) => {
  // console.log('handleLoader ~ event:', event);
  const loaderData = Store.getState()?.streaming?.multiLiveStreamLoader;
  const isLoaderDisplay = loaderData?.[deviceId];
  switch (event.type) {
    case 'canplay':
      if (isLoaderDisplay) {
        const updatedObj = {
          deviceId: deviceId,
          isLoading: false,
        };
        Store.dispatch(setMultiLiveStreamLoader(updatedObj));
      }
      break;

    case 'waiting':
      if (!isLoaderDisplay) {
        const updatedObj = {
          deviceId: deviceId,
          isLoading: true,
        };
        Store.dispatch(setMultiLiveStreamLoader(updatedObj));
      }
      // console.log(
      //   '=== video waiting',
      //   videoElement.currentTime,
      //   videoElement.paused,
      // );
      // if (virtualTimeInterval) clearInterval(virtualTimeInterval);
      // virtualCurrentTime = videoElement.currentTime;
      // noVideo.style.display = 'block';
      // virtualTimeInterval = setInterval(() => {
      //   virtualCurrentTime += virtualTimeGap / 1000;
      //   console.log(
      //     'virtualTimeInterval=setInterval ~ virtualCurrentTime:',
      //     virtualCurrentTime,
      //   );
      //   if (virtualCurrentTime && videoElement) {
      //     videoElement.currentTime = virtualCurrentTime;
      //   }

      //   // displayTime();
      // }, virtualTimeGap);
      break;

    case 'playing':
      if (isLoaderDisplay) {
        const updatedObj = {
          deviceId: deviceId,
          isLoading: false,
        };
        Store.dispatch(setMultiLiveStreamLoader(updatedObj));
      }
      // if (virtualTimeInterval) clearInterval(virtualTimeInterval);
      // virtualTimeInterval = null;
      // noVideo.style.display = 'none';
      break;

    case 'progress':
      assignBufferedMeta(deviceId);
      break;

    case 'error':
      handleVideoError(event, deviceId);
      break;

    default:
      break;
  }
};

const assignBufferedMeta = (deviceId) => {
  const videoElement = document.getElementById(`playback-video${deviceId}`);
  if (
    videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered.length > 0
  ) {
    const bufferStart =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.start(0);
    const bufferEnd =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);
    if (bufferStart && bufferEnd) {
      const obj = {
        // id: allConnectionData?.deviceId,
        id: deviceId,
        client: {
          start: parseInt(bufferStart, 10) * 1000,
          end: parseInt(bufferEnd, 10) * 1000,
          className: 'new-meta-buffer-class',
        },
      };
      Store.dispatch(setPlaybackBufferMeta(obj));
    }
  }
};

const handleVideoError = (event, deviceId) => {
  const videoElement = document.getElementById(`playback-video${deviceId}`);
  console.log('video error', videoElement?.error);
};

export const startPlaybackProcess = (playTime, audioValue, deviceId) => {
  // const videoElement = document.getElementById(`playback-video${deviceId}`);
  currentPlayStartTime = playTime;
  audioMuteUnmute = audioValue;
  videoContainer = document.getElementById(`remote-view-wrapper${deviceId}`);
  const videoElement = createVideoElement(deviceId);
  nextVideoElements.push(createVideoElement(deviceId));
  videoElement.autoplay = true;
  videoElement.preload = true;
  videoContainer.appendChild(videoElement);
  const peerConnection = peerConnections?.[deviceId];
  console.log('playback peerConnection', peerConnection);
  if (!peerConnection || peerConnection?.connectionState !== 'connected') {
    startConnection(deviceId);
  }
  noVideo = document.getElementById('noVideo');
  const elementCreated = 'elementCreated';
  return elementCreated;
};

export const sendPlayCommand = (timestamp, command, deviceId) => {
  const dataChannel = dataChannels?.[deviceId];
  const actualTime = Utils.getUnixDate(timestamp) * 1000;
  const uniqueId = uuidv4();
  if (dataChannel?.readyState === 'open') {
    const allConnectionData = mqttClient?.getMqttClient(false, deviceId);
    const playPayload = {
      cid: uniqueId, //-- optional in case of Edge playback
      tid: new Date().getTime().toString(),
      to: allConnectionData?.deviceId,
      from: allConnectionData?.accountId,
      msg: {
        action: 'set',
        resource: 'edge/playback',
        properties: {
          uuid: `${allConnectionData?.streamId}`, //-- optional in case of Edge playback
          org_id: allConnectionData?.orgId, //-- optional in case of Edge playback
          dev_id: allConnectionData?.deviceId, //-- optional in case of Edge playback
          start_time: actualTime, //-- epoch millisec
          duration: 60000, //-- +30000, -30000 (+ forward / - rewind)
          quality: 'HQ', //-- HQ or SQ
          type: command,
        },
      },
    };
    console.log('playbackfw sendPlayCommand ~ playPayload:', playPayload);
    setTimeout(() => {
      dataChannel?.send(JSON.stringify(playPayload));
    }, 3000);
  }
};

export const sendContinueCommand = (command, deviceId) => {
  const uniqueId = uuidv4();
  const dataChannel = dataChannels?.[deviceId];
  if (dataChannel?.readyState === 'open') {
    const allConnectionData = mqttClient?.getMqttClient(false, deviceId);
    const playPayload = {
      cid: uniqueId, //-- optional in case of Edge playback
      tid: new Date().getTime().toString(),
      to: allConnectionData?.deviceId,
      from: allConnectionData?.accountId,
      msg: {
        action: 'set',
        resource: 'edge/playback',
        properties: {
          uuid: `${allConnectionData?.streamId}`, //-- optional in case of Edge playback
          org_id: allConnectionData?.orgId, //-- optional in case of Edge playback
          dev_id: allConnectionData?.deviceId, //-- optional in case of Edge playback
          // start_time: actualTime, //-- epoch millisec
          duration: 60000, //-- +30000, -30000 (+ forward / - rewind)
          quality: 'HQ', //-- HQ or SQ
          type: command,
        },
      },
    };
    console.log('playbackfw continue command ~ continuePayload:', playPayload);
    dataChannel?.send(JSON.stringify(playPayload));
  }
};

export const sendStopCommand = (deviceId) => {
  const uniqueId = uuidv4();
  const dataChannel = dataChannels?.[deviceId];
  const allConnectionData = mqttClient?.getMqttClient(false, deviceId);
  if (dataChannel?.readyState === 'open') {
    const playPayload = {
      cid: uniqueId, //-- optional in case of Edge playback
      tid: new Date().getTime().toString(),
      to: allConnectionData?.deviceId,
      from: allConnectionData?.accountId,
      msg: {
        action: 'set',
        resource: 'edge/playback',
        properties: {
          uuid: `${allConnectionData?.streamId}`, //-- optional in case of Edge playback
          org_id: allConnectionData?.orgId, //-- optional in case of Edge playback
          dev_id: allConnectionData?.deviceId, //-- optional in case of Edge playback
          type: 'STOP',
        },
      },
    };
    dataChannel?.send(JSON.stringify(playPayload));
  }
};

export const stopPlaybackConnection = (deviceId) => {
  if (peerConnections?.[deviceId]) {
    let peerConnection = peerConnections?.[deviceId];
    let dataChannel = dataChannels?.[deviceId];
    if (peerConnection && dataChannel?.readyState === 'open') {
      peerConnection?.close();
      peerConnection.onicecandidate = null;
      peerConnection.ontrack = null;
      peerConnection = null;
      dataChannel = null;
    }
    delete peerConnections[deviceId];
    delete dataChannels[deviceId];
  }
  clearInterval(virtualTimeInterval);
  cleanupResources(deviceId);
};

const cleanupResources = (deviceId) => {
  cleanVideoElements();
  // dataChannel = null;
  // allConnectionData = null;
  // peerConnection = null;
  if (peerConnections?.[deviceId]) {
    delete peerConnections[deviceId];
  }
  currentPlayStartTime = '';
};

export const cleanVideoElements = (deviceId) => {
  const videoElement = document.getElementById(`playback-video${deviceId}`);
  if (videoElement?.mediaSource?.readyState === 'open') {
    videoElement.mediaSource.endOfStream();
  }
  videoElement?.removeEventListener('ended', (event) => {
    addNewVideoElement(event, deviceId);
  });
  videoElement?.removeEventListener('progress', (event) => {
    handleVideoEvents(event, deviceId);
  });
  videoElement?.removeEventListener('canplay', (event) => {
    handleVideoEvents(event, deviceId);
  });
  videoElement?.removeEventListener('error', (event) => {
    handleVideoEvents(event, deviceId);
  });
  videoElement?.removeEventListener('waiting', (event) => {
    handleVideoEvents(event, deviceId);
  });
  videoElement?.remove();
  // videoElement = null;
  nextVideoElements.length = 0;
  videoContainer = null;
};

export const getPeerConnectionState = (deviceId) => {
  return peerConnections?.[deviceId];
};
