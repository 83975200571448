import {
  fisheye,
  ptz,
  scrubSpeed,
  ptzDisabled,
  scrubSpeedDisabled,
  filter,
  filterDisable,
} from '../../assets/images';
import { constants } from '../../helpers/';

export const SettingOptions = [
  {
    id: 1,
    name: constants.CAMERA_SETTINGS_PTZ,
    icon: ptz,
    disabledIcon: ptzDisabled,
    subOptions: [],
  },
  {
    id: 2,
    name: constants.CAMERA_SETTINGS_SCRUB_SPEED,
    icon: scrubSpeed,
    disabledIcon: scrubSpeedDisabled,
    subOptions: [
      constants.CAMERA_SETTINGS_OPT_EVENT_BY_EVENT,
      //TODO: IT WILL IMPLEMENT LATER constants.CAMERA_SETTINGS_OPT_FRAME_BY_FRAME,
      constants.CAMERA_SETTINGS_OPT_5SEC_SKIP,
      constants.CAMERA_SETTINGS_OPT_10SEC_SKIP,
      constants.CAMERA_SETTINGS_OPT_20SEC_SKIP,
      constants.CAMERA_SETTINGS_OPT_30SEC_SKIP,
    ],
  },
  {
    id: 5,
    name: constants.CAMERA_SETTINGS_QUALITY,
    icon: filter,
    disabledIcon: filterDisable,
    subOptions: [
      constants.CAMERA_SETTINGS_HIGH_QUALITY,
      constants.CAMERA_SETTINGS_STANDARD_QUALITY,
    ],
  },
  // {
  //     id : 3,
  //     name : constants.CAMERA_SETTINGS_FISH_EYE,
  //     icon : fisheye ,
  //     subOptions : [constants.CAMERA_SETTINGS_OPT_REGULAR, constants.CAMERA_SETTINGS_OPT_DEWRAP]
  // },
  {
    id: 4,
    name: constants.CAMERA_SETTINGS_ALL,
    icon: '',
    subOptions: [],
  },
];
